import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { to2Decimal, formatINR } from '../../utils/CommonUtil';
import ImageUtil from '../../utils/ImageUtil';
import {previewCart} from '../../actions/CartAction';
import {Global} from '../../utils/Env';
import { selectCheckOutJSON, checkOutOrder } from '../../actions/CheckoutAction';
 /**
 * Creates a new Cart.
 * @class
 */
const Cart = (props) => {
    /** @function
 * @name checkOutOrder_onComplete */
    Global.callback.checkOutOrder_onComplete = () => {
        navigate("/checkout", { state: { process: true } });
    }

    const navigate = useNavigate();
        /** @function
 * @name onCartClicked */
    const onCartClicked = () => {
        if(hasItems()){
            props.checkOutOrder(props.checkoutData); 
        } 
    }
            /** @function
 * @name hasItems */
    const hasItems = () => {
        return (props.cartItems && (props.cartItems.length > 0)) ? true : false
    }
    
    return (
        <div className="cur-pointer d-inline-block" onClick={onCartClicked}>
            <img
                className="cart-iconclass"
                src={require("../../images/cart-bag.png")}
                alt="Cart" /> 
            {props.totalItems ? (<span className="position-absolute custom-badge">{props.totalItems}</span>) : ("")}
        </div>            
    )
}

const mapStateToProps = state => ({
    totalItems:state.CartData.totalItems,
    totalAmount:state.CartData.totalAmount,
    cartBounce:state.CartData.cartBounce,
    cartItems: state.CartData.cartList,
    checkoutData: selectCheckOutJSON(state)
  })

export default connect(mapStateToProps, { previewCart, checkOutOrder }) (Cart);