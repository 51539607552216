import React from 'react';
import { connect } from 'react-redux';

import ImageUtil from '../../utils/ImageUtil';
import { to2Decimal, formatINR } from '../../utils/CommonUtil';
import Counter from "../Counter";
import { removeItem } from '../../actions/CartAction';
import { Col, Row } from 'react-bootstrap';

/**
 * Creates a new CartItem.
 * @class
 */
const CartItem = (props) => {
    /* const getTotalAmount = (x) => {
        let totalAmount = 0
        for (let i = 0; i < x.length; i++) {
            let price = (x[i].promo_price ? x[i].promo_price : x[i].price_regular)
            totalAmount += price * parseInt(x[i].quantity);
        }
        return totalAmount
    }
    const handleRemove = () => {
        let cart1 = [...props.cartList];
        let index = cart1.findIndex(x => x.id === props.product.id);
        cart1.splice(index, 1);
        let totalAmount = getTotalAmount(cart1);
        props.removeItem({ cart: cart1, totalAmount: totalAmount });
    } */
    return (
        <React.Fragment>
            <Row className="cart-item" >

                <Col md="2" xs="3" className="p-0-5-tab d-flex jc-flc">
                    <div className="chkout-main-imgpanel">

                        {(props.product.brandicon !== "") ? <img alt={props.product.brandicon} className="cart-brand-img" src={ImageUtil.getImage(props.product.brandicon)}></img> : null}
                        <img
                            className="product-image product-image-cart"
                            src={props.product.images[0] ? ImageUtil.getImage(props.product.images[0].name) : ImageUtil.getErrorImage()}

                            alt={props.product.name}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = ImageUtil.getErrorImage();
                            }}
                        />
                    </div>
                </Col>
                <Col md="10 p-0" xs="9">
                    <span className="badge badge-pill badge-success">{props.product.promo_percentage}</span>
                    <Col md="12" className="text-left pl-0 pl-0-mob">
                        <p className="product-name product-name-cart mb-0">{`${props.product.name} `}<b>({props.product.size})</b></p>
                        {/* <p className="text-muted text-left font-12"><b>({props.product.size})</b></p> */}
                    </Col>
                    <div className="product-info">
                        <Row>
                            <Col md="7" xs="7" className="text-left checkout-count p-0 p-0-mob">
                                {/* <p className="text-left lh-mob-20"><b>{props.product.promo_price ? props.product.promo_price : props.product.price_regular}</b></p> */}
                                <div><Counter product={props.product} />&nbsp;&nbsp;
                                <span aria-hidden="true">&times;</span>&nbsp;&nbsp;
                                <span className="single-amt">
                                    {props.product.promo_price 
                                        ? formatINR(to2Decimal(props.product.promo_price))
                                        : formatINR(to2Decimal(props.product.price_regular))}
                                        </span> &nbsp; 
                                    {/* <s className="text-muted f-size">₹ 125</s> */}</div>
                            </Col>
                            <Col md="5" xs="5" className="text-right"><p className="amount product-price text-right">
                                {props.product.promo_price 
                                    ? formatINR(to2Decimal(props.product.quantity * props.product.promo_price)) 
                                    : formatINR(to2Decimal(props.product.quantity * props.product.price_regular))}</p>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                            <p className="product-name product-name-cart mb-0">{`${props.product.name} `} <b>({props.product.size})</b></p>
                            </Col>
                        </Row> */}
                    </div>
                    {/*eslint-disable-next-line*/}
                    {/* <a
                        className="product-remove product-remove-top"
                        href="#"
                        onClick={handleRemove}
                    >
                        ×
          </a> */}
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    cartList: state.CartData.cartList
})
export default connect(mapStateToProps, { removeItem })(CartItem);

