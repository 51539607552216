

/**
 * All APIs for the application
 */
const API = {};

API.CONFIG = "/config.json";

API.SIGN_IN = API.LOG_IN = "user/signin";
API.SIGN_OUT = API.LOG_OUT = "user/signout";
API.SIGN_UP = "user/signup";
API.PROFILE_SAVE = "user/UpdateUserProfile";
API.CHANGE_PASSWORD = "user/ChangePassword";
API.RESET_PASSWORD = "user/ResetPassword";

API.GET_COMPANY_INFO = 'Generic/GetCompany?url=';

API.GET_IP = 'https=//ipinfo.io?token=c49d0e3562b4b7';

API.GET_FEATURE_ITEMS = 'Inventory/GetFeatureItems?companyid=';

API.VALIDATE_PIN = 'Common/ValidatePinCode';
API.GET_CATEGORY = 'stock/GetAllCategories';
API.GET_ALL_ITEMS = 'stock/GetAllItems';
API.GET_CUSTOMER_DETAILS = 'Order/GetCustomerDtl?';
API.GET_ITEMS_BY_VARIANT = 'Inventory/GetItemsByVariantIds';

API.GET_ORDER_LIST = 'https://stagebadmin.uvanij.com/service/order/GetOrderList';

API.GET_ORDER_HISTORY_LIST ='customer/GetOrderHistory';

API.HTML_VIEW_ORDER_INVOICE = 'customer/GetHtmlViewOrderInvoice';

API.SEND_OTP = 'Sms/SendOtp';
API.VALIDATE_OTP = 'Sms/Verifyotp';

API.VALIDATE_DISCOUNT = 'order/ValidateDiscount';
API.CHECKOUT_ORDER = 'order/CheckOutOrder';
API.PLACE_ORDER = 'order/PlaceOrder';
API.GET_FILTER= 'filter/filters';

export default API
