
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ImageUtil from '../utils/ImageUtil';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Row, Col, Navbar, Nav, Form, FormControl, InputGroup, ListGroup, Card, Tab, Tabs } from 'react-bootstrap';
import { APIAlert } from '../utils/AlertUtil';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Global } from '../utils/Env';
import { addToCart, setCartBounce, clearCart } from "../actions/CartAction";
import { selectCheckOutJSON, checkOutOrder } from '../actions/CheckoutAction';
import ColorCode from './ColorCode';
import MsgModal from '../container/MsgModal';
import { setMsgModal, set_confirm } from '../actions/AuthAction';
import debounce from 'lodash.debounce';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  FacebookIcon,
  EmailIcon,
  TwitterIcon,
  TelegramIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";

import Header from '../components/header/Header';

/**
 * Creates a new Temp.
 * @class
 */
const Confirm = (props) => {
    return (
        <React.Fragment>
		{(props.size)?<p> Do you want to Confirm deletion of the size from your cart?</p>:<p> Do you want to Confirm deletion of the entire item from your cart?</p>}
            <Button variant="primary" onClick={() => {
                (props.size)?props.sizeSelectHandler(props.size):props.sizeSelectHandler(); props.msgmodal({
                    "title": "",
                    "message": "",
                    "show": false
                });
            }}>Confirm </Button>
        </React.Fragment>
    )
}

const Share = (props) => {
	const shareUrl='http://github.com';
	const title = props.message;
	return (
	<React.Fragment>
	{props.message}
	<br/>
	<FacebookShareButton  quote={title} url={shareUrl} >
	<FacebookIcon size={32} round />
	</FacebookShareButton>
<EmailShareButton subject="Check this Naumi Fashion Item" body={title} url={shareUrl} seperator="----------">
<EmailIcon size={32} round />
</EmailShareButton>
<TwitterShareButton
            url={shareUrl}
            title={title}
            
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
		  <TelegramShareButton
            url={shareUrl}
            title={title}
            
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
		  <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: " >
            <WhatsappIcon size={32} round />
			</WhatsappShareButton>
			<LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
</React.Fragment>
)
}

function Temp(props) {
    /** @function
 * @name checkOutOrder_onComplete */
    Global.callback.checkOutOrder_onComplete = () => {
        navigate("/checkout", { state: { process: true } });
    }

    const navigate = useNavigate();
    const emptyCart = [];
    const [enableDelete, setenableDelete] = useState(false);
    const deepClone = require('clone-deep');
    const [activeImg, setActiveImg] = useState(0);
    const [selectedSize, setSelectedSize] = useState([]);
    const [interv, setInterv] = useState(null);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        let order = orderObj(deepClone(props.productDetails), deepClone(selectedSize));
        let cart = [...props.cartList].filter((item) => item.id == order.id);
        if (props.productDetails.orders) {
			let order1 = orderObj(deepClone(props.productDetails), deepClone(props.productDetails.orders));
			let temp = deepClone(props.productDetails.orders);
			var i;
			var count = 0;
			for (i = 0; i < order1.orders.length; i++) {
				if (order1.orders[i].total_quantity == 0)
					count++;
			}
			while (count != 0) {
				for (i = 0; i < order1.orders.length; i++) {
					if (order1.orders[i].total_quantity == 0) {
						order1.orders.splice(i, 1);
						temp.splice(i, 1);
					}
				}
            count--;
			}
			setSelectedSize(temp);
        }
        if (cart.length > 0)
            setenableDelete(true);
	
		
    }, [props.productDetails])

    /** @function
 * @name findSizeIndex */
    const findSizeIndex = (size) => {
        let indx = selectedSize.findIndex(item => item.name === size.name)
        return indx;
    }

    const onMsgClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }
	
	const onConfirm = (size) => {	
		props.setMsgModal ({
			"title": "",
			"message":<Confirm sizeSelectHandler={sizeSelectHandler1} size={size} msgmodal={props.setMsgModal}/>,
			"show":true
		});
		
	}
	const onShare = (message) => {	
		props.setMsgModal ({
			"title": "Share",
			"message":<Share message={message}/>,
			"show":true
		});
		
	}
	
	const sizeSelectHandler1 = (size) => {
		let order = orderObj(deepClone(props.productDetails), deepClone(selectedSize));
		let cart = [...props.cartList].filter((item) => item.id === order.id);
        let copyselectedSize = [...selectedSize];
        let indx = findSizeIndex(size);
        if (indx === -1) {
            let nwColors = size.colors.map(clr => {
                return ({ ...clr, quantity: 0 })
            });
            copyselectedSize.push({ ...size, colors: nwColors, total_quantity: 0 })
        }
        else {
            copyselectedSize.splice(indx, 1);
        }
        setSelectedSize(copyselectedSize);
		addToCart1(size.name);
    }
    /** @function
 * @name sizeSelectHandler */
    const sizeSelectHandler = (size) => {
		let order = orderObj(deepClone(props.productDetails), deepClone(selectedSize));
		let cart = [...props.cartList].filter((item) => item.id === order.id);
        let copyselectedSize = [...selectedSize];
        let indx = findSizeIndex(size);
        if (indx === -1) {
            let nwColors = size.colors.map(clr => {
                return ({ ...clr, quantity: 0 })
            });
            copyselectedSize.push({ ...size, colors: nwColors, total_quantity: 0 })
        }
        else {
            if (copyselectedSize[indx].total_quantity > 0)
                onConfirm(size);
            else
                copyselectedSize.splice(indx, 1);
        }
        setSelectedSize(copyselectedSize);

    }

    // TODO: optimize increaseQuantity and decreaseQuantity

    /** @function
* @name increaseQuantity */
    const increaseQuantity = (size, color) => {
        //console.log("Called increase");
        let copyselectedSize = [...selectedSize]
        let indx = findSizeIndex(size)
        let colorIndex = copyselectedSize[indx].colors.findIndex(itm => itm.color === color.color)
        let newClr = { ...copyselectedSize[indx].colors[colorIndex], quantity: copyselectedSize[indx].colors[colorIndex].quantity + 1 }
        copyselectedSize[indx].colors.splice(colorIndex, 1, newClr);
        copyselectedSize[indx].total_quantity += 1;
        setSelectedSize(copyselectedSize);
		addToCart();
		
    }

           /** @function
 * @name decreaseQuantity */
    const decreaseQuantity = (size, color) => {
        let copyselectedSize = [...selectedSize]
        let indx = findSizeIndex(size)
        let colorIndex = copyselectedSize[indx].colors.findIndex(itm => itm.color === color.color)
        if (copyselectedSize[indx].colors[colorIndex].quantity > 0) {
            let newClr = { ...copyselectedSize[indx].colors[colorIndex], quantity: copyselectedSize[indx].colors[colorIndex].quantity - 1 }
            copyselectedSize[indx].colors.splice(colorIndex, 1, newClr);
            copyselectedSize[indx].total_quantity -= 1;
            setSelectedSize(copyselectedSize);
            addToCart();
        }

    }

    const increase = (size, color) => {
		clearTimeout(timer);
        setInterv(setInterval(() => {
            increaseQuantity(size, color);
        }, 170));


    }
	const showsave = () => {
		APIAlert('Any changes made here is being automatically saved to cart').alert();
			setTimeout(() => props.setCartBounce(false), 1000);
	}
		
    const increaseup = () => {
        clearInterval(interv);
		setTimer(setTimeout(() => { showsave()},1500));
    }

    const decrease = (size, color) => {
		clearTimeout(timer);
        setInterv(setInterval(() => {
            decreaseQuantity(size, color);
        }, 170));

    }
	

    /** @function
* @name orderObj */
    //.. transformed order object
    const orderObj = (product, orders) => {
        let quantity = orders.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.total_quantity
        }, 0)
        return { ...product, orders, quantity }
    }


    const addToCart1 = (size) => { /* TODO: Name the function to the performing task */
        let order = orderObj(deepClone(props.productDetails), deepClone(selectedSize));
        let quantity = order.quantity;
        var i;
        for (i = 0; i < order.orders.length; i++) {
            if (order.orders[i].name == size) {
                order.quantity = quantity - order.orders[i].total_quantity;
                quantity = quantity - order.orders[i].total_quantity;
                order.orders.splice(i, 1);
            }
        }
        if (quantity > 0)
            setenableDelete(true);
        else
            setenableDelete(false);
        if (quantity >= 0) {
            let cart = [...props.cartList].filter((item) => item.id != order.id);
            if (quantity == 0 && cart.length == 0) {
                props.clearCart(emptyCart);
                APIAlert('Item removed from basket').alert();
			}
            else if (quantity == 0 && cart.length >= 0) {
                props.addToCart({ cart });
                APIAlert('Item removed from basket').alert();
            }
            else {
                cart.push({ ...order });
                props.addToCart({ cart });
                APIAlert('Item added to basket').alert();
            }
        }
    }

    const addToCart = () => {
        let order = orderObj(deepClone(props.productDetails), deepClone(selectedSize));
        setenableDelete(true);
        if (order.quantity >= 0) {
            let cart = [...props.cartList].filter((item) => item.id != order.id);
            if (order.quantity == 0 && cart.length == 0) {
                props.clearCart(emptyCart);
                setenableDelete(false);
            }
            else if (order.quantity == 0 && cart.length >= 0) {
                props.addToCart({ cart });
                setenableDelete(false);
            }
            else {
                cart.push({ ...order });
                props.addToCart({ cart });
            }		
        }
        setTimeout(() => props.setCartBounce(false), 1000)
    }
    /** @function
* @name deleteCart */


	
    const deleteCart = () => {

        let order = orderObj(deepClone(props.productDetails), deepClone(selectedSize));
        setenableDelete(false);
        setSelectedSize([]);
        let cart = [...props.cartList].filter((item) => item.id != order.id);
        props.addToCart({ cart });
        APIAlert('Item removed from basket').alert();
        setTimeout(() => props.setCartBounce(false), 1000)
    }

    /** @function
* @name onCheckOut */
    const onCheckOut = () => {
        if (hasCartItems()) {
            props.checkOutOrder(props.checkoutData); //
        }
    }
    /** @function
    * @name hasCartItems */
    const hasCartItems = () => {
        return (props.cartList && (props.cartList.length > 0)) ? true : false
    }

    /** @function
     * @name onMoreShopping */
    const onMoreShopping = () => {
        navigate(-1);
    }

    return (
        <React.Fragment>

            {/* TODO : Header and breadcrumb to be placed in a global container */}
            <Header /> 

            <BreadcrumbsItem to='/products/'>Products / {props.productDetails.itemCode}</BreadcrumbsItem>
            <div className="page-bodesection">

                <Col className="pl-5 pr-5 pt-4 mb-5 mob-pad-1em mb-10px-mob">
                    <Row className="">
                        <Col md={2} xs={3} className="pr-0 text-right cur-pointer">
                            <ListGroup variant="flush">
                                {
                                    props.productDetails.images ? props.productDetails.images.map((image, i) => {
                                        return (
                                            <ListGroup.Item key={i} className="border-0 p-0 pb-2 variant-img" onClick={() => setActiveImg(i)}>
                                                <img className={activeImg === i ? "active-img" : ""} src={image ? ImageUtil.getImage(image) : ImageUtil.getErrorImage()} />
                                            </ListGroup.Item>
                                        )
                                    }) : ""
                                }
                            </ListGroup>
                        </Col>
                        <Col md={6} xs={9}>
                            {props.productDetails.images ? <InnerImageZoom className="w-100"  src={props.productDetails.images[activeImg] ? ImageUtil.getImage(props.productDetails.images[activeImg]) : ImageUtil.getErrorImage()} fullscreenOnMobile={true} mobileBreakpoint={450} zoomScale={2}/>: ""}
                        </Col>
                        <Col md={4}>
                            <h5 className="mb-2 items-code">{props.productDetails.itemCode || ""}</h5>

                            <h3 className="product-name-main mb-2">{props.productDetails.title || ""}</h3>
                            <h4 className="product-sub-description mb-4">{props.productDetails.description || ""}</h4>
                            {props.productDetails.chart ?
                                <div>
                                    <h5 className="details-sub-header">{props.productDetails.chart.title}</h5>
                                    <Card className="border border-dark w-100-mob" style={{ width: '22rem' }}>
                                        <Card.Header as="h5" className="bg-000 text-light">
                                            <Row className="p-0 pl-3 pr-3">
                                                <div className="w-40">
                                                    {props.productDetails.chart.quantity_title}
                                                </div>
                                                <div className="w-60">
                                                    {props.productDetails.chart.price_title}
                                                </div>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body className="pt-2 pb-2">
                                            {props.productDetails.chart.range.map((item, i) => {
                                                return (
                                                    <Row className="p-0 pl-3 pr-3" key={i}>
                                                        <div className="w-40">
                                                            <p className="mb-2 qty-price">{item.display_range}</p>
                                                        </div>
                                                        <div className="w-60">
                                                            <p className="mb-2 qty-price">{item.display_price} <span className="light-label">{item.display_unit}</span></p>
                                                        </div>
                                                    </Row>
                                                )
                                            })}
                                        </Card.Body>
                                    </Card>
                                </div> : ""}

                            {props.productDetails.size_panel ?
                                <div className="mt-3 pb-4 mb-2">
                                    <h5 className="details-sub-header mb-">{props.productDetails.size_panel.title}</h5>
                                    <div>
                                        {props.productDetails.size_panel.sizes.map((item, i) => {
                                            return (
                                                <label className={findSizeIndex(item) !== -1 ? "sizebox sizebox-active text-center cur-pointer" : "sizebox text-center cur-pointer"} key={i}
                                                    onClick={() => sizeSelectHandler(item)}
                                                >{item.name}</label>
                                            )
                                        })}
                                    </div>
                                </div> : ""}
                            <Row className="pl-2 mb-0 pl-0-mob border pt-2 pb-2 ml-0 mr-0 position-relative">
                            {enableDelete && <i className="fa fa-times-circle close-iconstyle" onClick={() =>{props.setMsgModal ({
			"title": "",
			"message":<Confirm sizeSelectHandler={deleteCart} msgmodal={props.setMsgModal}/>,
			"show":true
							});}} aria-hidden="true" ></i>} 
                                {selectedSize.length>0 ? selectedSize.map((item, i) => {
                                    return (
                                        <div className="different-sixebox" key={i}>
                                            <Card className="border border-dark">
                                                <Card.Header as="h5" className="bg-000 text-light">
                                                    <Row className="p-0 pl-2 pr-2">
                                                        <div className="w-40 fsize14">
                                                            {item.name}
                                                        </div>
                                                        
                                                        <div className="w-60 fsize14 text-center">
                                                            {props.productDetails.size_panel.quantity_title}.{item.total_quantity}
                                                            <i className="fa fa-trash-o fsize17 cur-pointer float-right" aria-hidden="true" onClick={() => sizeSelectHandler(item)}></i>
                                                        
                                                        </div>
                                                       
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body className="pt-2 pb-2">
                                                    {item.colors.map((clr, i) => {
                                                        return (
                                                            <Row className="p-0 pl-2 pr-2" key={i}>
                                                                <div className="w-40">
                                                                    <p className="mb-1 mb-1 qty-price fsize13">
                                                                        {/* <i className="fa fa-circle mr-2" style={{ color: clr.color }} aria-hidden="true"></i> */}
                                                                        <ColorCode color={clr.color} />{clr.name}
                                                                    </p>
                                                                </div>
                                                                <div className="w-60" >
                                                                    <InputGroup className="mb-2" >
                                                                        <InputGroup.Prepend>
                                                                            <InputGroup.Text className="bg-000 text-light rounded-0 h-30 cur-pointer"  onClick={() => decreaseQuantity(item, clr)}  onPointerUp={increaseup} onPointerDown={() => decrease(item, clr)} onPointerLeave={() => clearInterval(interv)}>-</InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                        <FormControl aria-label="Amount (to the nearest dollar)" readOnly value={clr.quantity} className="h-30 p-0 text-center" />
                                                                        <InputGroup.Append>
                                                                            <InputGroup.Text className="bg-000 text-light rounded-0 h-30 cur-pointer"  onClick={() => increaseQuantity(item, clr)}   onPointerUp={increaseup} onPointerDown={() => increase(item, clr)} onPointerLeave={() => clearInterval(interv)}>+</InputGroup.Text>
                                                                        </InputGroup.Append>
                                                                    </InputGroup>
                                                                </div>
                                                            </Row>
                                                        )
                                                    })}

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    )
                                }) : "Select the size to add items"}
                              {/* <div className="mt-0 pl-0-mob w-100 text-left"><Button variant="dark" className="rounded-0 primarybutton mr-0" disabled={!enableDelete} onClick={deleteCart}>DELETE ENTIRE ITEM </Button>{' '}</div> */}
                            </Row>
                            {/* <div className="mb-3 mt-0 pl-0-mob w-100 text-left"><Button variant="dark" className="rounded-0 primarybutton mr-0" disabled={!enableDelete} onClick={deleteCart}>DELETE ENTIRE ITEM </Button>{' '}</div> */}
                            <Col className="pl-0 pr-0 pt-2 pb-2" >
                                <Col className="pl-0 pr-0 pt-3 pb-3">

                                    <Button variant="dark" className="rounded-0 primarybutton bg-bordb02409 mr-3 w-40" onClick={onCheckOut}>CHECKOUT </Button>
                                    <Button variant="dark" className="rounded-0 secondarybutton mr-3 w-40" onClick={onMoreShopping}>MORE SHOPPING </Button>
                                </Col>
                                <Col className="pl-0 pr-0 pb-2">
                                <a  onClick={()=>onShare("".concat(props.productDetails.title).concat("\n Description ").concat(props.productDetails.description).concat("\n").concat("Check out the item in this link ").concat(window.location.href))}>
								<Button variant="dark" className="rounded-0 secondarybutton mr-3 w-40" id="btnOutlook">Share</Button>
								</a>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Col>
                <div className="details-page-tab">
                    <Col className="mb-5 p-0">
                        {props.productDetails.details ? <Tabs className="pl-5 pr-5" id="uncontrolled-tab-example">
                            {props.productDetails.details.map((dtl, i) => {
                                return (
                                    <Tab eventKey={dtl.title} title={dtl.title} className="p-0" key={i}>
                                        <div className="pl-5 pr-5 pt-2 decription-p" dangerouslySetInnerHTML={{ __html: dtl.text }} />
                                    </Tab>
                                )
                            })}
                        </Tabs> : ""}
                    </Col>
                </div>
                {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onMsgClose} />}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    cartList: state.CartData.cartList,
    checkoutData: selectCheckOutJSON(state)
});

export default connect(mapStateToProps, { addToCart, setCartBounce, checkOutOrder, clearCart, setMsgModal, set_confirm })(Temp);

