import React, {useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {fetchOrderHistory,getHtmlViewOrderInvoice} from '../../actions/UserAction';

import {setMsgModal} from '../../actions/AuthAction';
import OrderInvoiceModal from '../OrderInvoiceModal'; 
/**
 * Creates a new OrderedItemTable.
 * @class
 */
const AllOrders = props => {
    const [show, setShow] = useState(false);
    const [heading,setHeading] = useState(null);

    useEffect(() => {
        props.fetchOrderHistory({compId:props.compId,userId:props.userId}); 
        console.log(props.allOrders);
        },[]);


    /** @function
    *   @name renderTable  
    */
    const renderTable = () => {

        const getItemOrder = (orderNo) => {
            props.getHtmlViewOrderInvoice({"compId": props.compId,"type": "order","number": orderNo}); 
            setHeading("Order");
            setShow(true);
            
        }

        const getInvoice = (invoiceNo) => {
            props.getHtmlViewOrderInvoice({"compId": props.compId,"type": "invoice","number": invoiceNo}); 
            setHeading("Invoice");
            setShow(true);
        }
        
            return props.userOrders ? props.userOrders.map((item, i) => {
                return (
                        <Container fluid key={`s${i}`} className="table mb-0">
                        <Row className="tr border-bottom" >
                            <Col className="td border-left border-right text-center pt-1 pb-1" ><b><a href="#" onClick={()=>getItemOrder(item.orderNo)}>{item.orderNo}</a></b></Col>
                            <Col className="td border-right text-center pt-1 pb-1" ><b><a href="#" onClick={()=>getInvoice(item.invoiceNo)}>{item.invoiceNo}</a></b></Col>
                            <Col className="td border-right text-center pt-1 pb-1" >{item.orderDate}</Col>
                            <Col className="td border-right text-center pt-1 pb-1" >{item.netTotalOrderedAmount}</Col>
                            <Col className="td border-right text-center pt-1 pb-1" >{item.orderStatus}</Col>
                            <Col className="td border-right text-center pt-1 pb-1" >{item.paymentStatus}</Col>
                            <Col className="td text-center pt-1 pb-1" >{item.deliveryStatus}</Col>
                            </Row>
                        </Container>
                               
            
                )
            }) : null;
         
    }

    return (
        <React.Fragment>
        <OrderInvoiceModal show={show} onHide={() => setShow(false)} html={props.htmlData} heading={heading}/>
        <Card.Body className="border-bottom pl-0-mob pr-0-mob border-none-mob pb-0-mob">
        <Container fluid key={`table${1}`} className="table mb-0">
            <Row className="tr">
                <Col className="td p-0 border-bottom border-right border-top" sm={12}>
                    <Container className="table mb-0 ">
                        <Row className="border-bottom border-top bgefefef">
                            <Col className="td border-left border-right text-center pb-1 pt-1 text-left"><b>Order No</b></Col>
                            <Col className="td border-right text-center pb-1 pt-1 text-left"><b>Invoice No</b></Col>
                            <Col className="td border-right text-center pb-1 pt-1 text-left"><b>Order Date/Time</b></Col>
                            <Col className="td border-right pb-1 pt-1 text-center"><b>Total Order Amount (₹)</b></Col>
                            <Col className="td border-right pb-1 pt-1 text-center"><b>Order Status </b></Col>
                            <Col className="td border-right pb-1 pt-1 text-center"><b>Payment Status</b></Col>
                            <Col className="td pb-1 pt-1 text-right"><b>Delivery Status</b></Col>
                        </Row>
                    </Container>
                    {renderTable()}
            </Col>
            </Row>
        </Container>
    </Card.Body>
    <div className="divH">
    </div>
        </React.Fragment>
    );
}
 
const mapStateToProps = state => ({
    userOrders:state.UserData.userOrder,
    compId: state.HomeData.companyid,
    userId: state.UserData.user.id,
    htmlData:state.UserData.htmlData
})

export default connect(mapStateToProps,{fetchOrderHistory,getHtmlViewOrderInvoice,setMsgModal})(AllOrders);
