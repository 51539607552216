import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {  Row, Col, Button } from 'react-bootstrap';
import Product from '../components/Product';
import {ScrollInView} from '../components/Scroll';
import FilterSection from '../components/FilterSection';

import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import Header from '../components/header/Header';
import Filtertags from '../components/FilterTags';
import { setMsgModal } from '../actions/AuthAction';
import MsgModal from './MsgModal';


/**
 * Creates a new CategoryContainer.
 * @class
 */
const CategoryContainer = (props) => {
	
	const [pricemin,setpriceMin]=useState(0);
	const [pricemax,setpriceMax]=useState(0);
	const [brand,setBrand]=useState([]);
	const [color,setColor]=useState([]);
	const [size,setSize]=useState([]);
	const [discount,setDiscount]=useState([]);

useEffect(() => {
        setpriceMin(props.pricemin);
		setpriceMax(props.pricemax);
		if(typeof props.filter_data != "undefined" && typeof props.filter_data.brands != "undefined" && typeof props.filter_data.colors!="undefined" && typeof props.filter_data.sizes != "undefined"  && typeof props.filter_data.discounts !="undefined")
		{
			setBrand(props.filter_data.brands.filter((item)=> item.checked===true));
			setColor(props.filter_data.colors.filter((item)=> item.checked===true));
			setSize(props.filter_data.sizes.filter((item)=> item.checked===true));
			setDiscount(props.filter_data.discounts.filter((item)=> item.checked===true));
		}
    }, [props.pricemin,props.pricemax,props.filter_data]);
	
	const filter_function = (ob) => 
	{
		let final_dec=false;
		if(typeof ob!="undefined" && (brand.length>0 || color.length>0 || (pricemin>0 && pricemax>0) || size.length>0) )
		{
			let min=parseInt(ob.range_display.split(' - ')[0]);
			let max=parseInt(ob.range_display.split(' - ')[1]);
			if ((typeof pricemin ==="undefined" || typeof pricemax==="undefined") && (typeof brand === "undefined" && brand.length===0) && (typeof color==="undefined" && color.length===0))
				final_dec=false;
			else 
			{
				if((min>=pricemin && max<=pricemax) || (pricemin===0 && pricemax===0))
				{
					final_dec=true;
					if(brand.length>0)
					{
					if(typeof ob.brandName != "undefined")
					{
					var i;
					for( i=0;i<brand.length;i++)
					{
						if(ob.brandName===brand[i].name)
						{
							final_dec=true;
							break;
						}
					}
					
					if(i===brand.length)
						final_dec=false;
					}
					else
						final_dec=false;
					}
					if(color.length>0 && final_dec===true)
					{
						if(typeof ob.allcolor != "undefined")
						{
						var j;
							for( j=0;j<color.length;j++)
							{
								let temp=ob.allcolor.filter((item)=> item.name===color[j].name);
								if(temp.length>0)
								{
									final_dec=true;
									break;
								}
							}
							if(j===color.length)
								final_dec=false;
						}
						else
						final_dec=false;
					}
					if(size.length>0 && final_dec===true)
					{
						if(typeof ob.size_panel != "undefined" && typeof ob.size_panel.sizes != "undefined")
						{
						var j;
							for( j=0;j<size.length;j++)
							{
								let temp=ob.size_panel.sizes.filter((item)=> item.name===size[j].name);
								if(temp.length>0)
								{
									final_dec=true;
									break;
								}
							}
							if(j===size.length)
								final_dec=false;
						}
						else
						final_dec=false;
					}
					if(discount.length>0 && final_dec===true)
					{
						if(typeof ob.discounted != "undefined" )
						{
						var j;
							for( j=0;j<discount.length;j++)
							{
								if((discount[i].name==="yes" && ob.discounted) || ( discount[i].name==="no" && !ob.discounted))
								{
									final_dec=true;
									break;
								}
							}
							if(j===discount.length)
								final_dec=false;
						}
						else
						final_dec=true;
					}
				}
				else
					final_dec=false;
			}
		}
		else
			final_dec=true;
		return final_dec;
		
		
	}
	
	const onErrorLoginClose = () => {
        props.setMsgModal({
            "title": "",
            "message": {},
            "show": false
        });
    }


    /* const categoryList = () => {
        if(props.productsList) {
            return props.productsList.filter(item => {
                return (item.categoryId == props.selectedCategory) 
            }) 
        }
    } */
    /** @function
 * @name categoryList */
    let categoryList = props.productsList.filter(item => {
        return (item.categoryId == props.selectedCategory) 
    }).filter((item)=>filter_function(item)); 

    let col = 4;
    let row = Math.ceil(categoryList.length / col);

    //console.log("categoryList ", categoryList, col, row);
     /** @function
 * @name renderRows */
    let renderRows = () => {
        let a = 0;
        let rowSet = [];
        for (let i = 0; i < row; i++) {
            let cards = []
            for (let j = 0; j < col; j++) {
                
                cards.push(categoryList[a] 
                    ? <Col className="pt-2 pb-2 pl-2 pr-2 mob-left-right-padd-cont" xs={6} md={3} key={categoryList[a].id}><Product product={categoryList[a]} /></Col>
                    :<Col className="pt-2 pb-2 pl-2 pr-2" key={`${a}${j}`}></Col>);
                /* if(categoryList[a]){
                    cards.push(<Col className="pt-4 pb-4" key={categoryList[a].id}><Product product={categoryList[a]} /></Col>)    
                }else{
                    cards.push(<Col className="pt-4 pb-4"></Col>)
                } */
                a++
            }
            rowSet.push(<Row key={`row${i}`}>{cards}</Row>)
        }
        return rowSet
    }
	const displayCatname= () => {
		let test=props.CategoryList.filter(item =>item.id===props.selectedCategory)[0];
		if(typeof test!= "undefined")
			return test.name;
	}

    
    return (
        <ScrollInView trigger={props.selectedCategory}>
		<BreadcrumbsItem to='/category/:catid'>Categories / {displayCatname()}</BreadcrumbsItem>
		
         <Header />
        
            <div className="page-bodesection">
                
                {/* <div className="border-bottom">
                    <div className="offset-md-9">
                        <Row className="pr-5 pl-5 pt-4 pb-4 mr-0 ml-0">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text className="bg-white fsize13">
                                        Sort By :
      </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="select" className="border-left-0 fsize13 pl-0">
                                    <option>Recommended</option>
                                    <option>Recommended</option>
                                    <option>Recommended</option>
                                </Form.Control>
                            </InputGroup>
                        </Row>
                    </div>
                </div> */}
                
            </div>
            <div className="page-bodesection">
                <Col className="pl-5 pr-5 pl-30-pr-30-mob">
                    <Row className="all-cart-section">

                        <div className="w-100">
                            <Row>
                                { /* THIS IS LEFT PANEL */}
                                {(props.isMobileMenu)?(<Button variant="link" onClick={()=>{props.setMsgModal({
								"title": "Filters",
								"message": <FilterSection /> ,
								"show": true
									})}}>Filters</Button>): (<FilterSection />)}


                                { /* CARD DISPLAY AREA */}
                                <Col md={10} className="pt-2">
								<Filtertags />
                                    {categoryList.length>0 ? renderRows() : ""}
                                </Col>
                            </Row>

                        </div>
                    </Row>
                </Col>
            </div>
			{props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorLoginClose} />}

            {/* <Container className="p-0-mob">
                <div className="products-wrapper">  
                    <div className="row">
                    {categoryView}
                    </div>
                </div>
            </Container> */}    
        </ScrollInView>
    );

  }


const mapStateToProps = state => ({
    productsList: state.ProductsData.productsList,
    selectedCategory: state.CategoryData.selectedCategory,
	CategoryList: state.CategoryData.categoryList,
    cartList: state.CartData.cartList,
	pricemin: state.ProductsData.pricemin,
	pricemax:state.ProductsData.pricemax,
	filter_data:state.ProductsData.filter_data,
	isMobileMenu:state.CategoryData.isMobileMenu
	
})

export default connect(mapStateToProps,{setMsgModal})(CategoryContainer);  