import React from "react";
/**
 * Creates a new LoadingProduct.
 * @class
 */
const LoadingProduct = () => {
  return (
    <div className="product_loading">
      {/* <div className="loader"></div> */}
    </div>
  );
};

export default LoadingProduct;
