//import { apiAction } from '../middleware/api/apiActions';

// Description on const CATEGORYLIST
/** @constant
    @type {string}
    @default
*/
const SEARCHTERM = "SEARCHTERM";
/** @constant
    @type {string}
    @default
*/
const FILTERED_PRODUCTS_BY_CATEGORY = "FILTERED_PRODUCTS_BY_CATEGORY";
//.....................................

//...................................................................
/**
 * Action filterByCategory defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const filterByCategory = param => ({
    type: FILTERED_PRODUCTS_BY_CATEGORY,
    payload: param
});

//...................................................................


//..................................................................
/**
 * Action setSearchTerm defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setSearchTerm = param => ({
    type: SEARCHTERM,
    payload: param
});

//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    actualProductList: [],
    filteredProductsByCategory: "",
    searchTerm: ""
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case FILTERED_PRODUCTS_BY_CATEGORY: {
                return {
                    ...state,
                    filteredProductsByCategory: action.payload
                }
            }
        
        default:
            return state;
    }
}

//...................................................................
//.. selectors :: TODO


//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const SearchAction = {
    reducer: reducer,
    actions: {
        filterByCategory,
        setSearchTerm
    }
}

export default SearchAction