import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Container, Row, Col, Form, Navbar } from 'react-bootstrap';
import { Global, host } from '../utils/Env';
import MsgModal from './MsgModal';
import Footer from '../components/Footer';

import { login, signup,   set_message, set_status, setMsgModal } from '../actions/AuthAction';
import {
    set_fullName,
    set_address1, 
    set_address2,
    set_city,
    set_dist,
    set_state, set_pin,
	set_phone,set_email,
	set_purpose,
	set_reference	
} from '../actions/UserAction';

/**
 * Creates a new SignUp.
 * @class
 */
const SignUp = props => {

    const [validated, setValidated] = useState(false);

    const navigate = useNavigate();

    /** @function
 * @name signup_onError */
    Global.callback.signup_onError = () => {
        props.setMsgModal({
            "title": "Error in Registration",
            "message": props.message,
            "show": true
        });
    }

    useEffect(() => {
        props.set_fullName('');
            props.set_phone('');
            props.set_email('');
            props.set_address1('');
            props.set_address2('');
            props.set_city('');
            props.set_dist('');
            props.set_state('');
            props.set_pin('');
            props.set_purpose('');
            props.set_reference('');
    }, []);
      /** @function
 * @name onErrorSignUpClose */
    const onErrorSignUpClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }
          /** @function
 * @name signup_onComplete */
    Global.callback.signup_onComplete = () => {

        if (props.status == false) {
            
        } else {
            props.set_fullName('');
            props.set_phone('');
            props.set_email('');
            props.set_address1('');
            props.set_address2('');
            props.set_city('');
            props.set_dist('');
            props.set_state('');
            props.set_pin('');
            props.set_purpose('');
            props.set_reference('');
            //alert('Registration successful');
        }
        props.setMsgModal({
            "title": "Registration",
            "message": props.message,
            "show": true
        });
    }
    const handleSignUp = (event) => {
        setValidated(true);
        //const form = event.currentTarget;
        //console.log("dropdown value ",dropdowntest);  // 5-10-2020
        if (handleValidation() === true) {
			console.log("Validation successful");
        props.signup({
            "email": props.email,
            "name": props.name,
            "phone": props.phone,
            "reference": props.referenceName,
            "purpose": props.purpose,
            "address1": props.address1,
            "address2": props.address2,
            "city": props.city,
            "dist": props.dist,
            "state": props.cstate,
            "pin": props.pin,
            //"dropdowntest": dropdowntest,   // 5-10-2020
            "compUrl": `${host()}`
        });
        setValidated(false);
    }else{
		console.log("failed");
        //setValidated(true);
        event.stopPropagation();
       
    }

    }

    const handleValidation = () => {

        let formIsValid = true;

        //Name
        if(!props.name){
			props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Name Please check",
            "show": true
        });
           formIsValid = false;
		   console.log("name");
        }
  
        if(typeof props.name !== "undefined"){
           if(!props.name.match(/^[a-zA-Z ]+$/)){
              formIsValid = false;
			  console.log("name2");
			  props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Name Please check",
            "show": true
        });
           }        
        }

        //Mobile
        if(!props.name){
            formIsValid = false;
			props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Name Please check",
            "show": true
        });
         }
   
        if(typeof props.phone !== "undefined"){
            if(props.phone.length !== 10){
               formIsValid = false;
			   console.log("phone");
			   props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Phone Please check",
            "show": true
        });
            }        
        }
   
        //Email
        if(!props.email){
           formIsValid = false;
		   console.log("email");
		   props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Email Please check",
            "show": true
        });
        }
  
        if(typeof props.email !== "undefined"){
           let lastAtPos = props.email.lastIndexOf('@');
           let lastDotPos = props.email.lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && props.email.indexOf('@@') == -1 && lastDotPos > 2 && (props.email.length - lastDotPos) > 2)) {
              formIsValid = false;
			  props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Email Please check",
            "show": true
        });
			  console.log("email2");
            }
       }  

       //Address1
       if(!props.address1){
        formIsValid = false;
		console.log("address1");
		props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Address 1 Please check",
            "show": true
        });
       }
      
       //Address2
       if(!props.address2){
        formIsValid = false;
		console.log("address2");
		props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Address 2 Please check",
            "show": true
        });
       }

       //city
       if(!props.city){
        formIsValid = false;
		console.log("city");
		props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in City Please check",
            "show": true
        });
       }

       //state
       if(!props.cstate){
        formIsValid = false;
		console.log("state");
		props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in State Please check",
            "show": true
        });
       }

       //dist
       if(!props.dist){
        formIsValid = false;
		console.log("district");
		props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in District Please check",
            "show": true
        });
       }

       //pin
       if(!props.pin){
        formIsValid = false;
		console.log("pin");
		props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Pin Please check",
            "show": true
        });
       }

       if(typeof props.pin !== "undefined"){
        if(props.pin.length !== 6 ){
           formIsValid = false;
		   console.log("pin2");
		   props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Pin Please check",
            "show": true
        });
        }        
       }


       //purpose
       if(!props.purpose){
        formIsValid = false;
		console.log("purpose");
		props.setMsgModal({
            "title": "Form Validation Error",
            "message": "Error in Purpose Please check",
            "show": true
        });
       }

       //custom dropdown 05-10-2020
       /*if(!dropdowntest){
        props.setMsgModal({
        "title": "Form Validation Error",
        "message": "Error in Dropdown Please check",
        "show": true
        });
        formIsValid = false;
        console.log("dropdowntest");
        }*/


      // this.setState({errors: errors});
       return formIsValid;
   }


    /** @function
 * @name linkToSignIn */  
    const linkToSignIn = () => {
        navigate("/login");
    }
     /** @function
 * @name saveSignUp */ 
    // const saveSignUp = (event) => {
    //      setValidated(true);

    //     const form = event.currentTarget;

    //     if (form.checkValidity() === false) {
    //         setEnablePlaceOrder(false);
    //         event.stopPropagation();
    //     }
    //     event.preventDefault();

    //     if (form.checkValidity() === true) {
    //         setEnablePlaceOrder(true);
    //     }

    //     //.. callback for evaluation
    // }
    /** @function
 * @name handlenameChange */ 
    const handlenameChange = (e) => {
        props.set_fullName(e.target.value);
    }
    /** @function
 * @name handlephoneChange */
    const handlephoneChange = (e) => {
        if (e.target.value.length > 10 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9'))
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_phone(e.target.value);
    }
    /** @function
 * @name handleemailChange */
    const handleemailChange = (e) => {
        let lastAtPos = e.target.value.lastIndexOf('@');
        let lastDotPos = e.target.value.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && e.target.value.indexOf('@@') == -1 && lastDotPos > 2 && (e.target.value.length - lastDotPos) > 2))
            e.target.value = e.target.value;
            props.set_email(e.target.value);
    }
    /** @function
 * @name handleaddress1Change */
    const handleaddress1Change = (e) => {
        props.set_address1(e.target.value);
    } 
    /** @function
 * @name handleaddress2Change */
    const handleaddress2Change = (e) => {
        props.set_address2(e.target.value);
    }
    /** @function
 * @name handlecityChange */
    const handlecityChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_city(e.target.value);
    }
    /** @function
 * @name handlestateChange */
    const handlestateChange = (e) => {
        if (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_state(e.target.value);
    }
     /** @function
 * @name handledistChange */
    const handledistChange = (e) => {
        props.set_dist(e.target.value);
    }
    /** @function
 * @name handlepinChange */
    const handlepinChange = (e) => {
        if ((e.target.value.length > 6 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        props.set_pin(e.target.value);
    }
    /** @function
 * @name handlepurposeChange */
    const handlepurposeChange = (e) => {
        props.set_purpose(e.target.value);
    }
    /** @function
 * @name handlereferenceNameChange */
    const handlereferenceNameChange = (e) => {
        props.set_reference(e.target.value);
    }
    //let dropdowntest;
    /** //@function
 /* @name handleDropdownChange */     // 5-10-2020
    /*const handleDropdownChange = (e) => {
        dropdowntest = e.target.value;
    }*/
     /** @function
 * @name handleattachGSTChange */
    /*const handleattachGSTChange = (e) => {
        props.set_attachGST(e.target.value);
    }*/
    /** @function
 * @name handleattachPANChange */
    /*const handleattachPANChange = (e) => {
        props.set_attachPAN(e.target.value);
    }*/
    /** @function
 * @name handlegstNumberChange */
    /*const handlegstNumberChange = (e) => {
        props.set_gstNumber(e.target.value);
    }*/

    return (
        <div className="page-bodesection">
            <Navbar bg="light" sticky="top" expand="lg border-bottom" className="navstylecontrol ">
                <Navbar.Brand>
                    <img src={require("../images/logo.png")} alt="logo" />
                </Navbar.Brand>
            </Navbar>
            <Container>
                <Row>
                <Col>
                    <Row className="pt-5 pb-5">
                        <Col sm={8} className="hidden-xs">
                            <div className="position-relative z-index9">
                                <img src={require("../images/new-user-bg.png")} alt="Login Banner" className="w-75control" />
                            </div>
                            <div className="position-absolute log-sign-leftdiv signin-sign-leftdiv bge9e1d4 text-center">
                                <div>
                                    <h1 className="text-center log-welcome-text mb-0"><b>WELCOME</b></h1>
                                    <h3><i>To</i></h3>
                                    <label className="log-banner-logo mb-1 bg-white"><img src={require("../images/log-logo.png")} alt="Login Banner" /></label>
                                    <p className="mb-2"><b>Online Fashion Store</b></p>
                                    <h4 className="b2btagline mb-4">B2B ORDERING PLATFORM</h4>
                                    <Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2 position-relative z-index9" onClick={linkToSignIn} >SIGN IN</Button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div>
                                <fieldset className="scheduler-border rounded">
                                    <legend className="scheduler-border signin-label">New User? Register Here</legend>
                                    <Form noValidate validated={validated}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Full Name <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Full Name" value={props.name} required  onChange={handlenameChange} />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Mobile No <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control placeholder="Enter Mobile No." required pattern='[0-9]{10}' value={props.phone} onChange={handlephoneChange} />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label className="fsize14 mb-1"><b>Email Address <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control placeholder="Enter Email" value={props.email} required pattern='[a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*@[a-zA-Z]{1}[a-zA-Z]*[.]{1}[a-z]{1}[a-z]*' onChange={handleemailChange} />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Address 1 <i className="text-danger">*</i></b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address 1" value={props.address1} required onChange={handleaddress1Change} />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Address 2</b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address 2" value={props.address2} required onChange={handleaddress2Change} />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>City</b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter City" value={props.city} required onChange={handlecityChange} required />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>District</b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter District" value={props.dist} onChange={handledistChange} required />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>State</b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter State" value={props.cstate} onChange={handlestateChange} required />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Pin Code</b></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Pin Code" value={props.pin} onChange={handlepinChange} pattern='[0-9]{6}' required />

                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Purpose</b></Form.Label>
                                            <Form.Control value={props.purpose} placeholder="Enter Purpose" onChange={handlepurposeChange} required type="text" className="signinselect">
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Reference/Broker Name.</b><i>(optional)</i></Form.Label>

                                            <Form.Control type="text" placeholder="Enter Reference" value={props.referenceName} onChange={handlereferenceNameChange} />
                                        </Form.Group>
                                        {/* <Form.Group controlId="formBasicEmail">
                                            <Form.Label className="fsize14 mb-1"><b>Custom Dropdown</b></Form.Label>

                                            <Form.Control as="select" custom required onChange={handleDropdownChange} >
                                                <option disabled selected value="">--Select One--</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Control>
                                        </Form.Group> */}
                                        {/*<Form.Label className="fsize14 mb-1" style={{ display: 'none' }}><b>GST Info.</b></Form.Label>
                                        <Form.Group className="p-3 border rounded" style={{ display: 'none' }}>
                                            <Form.Control type="text" placeholder="Enter GST No." value={props.gstNumber} onChange={handlegstNumberChange} />
                                            <Form.Label className="fsize14 mb-2 mt-1"><b>Attach GST Document</b></Form.Label>
                                            <Form.File className="fsize14" value={props.attachGST} onChange={handleattachGSTChange} />
                                        </Form.Group>
                                        <Form.Group className="p-3 border rounded mt-4" style={{ display: 'none' }}>
                                            <Form.Label className="fsize14 mb-1"><b>Attach Your PAN Card</b></Form.Label>
                                            <Form.File className="fsize14" value={props.attachPAN} onChange={handleattachPANChange} />
                                            <Form.Control type="text" placeholder="Enter Reference" value={props.referenceName} onChange={handlereferenceNameChange} />
                                        </Form.Group>*/}
                                        <div className="mt-4"><Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2 mb-4 bg-bordb02409" onClick={handleSignUp}>REGISTER HERE</Button>{' '}</div>
                                        <div className="hidden-desktop">
                                        <p className="fsize13 font-weight-bold text-muted">Existence User? <a href="" className="fsize13 font-weight-bold text-danger text-decoration-underline"><span onClick={linkToSignIn}>Sign In</span></a></p>
                                        </div>
                                    </Form>
                                </fieldset>
                            </div>
                        </Col>
                    </Row>
                </Col>
                </Row>
                
            </Container>
            <Footer />
            {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorSignUpClose} />}
        </div>
    );
}

const mapStateToProps = state => ({

    // name: state.AuthData.name,
    // phone: state.AuthData.phone,
    // email: state.AuthData.email,
    // purpose: state.AuthData.purpose,
    // referenceName: state.AuthData.referenceName,
    // attachGST: state.AuthData.attachGST,
    // attachPAN: state.AuthData.attachPAN,
    // attachGST: state.AuthData.attachGST,
    // gstNumber: state.AuthData.gstNumber,

    message: state.AuthData.message,
    status: state.AuthData.status,
    email: state.UserData.email,
    purpose: state.UserData.purpose,
    phone: state.UserData.phone,
    email: state.UserData.email,
    pin: state.UserData.pin,
    address1: state.UserData.address1,
    address2: state.UserData.address2,
    city: state.UserData.city,
    cstate: state.UserData.cstate,
    dist: state.UserData.dist,

    modal: state.AuthData.modal,

    name: state.UserData.fullName,
    purpose: state.UserData.purpose,
    referenceName: state.UserData.reference
})

// export default connect(mapStateToProps, {setMsgModal,
//     login, signup, set_fullName, set_phone, set_email, set_address1, set_address2,
//     set_city,
//     set_dist,
//     set_state, set_pin, set_purpose, set_referenceName, set_attachGST, set_attachPAN, set_attachPAN, set_gstNumber, set_message, set_status,set_reference

// })

export default connect(mapStateToProps, {setMsgModal,
    login, signup, set_fullName, set_message, set_status, 
	set_phone, set_email, set_address1, set_address2,
    set_city,
    set_dist,
    set_state, set_pin, set_purpose, set_reference
})(SignUp);