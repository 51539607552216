import React from 'react';
import { connect } from 'react-redux';


import CartItem from './CartPreviewItem';
import EmptyCart from "../EmptyCart";
import { Container } from 'react-bootstrap';

/**
 * Creates a new PreviewItemList.
 * @class
 */
const PreviewItemList = (props) => {

  let cartItems = props.cartItems.map((product, index) => {
    return <CartItem key={`${product.name}${index}`} product={product} />
  });
/** @function
 * @name hasItems */
  let hasItems = () => {
    return (props.cartItems && (props.cartItems.length > 0)) ? true : false
  }

  return (<Container className="p-0-mob">
        <div className="itemlist">
          <div className="itemlistinner">
            {hasItems() ? cartItems : <EmptyCart />}
          </div>
        </div>
      </Container>)
}

const mapStateToProps = state => ({
  cartItems: state.CartData.cartList,
  totalAmount: state.CartData.totalAmount
});

export default connect(mapStateToProps)(PreviewItemList);