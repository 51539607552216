import React from "react";
import {ColorLuminance} from '../utils/CommonUtil';
/**
 * Creates a new ColorCode.
 * @class
 */
const ColorCode = props => {
    const dot = color => ({
        height: '13px',
        width: '13px',
        backgroundColor: color,
        border: `1px solid ${ColorLuminance(color, -60)}`,
        borderRadius: '50%',
        display: 'inline-block',
        margin: "1px",
        marginRight:"3px",
        verticalAlign: "text-top",
    }) 
  
    return (
    <React.Fragment>
        <span style={dot(props.color)} aria-hidden="true"></span>
    </React.Fragment>
  );
};

export default ColorCode;