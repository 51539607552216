import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate,  useLocation } from 'react-router-dom';
import { getCategoryList, selectCategory, setMenuState } from '../../actions/CategoryAction';
import Category  from './Category';
import { Nav } from 'react-bootstrap';
import { filterByCategory } from '../../actions/ProductsAction';
import Scroller from '../Scroller';
/**
 * Creates a new CategoryMenu.
 * @class
 */
 

const CategoryMenu = (props) => {

    const {companyid, getCategoryList } = props;

    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line
    useEffect(() => { 
        if(companyid && companyid !== '') {
            getCategoryList({"compId":companyid})
          }
    }, [companyid]);
    
    useEffect(() => {
        props.selectCategory(location.pathname.replace('/category/',''))
    },[location.pathname]);
    /** @function
 * @name onCategorySelect */
    const onCategorySelect = (eventKey) => {
        props.selectCategory(eventKey);
        props.filterByCategory(eventKey);
        if(props.isMobileMenu) {
            props.setMenuState(false)
        }
        navigate(`/category/${eventKey}`);
	}
    
	const data=props.CategoryList.map((category, key) => (
                        <Category category={category} key={key} keyValue={key}/>
					))
					
					
    
    return (
        <div className={props.menuState ? "menu-items" : "hideclassmain"}>
            
			 <Nav className="mr-auto" onSelect={onCategorySelect} >
				 {data}
                </Nav>
            
        </div>
    )
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    CategoryList: state.CategoryData.categoryList,
    menuState: state.CategoryData.menuState,
    isMobileMenu: state.CategoryData.isMobileMenu
})

export default connect(mapStateToProps, {getCategoryList, selectCategory, filterByCategory, setMenuState})(CategoryMenu);