import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';
 /** @constant
    @type {string}
    @default
*/
const PLACE_ORDER = 'PLACE_ORDER';
/** @constant
    @type {string}
    @default
*/
const CHECKOUT_ORDER = 'CHECKOUT_ORDER';
/** @constant
    @type {string}
    @default
*/
const SET_MODAL = 'SET_MODAL';
/** @constant
    @type {string}
    @default
*/
const SPECIAL_REQUEST = 'SPECIAL_REQUEST';
/** @constant
    @type {string}
    @default
*/
const SET_PROMO_MODAL = 'SET_PROMO_MODAL';
/** @constant
    @type {string}
    @default
*/
const VALIDATE_DISCOUNT = 'VALIDATE_DISCOUNT';
/** @constant
    @type {string}
    @default
*/
const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
/** @constant
    @type {string}
    @default
*/
const CLEAR_ORDER = 'CLEAR_ORDER';
/**
 * Action checkOutOrder defined.
 * @constructor
 * @param {object} obj - Pass obj as payload to call checkout order.
 */
export const checkOutOrder = (obj) => {
    return apiAction({ 
        url: API.CHECKOUT_ORDER,
        method: "post",
        data: obj,
        onSuccess: orderCheckedOut,
        onFailure: () => console.log("Error occured in placeOrder"),
        callback: 'checkOutOrder',
        label: CHECKOUT_ORDER
    });
}
/**
 * Action orderCheckedOut defined.
 * @constructor
 * @param {object} param - Pass object as payload to insert checkout order.
 */

export const orderCheckedOut = param => ({
    type: CHECKOUT_ORDER,
    payload: param.data
});

/**
 * Action placeOrder defined.
 * @constructor
 * @param {object} obj - pass object as payload to check order data.
 */
export const placeOrder = (obj) => {
    return apiAction({
        url: API.PLACE_ORDER,
        method: "post",
        data: obj,
        onSuccess: orderPlaced,
        onFailure: () => console.log("Error occured in placeOrder"),
        label: PLACE_ORDER
    });
}
/**
 * Action orderPlaced defined.
 * @constructor
 * @param {object} obj - pass object as payload to insert order data.
 */
export const orderPlaced = param => ({
    type: PLACE_ORDER,
    payload: param.data
});

//...............................................................
/**
 * Action validateDiscount defined.
 * @constructor
 * @param {object} obj - Pass object as payload to validate discount.
 */ 
export const validateDiscount = (obj) => {
    return apiAction({
        url: API.VALIDATE_DISCOUNT,
        method: "post",
        data: obj,
        onSuccess: discountValidated,
        onFailure: () => console.log("Error occured in validateDiscount"),
        label: VALIDATE_DISCOUNT
    });
}
/**
 * Action discountValidated defined.
 * @constructor
 * @param {object} obj - Pass object as payload to insert discount.
 */ 
export const discountValidated = param => ({
    type: VALIDATE_DISCOUNT,
    payload: param.data
});

//...................................................................
/**
 * Action setModal defined.
 * @constructor
 * @param {boolean} param - Pass boolean as payload to set modal.
 */ 
export const setModal = (param) => ({
    type: SET_MODAL,
    payload: param
});
//...................................................................
/**
 * Action setPromModal defined.
 * @constructor
 * @param {boolean} param - Pass boolean as payload to hide and show modal
 */
export const setPromModal = (param) => ({
    type: SET_PROMO_MODAL,
    payload: param
});
//...................................................................
/**
 * Action removeDiscount defined.
 * @constructor
 */
export const removeDiscount = () => ({
    type: REMOVE_DISCOUNT,
    payload: null
});

/**
 * Action clearOrder defined.
 * @constructor
 */
export const clearOrder = () => ({
    type: CLEAR_ORDER,
    payload: null
});



//................................................................... 
/**
 * Action set_special_request defined.
 * @constructor
 * @param {string} param - pass string as payload to hide and show modal
 */
export const set_special_request = (param) => ({ type: SPECIAL_REQUEST, payload: param })
/**
 * set up with initial state.
 */
const initialState = {
    items: [],
    checkoutDetails: {},
    special_request: '',
    payment_mode: 'COD',
    delivery_slot: '',
    selected_voucher: {},
    confirmationModal: false,
    promoModal: false,
    order: {},
    discountDetails: {}
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ORDER: return initialState
        case PLACE_ORDER: return { ...state, order: action.payload, confirmationModal: true }
        case CHECKOUT_ORDER: return { ...state, checkoutDetails: action.payload }
        case SET_MODAL: return { ...state, confirmationModal: action.payload }
        case SET_PROMO_MODAL: return { ...state, promoModal: action.payload }
        case SPECIAL_REQUEST: return { ...state, special_request: action.payload }
        case REMOVE_DISCOUNT: return { ...state, discountDetails: {}, selected_voucher: {} }
        case VALIDATE_DISCOUNT: return {
            ...state,
            promoModal: false,
            discountDetails: action.payload,
            checkoutDetails: { ...state.checkoutDetails, ...action.payload.order },
            selected_voucher: action.payload.voucher
        }
        default: return state;
    }
}
/**
 * Action selectCheckOutJSON defined.
 * @constructor
 * @param {object} state - Pass object as payload to store checkout data
 */
//...................................................................
//.. selectors :: TODO
export function selectCheckOutJSON(state) {

    return {
        "compId": state.HomeData.companyid,
        "delivery": {
            "pinCode": state.LocationData.pin
        },      
        "items": state.CartData.cartList.map(item => {
            return {
                id: item.id,
                categoryId: item.categoryId,
                subCategoryId: item.subCategoryId,
                itemBrandId: item.itemBrandId,
                variantSortOrderInCategory: item.variantSortOrderInCategory,
                orders: item.orders,
                quantity: item.quantity,
                status: "order placed"
            }
        })
    
    }
}
/**
 * Action selectValidateDiscountJSON defined.
 * @constructor
 * @param {object} state - Pass object as payload to store validate discount data
 */
export function selectValidateDiscountJSON(state) {
    return {
        "compId": state.HomeData.companyid,
        "order": {
            "totalAmount": state.CheckoutData.checkoutDetails.totalAmount,
            "totalTaxAmount": state.CheckoutData.checkoutDetails.totalTaxAmount,
            "totalDiscountAmount": state.CheckoutData.checkoutDetails.totalDiscountAmount,
            "deliveryChargeAmount": state.CheckoutData.checkoutDetails.deliveryChargeAmount,
            "grandTotalAmount": state.CheckoutData.checkoutDetails.grandTotalAmount
        }
          
    }
}
/**
 * Action selectPlaceOrderJSON defined.
 * @constructor
 * @param {object} state - Pass object as payload to store placed order data
 */
export function selectPlaceOrderJSON(state) {

    return {
        "compId": state.HomeData.companyid,
        "orderStatus": null,
		"codeDiscount": (typeof state.CheckoutData.selected_voucher.valid != "undefined" && state.CheckoutData.selected_voucher.valid==true)?(state.CheckoutData.selected_voucher.code):null,
        "totalAmount": state.CheckoutData.checkoutDetails.totalAmount,
        "totalTaxAmount": state.CheckoutData.checkoutDetails.totalTaxAmount,
        "totalDiscountAmount": state.CheckoutData.checkoutDetails.totalDiscountAmount,
        "deliveryChargeAmount": state.CheckoutData.checkoutDetails.deliveryChargeAmount,
        "grandTotalAmount": state.CheckoutData.checkoutDetails.grandTotalAmount,
        
        "user": {
          "id": state.UserData.user.id,
          "name": state.UserData.user.name,
		  "address1": state.UserData.deliveryAddress1,
		  "address2": state.UserData.deliveryAddress2,
		  "pin": state.UserData.deliveryPin,
		  "city": state.UserData.deliveryCity,
		  "dist": state.UserData.deliveryDistrict,
		  "state": state.UserData.deliveryState,
		  "phone": state.UserData.deliveryPhone,
		  "spec_ins": state.UserData.delivery_spec_ins
        },

        "items": state.CartData.cartList.map(item => {
            return {
                id: item.id,
                categoryId: item.categoryId,
                subCategoryId: item.subCategoryId,
                itemBrandId: item.itemBrandId,
                variantSortOrderInCategory: item.variantSortOrderInCategory,
                orders: item.orders
            }
        })
    }
}
//...................................................................

/**
 * Export Object (Action + Reducer)
 */
const CheckoutAction = {
    reducer: reducer,
    actions: {
        checkOutOrder,
        placeOrder,
        setModal,
        set_special_request,
        setPromModal,
        validateDiscount,
        removeDiscount
    }
}

export default CheckoutAction 

