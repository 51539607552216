import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setVariantModal, clearVariants } from '../actions/ProductsAction';
import ImageUtil from '../utils/ImageUtil';
import Counter from './Counter';
import { to2Decimal, formatINR } from '../utils/CommonUtil';
/**
 * Creates a new VarientModal.
 * @class
 */
const VarientModal = (props) => {
    const show = props.variantModal;
    const items = props.variantList;
    /** @function
 * @name handleClose */
    const handleClose = () => {
        props.setVariantModal(false);
        props.clearVariants();
    } 
        /** @function
 * @name renderVariants */
    const renderVariants = () => {
        let list = items.map((item,indx) => {

            return (
               
                <Row className="variant-right variant-right-active" key={indx}>
                    <Col>
                   <p className="variant-item-size">{item.size}</p>
                   <p className="variant-item-peg-price text-dark"><b>{item.promo_price ? formatINR(to2Decimal(item.promo_price)) : formatINR(to2Decimal(item.price_regular))}</b></p>
                   {item.promo_price ?<p className="variant-item-promo-price"><s>{formatINR(to2Decimal(item.price_regular))}</s></p> : "" }
                    </Col>
                   
                    <Col>
                        {item.promo_percentage ? <div className="centered-div"><span className="badge badge-pill badge-success pt-1 pb-1 bg-red font-weight-normal">{item.promo_percentage}</span></div> : "" }
                    </Col>
                    <Col>
                       <div className="centered-div"><Counter product={item}/></div> 
                    </Col>
                    
                </Row>
                
            )
        })
        return list;
    }
    if (items[0]) {
        return (
            <React.Fragment>
                <Modal show={show} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton  className="close-button">
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                        <Col md="3 pl-0">
                            <div className="product-image vl-top-d-inline-mob mob-wid-30">

                                {(items[0].brandicon !== "") ?
                                    <div className="brand-iconmaindiv">
                                        <img className="brand-img" alt={items[0].brandicon} src={ImageUtil.getImage(items[0].brandicon)} />
                                    </div> : null}
                                <img className="product-img"
                                    src={(items[0].images[0]) ? ImageUtil.getImage(items[0].images[0].name) : ImageUtil.getErrorImage()}
                                    alt={items[0].name}
                                    onError={(e) => {
                                        e.target.src = ImageUtil.getErrorImage();
                                    }}
                                />
                        </div>
                        <div className="vl-top-d-inline-mob mob-wid-65">
                        <p className="text-center pt-1 pb-1 variant-p-name">{items[0].name}</p>
                        {/* eslint-disable-next-line */}
                        {/* <a className="d-block text-center fs-13-f-n" href="#">View Details</a> */}
                        </div>
                        </Col>
                        <Col md="9 p-0 mt-10-mob">
                        {renderVariants()}
                        </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
    else {
        return ""
    }
}

const mapStateToProps = state => ({
    variantModal: state.ProductsData.variantModal,
    variantList: state.ProductsData.variantList
})
export default connect(mapStateToProps, { setVariantModal, clearVariants })(VarientModal);