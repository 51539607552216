import React,{useEffect,useState} from 'react';
import { connect } from 'react-redux';
import { useLocation,useNavigate } from 'react-router-dom';
import Product from '../components/Product';
import { Row, Col,Button } from 'react-bootstrap';
import NoResults from '../components/NoResults';
import FilterSection from '../components/FilterSection';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import Header from '../components/header/Header';
import Filtertags from '../components/FilterTags';
import MsgModal from './MsgModal';
import { setMsgModal } from '../actions/AuthAction';


/**
 * Creates a new SearchPanel.
 * @class
 */
function SearchPanel(props) {

    const location = useLocation();
	const navigate = useNavigate();
	const [pricemin,setpriceMin]=useState(0);
	const [pricemax,setpriceMax]=useState(0);
	const [brand,setBrand]=useState([]);
	const [color,setColor]=useState([]);
	const [size,setSize]=useState([]);
	const [discount,setDiscount]=useState([]);

     /** @function
     * @name searchingFor 
     */
	 
	 useEffect(() => {
        setpriceMin(props.pricemin);
		setpriceMax(props.pricemax);
		if(typeof props.filter_data != "undefined" && typeof props.filter_data.brands != "undefined" && typeof props.filter_data.colors!="undefined" && typeof props.filter_data.sizes != "undefined"  && typeof props.filter_data.discounts !="undefined")
		{
			setBrand(props.filter_data.brands.filter((item)=> item.checked===true));
			setColor(props.filter_data.colors.filter((item)=> item.checked===true));
			setSize(props.filter_data.sizes.filter((item)=> item.checked===true));
			setDiscount(props.filter_data.discounts.filter((item)=> item.checked===true));
		}
    }, [props.pricemin,props.pricemax,props.filter_data]);
	
	const filter_function = (ob) => 
	{
		let final_dec=false;
		if(typeof ob!="undefined" && (brand.length>0 || color.length>0 || (pricemin>0 && pricemax>0) || size.length>0) )
		{
			let min=parseInt(ob.range_display.split(' - ')[0]);
			let max=parseInt(ob.range_display.split(' - ')[1]);
			if ((typeof pricemin ==="undefined" || typeof pricemax==="undefined") && (typeof brand === "undefined" && brand.length===0) && (typeof color=="undefined" && color.length===0))
				final_dec=false;
			else 
			{
				if((min>=pricemin && max<=pricemax) || (pricemin===0 && pricemax===0))
				{
					final_dec=true;
					if(brand.length>0)
					{
					if(typeof ob.brandName != "undefined")
					{
					var i;
					for( i=0;i<brand.length;i++)
					{
						if(ob.brandName===brand[i].name)
						{
							final_dec=true;
							break;
						}
					}
					
					if(i==brand.length)
						final_dec=false;
					}
					else
						final_dec=false;
					}
					if(color.length>0 && final_dec===true)
					{
						if(typeof ob.allcolor != "undefined")
						{
						var j;
							for( j=0;j<color.length;j++)
							{
								let temp=ob.allcolor.filter((item)=> item.name===color[j].name);
								if(temp.length>0)
								{
									final_dec=true;
									break;
								}
							}
							if(j===color.length)
								final_dec=false;
						}
						else
						final_dec=false;
					}
					if(size.length>0 && final_dec===true)
					{
						if(typeof ob.size_panel != "undefined" && typeof ob.size_panel.sizes != "undefined")
						{
						var j;
							for( j=0;j<size.length;j++)
							{
								let temp=ob.size_panel.sizes.filter((item)=> item.name===size[j].name);
								if(temp.length>0)
								{
									final_dec=true;
									break;
								}
							}
							if(j===size.length)
								final_dec=false;
						}
						else
						final_dec=false;
					}
					if(discount.length>0 && final_dec===true)
					{
						if(typeof ob.discounted != "undefined" )
						{
						var j;
							for( j=0;j<discount.length;j++)
							{
								if((discount[i].name==="yes" && ob.discounted) || ( discount[i].name==="no" && !ob.discounted))
								{
									final_dec=true;
									break;
								}
							}
							if(j===discount.length)
								final_dec=false;
						}
						else
						final_dec=true;
					}
				}
				else
					final_dec=false;
			}
		}
		else
			final_dec=true;
		return final_dec;
		
		
	}
	
	const onErrorLoginClose = () => {
        props.setMsgModal({
            "title": "",
            "message": {},
            "show": false
        });
    }


    const searchingFor = (term) => {
        return x => {
			if(typeof x.name != "undefined" && typeof term != "undefined" && x.itemCode !="undefined" && x.description != "undefined" && x.name != null && term !=null && x.itemCode != null && x.description !=null)
            {return x.name.toLowerCase().includes(term.toLowerCase())
                    || x.itemCode.toLowerCase().includes(term.toLowerCase()) 
                    || x.description.toLowerCase().includes(term.toLowerCase()) 
                    || !term;
			}
			else
				return false
        }
    }

    let productsData = [];
    if (props.productsList) {
		if(location.state===null)
			navigate('/', {replace: true});
		else
        productsData = props.productsList.filter(searchingFor(location.state.term))
    }
     /** @function
      * @name renderRows 
      */
    const renderRows = (list2Render) => {

        let a = 0;
        let rowSet = [];

        if(list2Render){
            let col = Array.from(Array(4).keys());
            let row = Array.from(Array(Math.ceil(list2Render.length / col.length)).keys());

            for (let i = 0; i < row.length; i++) {
                let cards = []
                for (let j = 0; j < col.length; j++) {
                    cards.push(list2Render[a] 
                        ? <Col className="pt-4 pb-4 " key={`col${list2Render[a].id}`}><Product product={list2Render[a]} /></Col> 
                        : <Col className="pt-4 pb-4 " key={`empty${j}`}></Col>
                    );
                    a++
                }
                rowSet.push(<Row key={`row${i}`}>{cards}</Row>)
            }
        }
        return rowSet
    } 

    return (

        <React.Fragment>
	
         <Header />
        
		 <BreadcrumbsItem to='/search'>Search / {location.state.term}</BreadcrumbsItem>
            <div className="page-bodesection">

            </div>
            <div className="page-bodesection">
                <Col className="pl-5 pr-5 pl-30-pr-30-mob">
                    <Row className="all-cart-section">

                        <div className="w-100">
                            <Row>
                                { /* THIS IS LEFT PANEL */}
                                {(props.isMobileMenu)?(<Button variant="link" onClick={()=>{props.setMsgModal({
								"title": "Filters",
								"message": <FilterSection /> ,
								"show": true
									})}}>Filters</Button>): (<FilterSection />)}


                                { /* CARD DISPLAY AREA */}
                                <Col md={10} className="pt-2">
								
								<Filtertags />
                                    {productsData.length > 0 ? renderRows(productsData.filter((item)=>filter_function(item))) : <NoResults/>} 
                                    {/* {productsData.length > 0 ? productsData : APIAlert("No data received").error()} */}
                                </Col>
                            </Row>

                        </div>
                    </Row>
                </Col>
            </div>
			{props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorLoginClose} />}


        </React.Fragment>
        
    );
}

const mapStateToProps = state => ({
    productsList: state.ProductsData.productsList,
    cartList: state.CartData.cartList,
	pricemin: state.ProductsData.pricemin,
	pricemax:state.ProductsData.pricemax,
	filter_data:state.ProductsData.filter_data,
	isMobileMenu:state.CategoryData.isMobileMenu

})

export default connect(mapStateToProps,{setMsgModal})(SearchPanel);
