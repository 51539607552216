import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Row, Col, Container, Navbar, Nav, NavDropdown, Form, FormControl, InputGroup, ListGroup, Card, Tab, Tabs, Table, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLocation,useNavigate } from 'react-router-dom';
import { Global } from '../utils/Env';
import {
  set_fname,
  set_lname,
  set_email,
  set_email1,
  set_address1,
  set_address2,
  set_city,
  set_dist,
  set_state, set_phone, set_phone1, set_pin,
  set_reference, set_purpose, set_org, set_org_name, set_org_phone, set_org_type, set_org_email, set_org_address1, set_org_address2, set_org_city, set_org_district, set_org_state, set_org_pin,set_pan,set_gst,set_state_code,profileSave,selectProfileSaveJSON,changePassword
} from '../actions/UserAction';
import Header from '../components/header/Header';
import MsgModal from './MsgModal';
import {setMsgModal} from '../actions/AuthAction';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import ColorCode from '../components/ColorCode';
import AllOrders from '../components/checkout/AllOrders';

/**
 * Creates a new Profile.
 * @class
 */
const Profile = (props) => {

  const [validated, setValidated] = useState(false);
  const [orgvalidated, setOrgValidated] = useState(false);
  const [orgCheck,setOrgCheck] = useState(false);
  const [gstvalidated, setGstValidated] = useState(false);
  const [gstCheck,setGstCheck] = useState(false);
  const [profileCheck, setProfileCheck] = useState(false);
  const [pwdvalidated,setPwdValidated] = useState(false);
  const [pwdCheck,setPwdCheck] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const emptyprofileForm = {
    email: props.email,
    email1: props.email1,
    fname: props.fname,
    lname: props.lname,
    address1: props.address1,
    address2: props.address2,
    city: props.city,
    dist: props.dist,
    state: props.cstate,
    pin: props.pin,
    phone: props.phone,
    phone1: props.phone1,
    reference: props.reference,
    purpose: props.purpose

  }
  const emptyorgForm = {
    org_email: props.org_email,
    org_name: props.org_name,
    org: props.org,
    org_address1: props.org_address1,
    org_address2: props.org_address2,
    org_city: props.org_city,
    org_district: props.org_district,
    org_state: props.org_state,
    org_pin: props.org_pin,
    org_phone: props.org_phone,
    org_type: props.org_type

  }
  const emptygstForm={
	  state_code:props.state_code,
	  gst:props.gst,
	  pan:props.pan
	  
  }
  
  const emptypwdForm={
	  old_pwd:'',
	  new_pwd:'',
	  re_new_pwd:''
  }
  useEffect(() => {
        if( props.user.id==null || props.user.id=='')
			navigate('/', {replace: true});
    }, []);
  
  const [orgData, setOrgData] = useState(emptyorgForm);
  const [profileData, setProfileData] = useState(emptyprofileForm);
  const [gstData, setGstData] = useState(emptygstForm);
  var profileData1=emptyprofileForm;
  var gstData1=emptygstForm;
  const [pwdData, setPwdData] = useState(emptypwdForm);
  const { email, email1, fname, lname, address1, address2, city, state, pin, dist, phone, phone1, reference, purpose } = profileData;
  const { org, org_name, org_type, org_phone, org_email, org_address1, org_address2, org_city, org_district, org_pin, org_state } = orgData;
  const { state_code,gst,pan } = gstData;
  /** @function
 * @name profile_onComplete */
   Global.callback.profile_onComplete = () => {
        console.log("Profile_onComplete ");
        props.setMsgModal({
            "title": "User Information",
            "message": props.message,
            "show": true
        });
    }
    /** @function
 * @name profile_onError */
    Global.callback.profile_onError = () => {
		console.log("Profile_onError ");
        props.setMsgModal({
            "title": "Error in Profile Save",
            "message": "Error in saving your profile",
            "show": true
        });
    }
    /** @function
 * @name password_onComplete */
	Global.callback.password_onComplete = () => {
        console.log("Password_onComplete ");
        props.setMsgModal({
            "title": "Password Changed Successfully",
            "message": props.message,
            "show": true
        });
    }
    /** @function
 * @name password_onError */
    Global.callback.password_onError = () => {
		console.log("Password_onError ");
        props.setMsgModal({
            "title": "Error in Password Change",
            "message": "Error in changing your password",
            "show": true
        });
    }
	
    /** @function
 * @name onErrorLoginClose */
    const onErrorLoginClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }
    /** @function
 * @name setFinalData */
  const setFinalData = (item) => {
    //+++++++++++++++++++++++++
    props.set_fname(item.fname);
    props.set_lname(item.lname);
    props.set_email(item.email);
    props.set_email1(item.email1);
    props.set_address1(item.address1);
    props.set_address2(item.address2);
    props.set_city(item.city);
    props.set_state(item.state);
    props.set_dist(item.dist);
    props.set_pin(item.pin);
    props.set_phone(item.phone);
    props.set_phone1(item.phone1);
    props.set_reference(item.reference);
    props.set_purpose(item.purpose);
    //+++++++++++++++++++++++++
  }
   /** @function
 * @name setFinalOrgData */
  const setFinalOrgData = (item) => {
    //+++++++++++++++++++++++++
    props.set_org_name(item.org_name);
    props.set_org(item.org);
    props.set_org_type(item.org_type);
    props.set_org_email(item.org_email);
    props.set_org_phone(item.org_phone);
    props.set_org_address1(item.org_address1);
    props.set_org_city(item.org_city);
    props.set_org_state(item.org_state);
    props.set_org_district(item.org_district);
    props.set_org_pin(item.org_pin);
    props.set_org_address2(item.org_address2);
    //+++++++++++++++++++++++++
  }
  /** @function
 * @name setFinalGstData */
  const setFinalGstData =(item) => {
	  props.set_state_code(item.state_code);
	  props.set_gst(item.gst);
	  props.set_pan(item.pan);
  }

  //functions for Changing Profule Data Start
  /** @function
 * @name sendProfileData */
  const sendProfileData = (e) => {
	  console.log(props.profileSaveData);
	  console.log("Saved");
	  props.profileSave(props.profileSaveData);
  }
  /** @function
 * @name handleOnChange */
  const handleOnChange = (e) => {
    if (e.target.name == 'pin' && (e.target.value.length > 6 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    if (e.target.name == 'phone' && (e.target.value.length > 10 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    if (e.target.name == 'phone1' && (e.target.value.length > 10 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    if ((e.target.name == 'state' || e.target.name == 'city') && e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    if (e.target.name == 'purpose')
      console.log(e.target.value);
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
	profileData1={ ...profileData, [e.target.name]: e.target.value };
  }
  /** @function
 * @name formChange */
  const formChange = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false)
      setProfileCheck(false);
    else if (form.checkValidity() == true)
	{
		setFinalData(profileData1);
		setProfileCheck(true);
	}
  }
  /** @function
 * @name saveProfile */
  const saveProfile = (event) => {
	      setValidated(true);
    console.log("saving Customer details");
    //document.getElementById("addtype").options[1].selected = true;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      props.setMsgModal({
            "title": "Profile Page Validation Error",
            "message": "Data Incorrect. Please check and try again",
            "show": true
        });
    }
    event.preventDefault();

    if (form.checkValidity() === true) {
      //.. Save DETAILS OF THE FORM
      setFinalData(profileData1);
	  sendProfileData();
      // to be replaced with updateAddress call to server
    }



  }


  //funcitons for Changing Profile Data End

  //functions for Changing Org Data Start
/** @function
 * @name handleOrgChange */
const handleOrgChange = (e) => {
    if (e.target.name == 'org_pin' && (e.target.value.length > 6 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    if (e.target.name == 'org_phone' && (e.target.value.length > 10 || !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    if ((e.target.name == 'org_state' || e.target.name == 'org_city') && e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    if (e.target.name == 'purpose')
      console.log(e.target.value);
    setOrgData({ ...orgData, [e.target.name]: e.target.value });
	console.log(orgData);
    setOrgValidated(true);
  }
  /** @function
 * @name orgformChange */
   const orgformChange = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false)
      setOrgCheck(false);
    else if (form.checkValidity() == true)
      setOrgCheck(true);
  }
/** @function
 * @name saveOrgData */
const saveOrgData = (event) => {
    console.log("saving Customer details");
    //document.getElementById("addtype").options[1].selected = true;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      props.setMsgModal({
            "title": "Profile Page Validation Error",
            "message": "Data Incorrect. Please check and try again",
            "show": true
        });
    }
    event.preventDefault();

    if (form.checkValidity() === true) {
      //.. Save DETAILS OF THE FORM
      setFinalOrgData(orgData);
      // to be replaced with updateAddress call to server
    }



  }
  
  //functions for handling change in organization completed
  
  //functions for GST data starting
  /** @function
 * @name handleGSTChange */
  const handleGSTChange = (e) => {
	  setGstData({ ...gstData, [e.target.name]: e.target.value });
	  gstData1={ ...gstData, [e.target.name]: e.target.value };
  }
  /** @function
 * @name gstformChange */
  const gstformChange = (event) => {
	   const form = event.currentTarget;
    if (form.checkValidity() === false)
      setGstCheck(false);
    else if (form.checkValidity() == true)
	{
		      setFinalGstData(gstData1);
      setGstCheck(true);
	}
  }
   /** @function
 * @name saveGstData */
  const saveGstData = (event) => {
    console.log("saving Gst details");
	setGstValidated(true);
    //document.getElementById("addtype").options[1].selected = true;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      props.setMsgModal({
            "title": "Profile Page Validation Error",
            "message": "Data Incorrect. Please check and try again",
            "show": true
        });
    }
    event.preventDefault();

    if (form.checkValidity() === true) {
      //.. Save DETAILS OF THE FORM
      setFinalGstData(gstData1);
	  sendProfileData();
      // to be replaced with updateAddress call to server
    }



  }
  
  
  
  //functions for GST data end
  
  
  //functions for Pwd change start
  /** @function
 * @name pwdFieldChange */
  const pwdFieldChange = (e) => {
	  setPwdData({ ...pwdData, [e.target.name]: e.target.value });
  }
  /** @function
 * @name pwdFormChange */
  const pwdFormChange = (event) => {
	   const form = event.currentTarget;
    if (form.checkValidity() === false)
      setPwdCheck(false);
    else if (form.checkValidity() == true)
	{
		setPwdCheck(true);
	}
  }
  /** @function
 * @name PwdChange */
  const PwdChange = (event) => {
    console.log("saving Pwd changes");
    const form = event.currentTarget;
	setPwdValidated(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      props.setMsgModal({
            "title": "Profile Page Validation Error",
            "message": "Data Incorrect. Please check and try again",
            "show": true
        });
    }
    event.preventDefault();

    if (form.checkValidity() === true) {
      //.. Save DETAILS OF THE FORM
      if(pwdData.re_new_pwd==pwdData.new_pwd)
	  {
		  let obj={
			  "compId": props.compId,
			  "userId": props.id,
			"oldPassword":pwdData.old_pwd,
			"newPassword":pwdData.new_pwd
		  }

		  props.changePassword(obj);
	  }
	  else
		  props.setMsgModal({
            "title": "Profile Page Validation Error",
            "message": "Please check data. New password and Confirm new password are not same",
            "show": true
        });;
    }
  }
	
	//functions for Pwd change ends


  return (
    <React.Fragment>

         <Header />
       
		 <BreadcrumbsItem to='/Profile'>Profile</BreadcrumbsItem>
     
      <Col>
      
        <Container>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} className="pl-0-mob pr-0-mob">
                <div className="pt-4 pb-4">
                  <Nav variant="pills" className="flex-column custom-sidetab">
                    <Nav.Item className="border-bottom text-left pt-3 pb-3 tab-head-mob">
                      <Nav.Link eventKey="fourth">Order History</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="border-bottom text-left pt-3 pb-3 tab-head-mob">
                      <Nav.Link eventKey="first">User Info</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="border-bottom text-left pt-3 pb-3">
                      <Nav.Link eventKey="second">Organization Info</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className="border-bottom text-left pt-3 pb-3 tab-head-mob">
                      <Nav.Link eventKey="third">Tax Info</Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="border-bottom text-left pt-3 pb-3 tab-head-mob">
                      <Nav.Link eventKey="fifth">Change Password</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="border-bottom text-left pt-3 pb-3 tab-head-mob">
                      <Nav.Link eventKey="sixth">Log Out</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col sm={9} className="border-left border-none-mob pl-0-mob pr-0-mob">
                <div className="pt-4 pb-4 pl-5 pr-5 pl-0-mob pr-0-mob">
                  <Tab.Content>
                    <Tab.Pane eventKey="fourth">
                      <h3 className="text-center">Order History</h3>
                      <div>
				{	/*	<div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
					             <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
        <div>&nbsp;</div> */}
                    <AllOrders/>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="first">
                      <h3 className="text-center mb-5">User Information</h3>


                      {/*Starting the form for saving the userData for the Customer*/}

                      <Form noValidate validated={validated} onSubmit={saveProfile} onChange={formChange} className="mb-0">
                          <Row className="infoppage">
                            <Col>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>First Name <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control type="text" placeholder="Your First Name" onChange={handleOnChange} name="fname" value={fname} autoComplete="none" required />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Last Name <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control type="text" placeholder="Your Last Name" onChange={handleOnChange} name="lname" value={lname} autoComplete="none" required />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row className="infoppage">
                            <Col>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Phone <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control type="text" placeholder="Your Phone " name="phone" onChange={handleOnChange} value={phone} autoComplete="none" pattern='[0-9]{10}' required />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Phone 2 (Optional)</b></Form.Label>
                                <Form.Control type="text" placeholder="Your Phone 2 " onChange={handleOnChange} value={phone1} name="phone1" autoComplete="none" pattern='[0-9]{10}' />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Email <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control type="text" placeholder="Your Email" name="email" onChange={handleOnChange} value={email} autoComplete="none" required />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="infoppage">
                           
                            {/* <Col>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Email 2 (Optional)</b></Form.Label>
                                <Form.Control type="text" placeholder="Your Email 2" onChange={handleOnChange} value={email1} name="email1" autoComplete="none" />
                              </Form.Group>
                            </Col> */}
                          </Row>
                          {/*Address mini form begin*/}

                          <Row className="infoppage">
                            <Col sm={6} xs={12}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Address Line 1 <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control type="text" placeholder="Number & Street" onChange={handleOnChange} name="address1" value={address1} autoComplete="none" required />
                              </Form.Group>
                            </Col>
                            <Col sm={6} xs={12}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Address Line 2 (Optional)</b></Form.Label>
                                <Form.Control placeholder="Number & Street" onChange={handleOnChange}
                                  name="address2" value={address2} autoComplete="none" placeholder="Address Line 2" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="infoppage">
                           
                            <Col sm={3} xs={6}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Pin Code <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control name="pin" value={pin} onChange={handleOnChange} autoComplete="none" required placeholder="Pin Code" pattern='[0-9]{6}' />
                              </Form.Group>
                            </Col>
                            <Col sm={3} xs={6}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>District <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control name="dist" value={dist} onChange={handleOnChange} autoComplete="none" required placeholder="District" required />
                              </Form.Group>
                            </Col>
                            <Col sm={3} xs={6}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>State <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control name="state" value={state} onChange={handleOnChange} autoComplete="none" required placeholder="State" required />
                              </Form.Group>
                            </Col>
                            <Col sm={3} xs={6}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>City <i className="text-danger">*</i></b></Form.Label>
                                <Form.Control name="city" value={city} onChange={handleOnChange} autoComplete="none" required placeholder="City" required />
                              </Form.Group>
                            </Col>
                            
                          </Row>
                          <Row className="infoppage">
                            
                          </Row>

                          {/*Address mini form end*/}
                          <Row className="infoppage">
                            <Col sm={6} xs={12}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Purpose</b></Form.Label>
                                <Form.Control placeholder="Your Purpose"type="text" onChange={handleOnChange} value={purpose} name="purpose" className="signinselect">
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col sm={6} xs={12}>
                              <Form.Group>
                                <Form.Label className="fsize14 mb-1"><b>Reference/Broker Name.</b></Form.Label>
                                <Form.Control type="text"  readOnly = {true} placeholder="Your Reference" onChange={handleOnChange} value={reference} name="reference" autoComplete="none" required />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2" type="submit" value="Submit">&nbsp;&nbsp;SAVE&nbsp;&nbsp;</Button>{' '}
                            </Col>
                          </Row>
                      </Form>
					  
					  {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={(props.modal.message=='')?'Error while saving':props.modal.message} closeAction={onErrorLoginClose} />}

                      {/*Form ends for saving the user Data*/}

                      {/* Form starts for Organization */}

                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <h3 className="text-center mb-5">Organization Information</h3>
					  <Form noValidate validated={orgvalidated} onSubmit={saveOrgData} onChange={orgformChange} className="mb-0">
                      <Row className="infoppage">
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Organization Name <i className="text-danger">*</i></b></Form.Label>
                            <Form.Control type="text" placeholder="Organization Name " name="org" onChange={handleOrgChange} value={org} autoComplete="none" required />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Organization Type</b></Form.Label>
                            <Form.Control as="select" type="text" className="signinselect" name="org_type" onChange={handleOrgChange} value={org_type} autoComplete="none" required>
                              <option>Business</option>
                              <option>Individual</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="infoppage">
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Address Line 1 <i className="text-danger">*</i></b></Form.Label>
                            <Form.Control type="text" placeholder="Address Line 1 " onChange={handleOrgChange} name="org_address1" value={org_address1} autoComplete="none" required  />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Address Line 2</b></Form.Label>
                            <Form.Control type="text" placeholder="Address Line 2 " onChange={handleOrgChange} name="org_address2" value={org_address2} autoComplete="none"  />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="infoppage">
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>State<i className="text-danger">*</i></b></Form.Label>
                            <Form.Control type="text" className="signinselect" required placeholder="State " onChange={handleOrgChange} name="org_state" value={org_state} autoComplete="none" />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>District<i className="text-danger">*</i></b></Form.Label>
                            <Form.Control  className="signinselect" name="org_district" value={org_district} onChange={handleOrgChange} autoComplete="none" required placeholder="District"  />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>City<i className="text-danger">*</i></b></Form.Label>
                            <Form.Control type="text" className="signinselect" name="org_city" value={org_city} onChange={handleOrgChange} autoComplete="none" required placeholder="City" />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>PIN Code<i className="text-danger">*</i></b></Form.Label>
                            <Form.Control  className="signinselect" required name="org_pin" value={org_pin} onChange={handleOrgChange} autoComplete="none" pattern='[0-9]{6}' placeholder="Pin Code" />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="infoppage p-2 border m-0 mt-4">
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Contact Person Name</b></Form.Label>
                            <Form.Control type="text" name="org_name" value={org_name} onChange={handleOrgChange} autoComplete="none" required placeholder="Contact Person Name" />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Contact Person Email</b></Form.Label>
                            <Form.Control name="org_email" value={org_email} required onChange={handleOrgChange} autoComplete="none" placeholder="Contact Person Email" />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Contact Person Phone</b></Form.Label>
                            <Form.Control  name="org_phone" required value={org_phone} onChange={handleOrgChange} autoComplete="none" pattern='[0-9]{10}' placeholder="Contact Person Phone" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2 pl-1 pr-1" type="submit" value="Submit" disabled={!orgCheck}>&nbsp;&nbsp;SAVE&nbsp;&nbsp;</Button>{' '}
                        </Col>
                      </Row>
					  
					  </Form>
					  

                      {/* Form ends for Organization*/}


                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <h3 className="text-center mb-5">Tax Information</h3>
						
						{/* Form Starts for GST */}
						
						<Form noValidate validated={gstvalidated} onSubmit={saveGstData} onChange={gstformChange} className="mb-0">
						
                          <Form.Label className="fsize14 mb-1"><b>GST Info.</b></Form.Label>
                          <Form.Group className="p-3 border rounded">
                            <Form.Group controlId="formBasicgst">
                              <Form.Control type="text" placeholder="GST No." name="gst" value={gst} onChange={handleGSTChange} autoComplete="none" required />
                            </Form.Group>
                            <Row>
                              <Col className="bg-light">
                                <Form.Group>
                                  <Form.Control type="text" placeholder="State Code" name="state_code" value={state_code} onChange={handleGSTChange} autoComplete="none" required />
                                </Form.Group>
                              </Col>
                              <Col>
                                {/* <Form.Group>
                                  <Form.Control type="text" placeholder="City Code" />
                                </Form.Group> */}
                              </Col>
                            </Row>
                            {/* <Form.Label className="fsize14 mb-1"><b>Attach GST Document</b></Form.Label>
                            <Form.File className="fsize14" /> */}
                        <Col className="p-0">
                          <Form.Label className="fsize14 mb-1"><b>PAN Card</b></Form.Label>
                          <Form.Group className="p-3 border rounded">

                            <Form.Group controlId="formBasicpan">
                              <Form.Control type="text" placeholder="Organization PAN No." name="pan" value={pan} onChange={handleGSTChange} autoComplete="none" required />
                            </Form.Group>
                            {/* <Form.Label className="fsize14 mb-1"><b>Attach PAN Card</b></Form.Label>
                            <Form.File className="fsize14" /> */}
                          </Form.Group>
						  </Col>
						  </Form.Group>
						  
                        <Row className="mt-4">
                        <Col>
                          <Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2" type="submit" value="Submit">&nbsp;&nbsp;SAVE&nbsp;&nbsp;</Button>{' '}
                        </Col>
                      </Row>
					  </Form>

					  {/* Form for GST ends */}
					  
					  {/* Form for Change Password starts */}
					  
					  
                    </Tab.Pane>

                    <Tab.Pane eventKey="fifth">
					
					<Form noValidate validated={pwdvalidated} onSubmit={PwdChange} onChange={pwdFormChange} className="mb-0">

                      <h3 className="text-center mb-5">Change Password</h3>
                      <Row className="infoppage">
                        <Col sm={4} xs={6}>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Enter Old Password</b></Form.Label>
                            <Form.Control type="text" placeholder="Old Password" type="password" name="old_pwd" onChange={pwdFieldChange} required />
                          </Form.Group>
                        </Col>
                        <Col sm={4} xs={6}>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Enter New Password</b></Form.Label>
                            <Form.Control type="text" placeholder="New Password" type="password" name="new_pwd" onChange={pwdFieldChange} required />
                          </Form.Group>
                        </Col>
                        <Col sm={4} xs={6}>
                          <Form.Group>
                            <Form.Label className="fsize14 mb-1"><b>Retype New Password</b></Form.Label>
                            <Form.Control type="text" placeholder="Retype New Password" type="password" name="re_new_pwd" onChange={pwdFieldChange} required />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2" type="submit" value="Submit">&nbsp;&nbsp;SAVE&nbsp;&nbsp;</Button>{' '}
                        </Col>
                      </Row>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
					  </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <h3 className="text-center">Log Out</h3>
                      <div>
					  <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
					  <div>&nbsp;</div>
					  <div>&nbsp;</div>
					  <div>&nbsp;</div>
					<div>&nbsp;</div>
					<div>&nbsp;</div>
                      </div>                      
                    </Tab.Pane>
					
					{/* form for change password ends*/}
                  </Tab.Content>                  
                </div>                
              </Col>
            </Row>
          </Tab.Container>                
        </Container>
      </Col>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  phone: state.UserData.phone,
  phone1: state.UserData.phone1,
  pin: state.UserData.pin,
  address1: state.UserData.address1,
  address2: state.UserData.address2,
  email: state.UserData.email,
  email2: state.UserData.email2,
  city: state.UserData.city,
  cstate: state.UserData.cstate,
  dist: state.UserData.dist,
  fname: state.UserData.fname,
  lname: state.UserData.lname,
  reference: state.UserData.reference,
  purpose: state.UserData.purpose,
  org: state.UserData.org,
  org_name: state.UserData.org_name,
  org_type: state.UserData.org_type,
  org_phone: state.UserData.org_phone,
  org_email: state.UserData.org_email,
  org_address1: state.UserData.org_address1,
  org_address2: state.UserData.org_address2,
  org_district: state.UserData.org_district,
  org_city: state.UserData.org_city,
  org_state: state.UserData.org_state,
  org_pin: state.UserData.org_pin,
  state_code: state.UserData.state_code,
  gst: state.UserData.gst,
  pan: state.UserData.pan,
  profileSaveData: selectProfileSaveJSON(state),
  profile_response: state.UserData.profile_response,
  customerDetails: state.UserData.customerDetails,
  addressTypes: state.UserData.addressTypes,
  compId: state.HomeData.companyid,
  id: state.UserData.user.id,
  message: state.AuthData.message,
  modal: state.AuthData.modal,
  user: state.UserData.user
})

export default connect(mapStateToProps, {
  set_fname,
  set_lname,
  set_email,
  set_email1,
  set_address1,
  set_address2,
  set_city,
  set_dist,
  set_state, set_phone, set_phone1, set_pin, set_reference, set_purpose,
  set_org, set_org_name,
  set_org_phone, set_org_type,
  set_org_email,
  set_org_address1, set_org_address2,
  set_org_city, set_org_district,
  set_org_state, set_org_pin,set_state_code,set_gst,set_pan,profileSave,changePassword,MsgModal,setMsgModal
})(Profile);
