
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';


import { getItemsByVariant, selectProduct } from '../actions/ProductsAction';

import Temp from './tempProduct';
/**
 * Creates a new ProductDetails.
 * @class
 */
const ProductDetails = (props) => {
  const [productDetails, setDetails] = useState({});
  const params = useParams();

  const product = (props.productsList.filter(product => {
    return Number(product.id) === Number(params.slug)
  }))[0];


  useEffect(() => {
    if (product) {
      //.. is product already available in cart then filter to modify object 
      //.. else use the retrieved object
      let existing = props.cartList.filter(item => item.id === product.id)
      setDetails((existing.length > 0) ? existing[0] : product)
    }
  }, [product,props.cartList])
  
  return (
    <Temp productDetails={productDetails} />
  )
}

const mapStateToProps = state => ({
  productsList: state.ProductsData.productsList,
  /* product: state.ProductsData.selectedProduct, */
  cartList: state.CartData.cartList,
  variantList: state.ProductsData.variantList,
  companyid: state.HomeData.companyid
})

export default connect(mapStateToProps, { getItemsByVariant, selectProduct })(ProductDetails);