import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import ThemeSwitcher from 'react-css-vars';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

//import Header from '../components/header/Header';
import Footer from '../components/Footer';

import { getProductsList, getFilterList } from '../actions/ProductsAction';

/**
 * Creates a HomeContainer.
 * @class
 */
const HomeContainer = props => {

  const { companyid, theme, getProductsList, getFilterList } = props;
  //.. initial call for company and app setup

  const navigate = useNavigate();
  const location = useLocation();

  const [defaultTheme, setDefaultTheme] = useState({ themecolor: "#177913" });

  let [hideFooter4Checkout, setHideFooter4Checkout] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('tmp_auth') !== "true") {
      navigate('/login', { replace: true });
    }
  })

  useEffect(() => {
    if (companyid && companyid !== '') {
      getProductsList({ "compId": companyid });
      getFilterList({ "compId": companyid });
    }
  }, [companyid]);


  useEffect(() => {
    if (theme) if (theme.color) setDefaultTheme({ themecolor: theme.color })
  }, [theme]);

  useEffect(() => {
    setHideFooter4Checkout((location.pathname === "/checkout" && props.isMobileMenu));
  }, [location, props.isMobileMenu]);

  return (
    <React.Fragment>
      <BreadcrumbsItem to='/'>Home</BreadcrumbsItem>
      <ThemeSwitcher theme={defaultTheme} elementId="root">
        <Outlet />
        <div className="full-Pagesection">
          {/* {brands && <Brands />} */}
        </div>
        {!hideFooter4Checkout && <Footer />}
      </ThemeSwitcher>
    </React.Fragment>
  );

}

const mapStateToProps = state => ({
  companyid: state.HomeData.companyid,
  brands: state.HomeData.brands,
  theme: state.HomeData.theme,
  imagepath: state.HomeData.imagepath,
  productsList: state.ProductsData.productsList,
  authenticated: state.AuthData.authenticated,
  isMobileMenu: state.CategoryData.isMobileMenu
})

export default connect(mapStateToProps, { getProductsList, getFilterList })(HomeContainer);