import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import { Container, Row, Col, Button, Form, Nav, Navbar, InputGroup, FormControl, ListGroup, Breadcrumb, Table, Accordion, Card } from 'react-bootstrap';
import { useLocation, useNavigate, Link, NavLink } from 'react-router-dom';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';

import { to2Decimal, formatINR, hasProps } from '../utils/CommonUtil';
import GenericModal from '../components/checkout/OrderConfirmationModal';
import MsgModal from './MsgModal';
import PromoModal from '../components/checkout/PromoModal';
import OrderedItemTable from '../components/checkout/OrderedItemTable';
import AddressB2B from '../components/checkout/AddressB2B';
import { clearCart } from '../actions/CartAction';
import { removeDiscount, clearOrder, setModal } from '../actions/CheckoutAction';
import { selectPlaceOrderJSON, placeOrder } from '../actions/CheckoutAction';
import { setMsgModal } from '../actions/AuthAction';

/**
 * Creates a new CheckOut.
 * @class
 */


const CheckOut = props => {

    /** @function
    *   @name closeAction 
    */
    const closeAction = () => {
        props.clearCart();
        props.removeDiscount();
        props.clearOrder();
        props.setModal(false);
        navigate('/');
    }
    /** @function
    *   @name onErrorClose 
    */
    const onErrorClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }


    const navigate = useNavigate();
    const location = useLocation();
    const [enablePlaceOrder, setEnablePlaceOrder] = useState(false);

    useEffect(() => {
        console.log(props.placeOrderData);
        navigate(location.state && (location.state.process) ? "/checkout" : "/products", { state: { process: false }, replace: true });
        if (location.state == null || props.user.id == null || props.user.id == '')
            navigate('/', { replace: true });
        //to be only used for 101 hooks
        //eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    /** @function
 * @name callbackFunction */
    const callbackFunction = (childData) => {
        //setEnablePlaceOrder(childData);
        if (childData == true) {
            props.placeOrder(props.placeOrderData);
        } else {
            props.setMsgModal({
                "title": "Address Validation Error",
                "message": "Incorrect Address Data. Please check and try again",
                "show": true
            });
        }
    }
    /** @function
* @name evaluateVoucherMsg */
    const evaluateVoucherMsg = () => {
        //console.log("evaluateVoucherMsg called");
        return props.selected_voucher.valid
            ? <p className="mb-0 mt-1"><b>{props.selected_voucher.msg}</b></p>
            : <p className="mb-0 mt-1"><b>{props.selected_voucher.msg}</b></p>
    }

    return (
        <React.Fragment>

            <BreadcrumbsItem to='/checkout'>Checkout</BreadcrumbsItem>
            <Navbar bg="white" sticky="top" expand="lg border-bottom" className="navstylecontrol">
                <Navbar.Brand href="#home">
                    <img src={require("../images/logo.png")} alt="logo" />
                </Navbar.Brand>
            </Navbar>
            <div className="page-bodesection">
                <Col className="page-breadcrumb pl-5 pr-5 pt-3 pb-1 pl-pr-2em-mob border-bottom">
                    <Breadcrumbs
                        separator={<b> / </b>}
                        item={NavLink}
                        finalItem={'i'}
                        finalProps={{
                            style: { color: 'black' }
                        }}
                    />
                </Col>
            </div>

            <Col>

                <Container className="pl-0-mob pr-0-mob">
                    <Row className="mt-5 mob-mt-1em">
                        <Col sm={9} className="">


                            <OrderedItemTable />
                        </Col>
                        <Col sm={3} className="pr-0 pr-15-mob mob-martop-25px">

                            <div className="pl-3 pl-0-mob">
                                <h4 className="border-bottom bge4e4e4 m-0 prod-dlts-label pt-2 pb-2 pl-3 pr-3">Price Details</h4>
                                <div className="bgefefef pl-3 pr-3 pt-3 pb-3">
                                    <p className="mb-0 pb-1 text-dark fsize15">Bag Total<span className="float-right">{formatINR(to2Decimal(props.checkoutDetails.totalAmount))}</span></p>
                                    <p className="pb-3 mb-0 text-dark fsize15">Tax<span className="float-right">{formatINR(to2Decimal(props.checkoutDetails.totalTaxAmount))}</span></p>
                                    <p className="mb-0 pb-1 text-dark fsize15">Discount<span className="float-right">{formatINR(to2Decimal(props.checkoutDetails.totalDiscountAmount))}</span></p>
                                    {/* <p className="mb-0 pb-1 text-dark fsize15">Delivery Charge<span className="float-right">{formatINR(to2Decimal(props.checkoutDetails.deliveryChargeAmount))}</span></p> */}
                                </div>
                                <div className="pt-2 pb-2 border-top bgefefef">
                                    <p className="m-0  pl-3 pr-3 pb-1 text-dark"><b>TOTAL<span className="float-right">{formatINR(to2Decimal(props.checkoutDetails.grandTotalAmount))}</span></b></p>
                                </div>
                                <div className="text-center pr-3 pl-3 pb-3 bgefefef fsize14">
                                    <PromoModal disable={false} />
                                    {hasProps(props.selected_voucher, 'valid') && <div className="text-dark" role="alert">
                                        {evaluateVoucherMsg()}
                                    </div>}
                                </div>
                                <div>
                                    <div className="alert alert-info mt-3 pb-1 pt-1 fsize13 pl-2 pr-2 text-center"><i className="fa fa-info-circle fsize20 mr-2 position-relative topcont" aria-hidden="true"></i><b>Delivery charge will be communicated later</b></div>
                                </div>
                            </div>
                            <div className="pl-3 mt-4 pl-0-mob">
                                <AddressB2B parentCallback={callbackFunction} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
            {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorClose} />}
            <MsgModal show={props.confirmationModal} closeAction={closeAction} title={`Thank you`} message={props.order.orderStatus} />
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    cartItems: state.CartData.cartList,
    checkoutDetails: state.CheckoutData.checkoutDetails,
    selected_voucher: state.CheckoutData.selected_voucher,
    placeOrderData: selectPlaceOrderJSON(state),
    modal: state.AuthData.modal,
    confirmationModal: state.CheckoutData.confirmationModal,
    order: state.CheckoutData.order,
    user: state.UserData.user
})
export default connect(mapStateToProps, {
    placeOrder,
    clearCart,
    clearOrder,
    removeDiscount,
    setModal,
    setMsgModal
})(CheckOut);  
