import React, { useState, useCallback } from "react";
import { connect } from 'react-redux';
import Counter from "./Counter";
import ImageUtil from '../utils/ImageUtil';
import { to2Decimal, formatINR } from '../utils/CommonUtil';
import { selectProduct, getItemsByVariant, setVariantModal } from '../actions/ProductsAction';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container, Card, ListGroup } from 'react-bootstrap';

import { APIAlert } from '../utils/AlertUtil';
import { isProd } from '../utils/Env';

import ColorCode from '../components/ColorCode';
import Scroller from './Scroller';
import LoadingProduct from './LoadingProduct';
/**
 * Creates a new Product.
 * @class
 */
 
 
 
const Product = (props) => {
  const [loading, setLoading] = useState(true);
  const[colorrange,setColorrange]=useState([0,4]);
	
  const navigate = useNavigate();
  let { product } = props;

  let cartItems = props.cartList.filter(item => item.id === product.id);
  /** @function
* @name sizeHandler */
  const sizeHandler = () => {
    props.getItemsByVariant({ companyid: props.companyid, variant: product.variant });
    props.setVariantModal(true);
  }
  /** @function
* @name handleImgClick */
  const handleImgClick = () => {

    //.. get variant details
    props.getItemsByVariant({ companyid: props.companyid, variant: product.variant });
    props.selectProduct(product);
    navigate(`/products/${product.id}`)
  }

  /** @function
   *  @name addTick
   * fa fa-check  
   */
  const addTick = () => {
    return cartItems.length > 0 && cartItems[0].orders[0].total_quantity > 0
      ? <span className="pull-right"><i className={`fa fa-cart-plus`} style={{ color: "#800000" }} ></i></span> : null
  }


  const load = useCallback(() => {
    setLoading(false);
  }, []);
  
  
  const colorcode_list=product.allcolor.map((item, id) =>
              <ListGroup.Item className="pt-0 pb-0 pl-0 pr-2 border-0" key={id}>
                <ColorCode color={item.color} />
              </ListGroup.Item>
            )
			
const scroll_nav={type:"fa",left:"fa fa-angle-left",right:"fa fa-angle-right"};
const scroller_comp=<Scroller key={props.isMobileMenu} data={colorcode_list} range={(props.isMobileMenu)?5:8} nav={scroll_nav} />;
  return (
    <React.Fragment>

      <Card style={{ display: loading ? "block" : "none" }}>
          <LoadingProduct />
		  <Card.Body>
          <Card.Title className="mb-1">Loading</Card.Title>
		  <Card.Text className="mb-1">
            Loading product.name
          </Card.Text>
          <ListGroup horizontal className="transparent-bg">
              <ListGroup.Item className="pt-0 pb-0 pl-0 pr-2 border-0">
                <ColorCode color={'#999'} />
                <ColorCode color={'#999'} />
                <ColorCode color={'#999'} />
                <ColorCode color={'#999'} />
              </ListGroup.Item>
          </ListGroup>
          <p className="price-range mb-0">Loading product.range</p>
		  </Card.Body>
      </Card>

      <Card className="rounded-0 cur-pointer" style={{ display: loading ? "none" : "block" }}>
        <Card.Img variant="top" onClick={handleImgClick} src={(product.images && product.images.length>0) ? ImageUtil.getImage(product.images[0]) : ImageUtil.getErrorImage()} onLoad={load} />

        <Card.Body>

          <Card.Title className="mb-1">{product.itemCode}{addTick()}</Card.Title>

          <Card.Text className="mb-1">
            {(product.name.length>25)?(product.name.substring(0,22).concat("...")):(product.name)}
          </Card.Text>
		  <div>
		  <ListGroup horizontal className="transparent-bg">
		  {scroller_comp}
		   </ListGroup>
		  </div>
          <p className="price-range mb-0">{product.range_display}</p>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}

const mapStateToProps = state => ({
  productsList: state.ProductsData.productsList,
  cartList: state.CartData.cartList,
  companyid: state.HomeData.companyid,
  secondaryColour: state.HomeData.secondaryColour,
  isMobileMenu:state.CategoryData.isMobileMenu
  
});


export default connect(mapStateToProps, { selectProduct, getItemsByVariant, setVariantModal })(Product);


