import React from "react";
//import ImageUtil from '../../utils/ImageUtil';
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";
/**
 * Creates a new Category.
 * @class
 */
const Category = props => {
  /** @function
 * @name handleMouseOver */
  const handleMouseOver = e => {
    //console.log("handleMouseOver ");
  }
  /** @function
 * @name handleMouseOut */
  const handleMouseOut = e => {
    //console.log("handleMouseOut")
  }
  /** @function
 * @name getIcon */
 /*const getIcon = icon => {
    return (
      <div>
        <img className="icon-image hidden-xs hidden-sm" alt={icon} src={ImageUtil.getImage(icon)} />
        <br className="hidden-xs"/>
      </div>
    ) 
 }*/

  return (
    <Nav.Item key={props.keyValue} 
    onMouseEnter={handleMouseOver}
    onMouseLeave={handleMouseOut}>
      <Nav.Link eventKey={props.category.id} active={props.selectedCategory === props.category.id ? true : false}>
      {/* {props.category.categoryImageName && getIcon(props.category.categoryImageName)} */}
      {props.category.name}    
      </Nav.Link>
    </Nav.Item>
  );
};
const mapStateToProps = state => ({
  selectedCategory: state.CategoryData.selectedCategory,
})
export default connect(mapStateToProps)(Category);