import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import Header from '../components/header/Header';
import Product from '../components/Product';
import { Container, Row, Col, Form, InputGroup,Button } from 'react-bootstrap';
import {APIAlert} from '../utils/AlertUtil';
import SimpleSlider from '../components/BannerSlider';
import { getfeaturedItems } from '../actions/ProductsAction';
import FilterSection from '../components/FilterSection';
import Filtertags from '../components/FilterTags';
import MsgModal from './MsgModal';
import { setMsgModal } from '../actions/AuthAction';


/**
 * Creates a new FeaturePanel.
 * @class
 */
const FeaturePanel = props => {
	const [pricemin,setpriceMin]=useState(0);
	const [pricemax,setpriceMax]=useState(0);
	const [brand,setBrand]=useState([]);
	const [color,setColor]=useState([]);
	const [size,setSize]=useState([]);
	const [discount,setDiscount]=useState([]);
	
	const onErrorLoginClose = () => {
        props.setMsgModal({
            "title": "",
            "message": {},
            "show": false
        });
    }
	
	useEffect(() => {
        setpriceMin(props.pricemin);
		setpriceMax(props.pricemax);
		if(typeof props.filter_data != "undefined" && typeof props.filter_data.brands != "undefined" && typeof props.filter_data.colors!="undefined" && typeof props.filter_data.sizes != "undefined"  && typeof props.filter_data.discounts !="undefined")
		{
			setBrand(props.filter_data.brands.filter((item)=> item.checked==true));
			setColor(props.filter_data.colors.filter((item)=> item.checked==true));
			setSize(props.filter_data.sizes.filter((item)=> item.checked==true));
			setDiscount(props.filter_data.discounts.filter((item)=> item.checked==true));
		}
		if(!props.isMobileMenu)
			onErrorLoginClose();
    }, [props.pricemin,props.pricemax,props.filter_data,props.isMobileMenu]);
	
	const filter_function = (ob) => 
	{
		let final_dec=false;
		if(typeof ob!="undefined" && (brand.length>0 || color.length>0 || (pricemin>0 && pricemax>0) || size.length>0) )
		{
			let min=parseInt(ob.range_display.split(' - ')[0]);
			let max=parseInt(ob.range_display.split(' - ')[1]);
			if ((typeof pricemin =="undefined" || typeof pricemax=="undefined") && (typeof brand == "undefined" && brand.length==0) && (typeof color=="undefined" && color.length==0))
				final_dec=false;
			else 
			{
				if((min>=pricemin && max<=pricemax) || (pricemin==0 && pricemax==0))
				{
					final_dec=true;
					if(brand.length>0)
					{
					if(typeof ob.brandName != "undefined")
					{
					var i;
					for( i=0;i<brand.length;i++)
					{
						if(ob.brandName==brand[i].name)
						{
							final_dec=true;
							break;
						}
					}
					
					if(i==brand.length)
						final_dec=false;
					}
					else
						final_dec=false;
					}
					if(color.length>0 && final_dec==true)
					{
						if(typeof ob.allcolor != "undefined")
						{
						var j;
							for( j=0;j<color.length;j++)
							{
								let temp=ob.allcolor.filter((item)=> item.name==color[j].name);
								if(temp.length>0)
								{
									final_dec=true;
									break;
								}
							}
							if(j==color.length)
								final_dec=false;
						}
						else
						final_dec=false;
					}
					if(size.length>0 && final_dec==true)
					{
						if(typeof ob.size_panel != "undefined" && typeof ob.size_panel.sizes != "undefined")
						{
						var j;
							for( j=0;j<size.length;j++)
							{
								let temp=ob.size_panel.sizes.filter((item)=> item.name==size[j].name);
								if(temp.length>0)
								{
									final_dec=true;
									break;
								}
							}
							if(j==size.length)
								final_dec=false;
						}
						else
						final_dec=false;
					}
					if(discount.length>0 && final_dec==true)
					{
						console.log("in discounted");
						if(typeof ob.discounted != "undefined" )
						{
						var j;
							for( j=0;j<discount.length;j++)
							{
								if((discount[i].name=="yes" && ob.discounted) || ( discount[i].name=="no" && !ob.discounted))
								{
									final_dec=true;
									break;
								}
							}
							if(j==discount.length)
								final_dec=false;
						}
						else
						final_dec=true;
					}
				}
				else
					final_dec=false;
			}
		}
		else
			final_dec=true;
		return final_dec;
		
		
	}
	
	
	

    /** @function
 * @name renderRows */
    //.. BELOW IS THE WORKING CODE ALSO UNCOMMENT JSX
    const renderRows = (list2Render) => {

        let a = 0;
        let rowSet = [];

        if(list2Render){
            let col = Array.from(Array(4).keys());
            let row = Array.from(Array(Math.ceil(list2Render.length / col.length)).keys());

            for (let i = 0; i < row.length; i++) {
                let cards = []
                for (let j = 0; j < col.length; j++) {
					if(typeof list2Render[a]!="undefined")
					{
							cards.push(list2Render[a] 
                        ? <Col className="pt-2 pb-2 pl-2 pr-2 mob-left-right-padd-cont" xs={6} md={3} key={`col${list2Render[a].id}`}><Product product={list2Render[a]} /></Col> 
                        : <Col className="pt-2 pb-2 pl-2 pr-2" key={`empty${j}`}></Col>
                    )
						
							
					}
                    a++
                }
                rowSet.push(<Row key={`row${i}`}>{cards}</Row>)

            }
        }
        return rowSet
    } 

    /* TODO:: Hardcoded design to be removed */

    return (

        <React.Fragment>
		
         <Header />
       
            <div className="page-bodesection">
                <SimpleSlider />

                {/* <div className="border-bottom">
                    <div className="offset-md-9">
                        <Row className="pr-5 pl-5 pt-4 pb-4 mr-0 ml-0">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text className="bg-white fsize13">
                                        Sort By :
      </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as="select" className="border-left-0 fsize13 pl-0">
                                    <option>Recommended</option>
                                    <option>Recommended</option>
                                    <option>Recommended</option>
                                </Form.Control>
                            </InputGroup>
                        </Row>
                    </div>
                </div> */}

            </div>
            <div className="page-bodesection">
                <Col className="pl-5 pr-5 pl-30-pr-30-mob">
                    <Row className="all-cart-section">

                        <div className="w-100">
                            <Row>
                                { /* THIS IS LEFT PANEL */}
                                { /* THIS IS LEFT PANEL */}
									{(props.isMobileMenu)?(<Button variant="link" onClick={()=>{props.setMsgModal({
								"title": "Filters",
								"message": <FilterSection /> ,
								"show": true
									})}}>Filters</Button>): (<FilterSection />)}

                                { /* CARD DISPLAY AREA */}
                                <Col md={10} className="pt-2">
								<Filtertags />
                                    {props.productsList ? renderRows(props.productsList.filter((item)=>filter_function(item))) : APIAlert("No data received").error()}
                                </Col>
                            </Row>

                        </div>
                    </Row>
                </Col>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    companyid: state.HomeData.companyid,
    productsList: state.ProductsData.productsList,
    featuredItems: state.ProductsData.featuredItems,
	pricemin: state.ProductsData.pricemin,
	pricemax:state.ProductsData.pricemax,
	filter_data:state.ProductsData.filter_data,
	isMobileMenu:state.CategoryData.isMobileMenu
})

export default connect(mapStateToProps, { getfeaturedItems,setMsgModal })(FeaturePanel);
