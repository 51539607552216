// Description on const CATEGORYLIST
/** @constant
    @type {string}
    @default
*/
const ADDTOCART = "ADDTOCART";
/** @constant
    @type {string}
    @default
*/
const SETCARTBOUNCE = "SETCARTBOUNCE";
/** @constant
    @type {string}
    @default
*/
const ADDQUANTITY = "ADDQUANTITY";
/** @constant
    @type {string}
    @default
*/
const SUBTRACTQUANTITY = "SUBTRACTQUANTITY";
/** @constant
    @type {string}
    @default
*/
const REMOVE_FROM_CART = "REMOVE_FROM_CART";
/** @constant
    @type {string}
    @default
*/
const CLEAR_CART = "CLEAR_CART";
/** @constant
    @type {string}
    @default
*/
const PREVIEW_CART = "PREVIEW_CART";
/** @constant
    @type {string}
    @default
*/
const CHECKEDOUT_CART = "CHECKEDOUT_CART";
//.....................................

//...................................................................
/**
 * Action addToCart defined. 
 * @constructor
 * @param {object} param - Pass object as payload to add product in cart.
 */
export const addToCart = param => ({
    type: ADDTOCART,
    payload: param
});

//...................................................................
/** 
 * Action addQuantity defined.
 * @constructor
 * @param {object} param - Pass object as payload to add quentity.
 */
export const addQuantity = param => ({
    type: ADDQUANTITY,
    payload: param
});

//...................................................................
/**
 * Action subtractQuantity defined.
 * @constructor
 * @param {object} param - Pass object as payload to substract quentity.
 */
export const subtractQuantity = param => ({
    type: SUBTRACTQUANTITY,
    payload: param
});

//...................................................................
/**
 * Action removeItem defined.
 * @constructor
 * @param {object} param - Pass object as payload to remove item.
 */
export const removeItem = param => ({
    type: REMOVE_FROM_CART,
    payload: param
});

//...................................................................
/**
 * Action clearCart defined.
 * @constructor
 */
export const clearCart = () => ({
    type: CLEAR_CART,
    payload: null
}); 

//...................................................................
/**
 * Action setCartBounce defined.
 * @constructor 
 * @param {boolean} param - .
 */
export const setCartBounce = param => ({
    type: SETCARTBOUNCE,
    payload: param
});


//...................................................................
/**
 * Action previewCart defined .
 * @constructor
 * @param {boolean} param - Pass boolean as payload to set preview value.
 */
export const previewCart = param => ({
        type: PREVIEW_CART,
        payload: param
}); 
//...................................................................
/**
 * Action setCheckOutCartState defined >> cart clicked for checkout .
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setCheckOutCartState = param => ({
    type: CHECKEDOUT_CART,
    payload: param
});
//...................................................................


//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    preview: false,
    totalItems: 0,
    totalAmount: 0,
    cartBounce: false,
    cartList: [],
    checkOutClicked: false
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case ADDTOCART: {
            const { cart } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, cartBounce: true }
        }
        case ADDQUANTITY: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount }
        }
        case SUBTRACTQUANTITY: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount}
        }
        case REMOVE_FROM_CART: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount}
        }
        case CLEAR_CART: {
            return {...state, cartList: [], totalItems: 0, totalAmount: 0} 
        }
        case PREVIEW_CART: {
            return {...state, preview: action.payload} 
        }
        case CHECKEDOUT_CART: {
            return {...state, checkOutClicked: action.payload} 
        }
        default:
            return state;
    }
}

//...................................................................
//.. selectors 

//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const CartAction = {
    reducer: reducer,
    actions: {
        addToCart,
		clearCart,
        addQuantity,
        subtractQuantity,
        setCartBounce,
        removeItem
    }
}

export default CartAction