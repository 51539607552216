import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';
import {DispatchAction} from './index';
import {set_message,set_status} from './AuthAction';

/** @constant
    @type {string}
    @default
*/
const USER_FIRST_NAME = "USER_FIRST_NAME";
/** @constant
    @type {string}
    @default
*/
const USER_LAST_NAME = "USER_LAST_NAME";
/** @constant
    @type {string}
    @default
*/
const USER_FULL_NAME = "USER_FULL_NAME";
/** @constant
    @type {string}
    @default
*/
const USER_PHONE = "USER_PHONE";
/** @constant
    @type {string}
    @default
*/
const USER_PHONE1= "USER_PHONE1";
/** @constant
    @type {string}
    @default
*/
const USER_EMAIL = "USER_EMAIL";
/** @constant
    @type {string}
    @default
*/
const USER_EMAIL1= "USER_EMAIL1";
/** @constant
    @type {string}
    @default
*/
const USER_ADDRESS_1 = "USER_ADDRESS_1";
/** @constant
    @type {string}
    @default
*/
const USER_ADDRESS_2 = "USER_ADDRESS_2";
/** @constant
    @type {string}
    @default
*/
const USER_PINCODE = "USER_PINCODE";
/** @constant
    @type {string}
    @default
*/
const USER_CITY = "USER_CITY";
/** @constant
    @type {string}
    @default
*/
const USER_DISTRICT = "USER_DISTRICT";
/** @constant
    @type {string}
    @default
*/
const USER_STATE = "USER_STATE";
/** @constant
    @type {string}
    @default
*/
const USER_COUNTRY = "USER_COUNTRY";
/** @constant
    @type {string}
    @default
*/
const ADDRESS_TYPE = "ADDRESS_TYPE";
/** @constant
    @type {string}
    @default
*/
const ADDRESS_ID = "ADDRESS_ID";
/** @constant
    @type {string}
    @default
*/
const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";
/** @constant
    @type {string}
    @default
*/
const OTP_VALIDATION = 'OTP_VALIDATION';
/** @constant
    @type {string}
    @default
*/
const CLEAR_DETAILS = "CLEAR_DETAILS";
/** @constant
    @type {string}
    @default
*/
const OTP_SEND_STATUS = "OTP_SEND_STATUS";
/** @constant
    @type {string}
    @default
*/
const CLEAR_OTP_STATUS = "CLEAR_OTP_STATUS";
/** @constant
    @type {string}
    @default
*/
const USER_PROFILE = "USER_PROFILE";
/** @constant
    @type {string}
    @default
*/
const USER_REFERENCE = "USER_REFERENCE";
/** @constant
    @type {string}
    @default
*/
const USER_PURPOSE = "USER_PURPOSE";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_ADDRESS1= "USER_ORG_ADDRESS1";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_ADDRESS2= "USER_ORG_ADDRESS2";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_STATE= "USER_ORG_STATE";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_PIN="USER_ORG_PIN";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_CITY="USER_ORG_CITY";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_DISTRICT="USER_ORG_DISTRICT";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_PHONE="USER_ORG_PHONE";
/** @constant
    @type {string}
    @default
*/
const USER_ORG="USER_ORG";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_NAME="USER_ORG_NAME";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_EMAIL="USER_ORG_EMAIL";
/** @constant
    @type {string}
    @default
*/
const USER_ORG_TYPE="USER_ORG_TYPE";
/** @constant
    @type {string}
    @default
*/
const USER_DELIVERY_ADDRESS1= "USER_DELIVERY_ADDRESS1";
/** @constant
    @type {string}
    @default
*/
const USER_DELIVERY_ADDRESS2 = "USER_DELIVERY_ADDRESS2";
/** @constant
    @type {string}
    @default
*/
const USER_DELIVERY_PIN = "USER_DELIVERY_PIN";
/** @constant
    @type {string}
    @default
*/
const USER_DELIVERY_CITY = "USER_DELIVERY_CITY";
/** @constant
    @type {string}
    @default
*/
const USER_DELIVERY_DISTRICT = "USER_DELIVERY_DISTRICT";
/** @constant
    @type {string}
    @default
*/
const USER_DELIVERY_STATE = "USER_DELIVERY_STATE";
/** @constant
    @type {string}
    @default
*/
const USER_DELIVERY_PHONE ="USER_DELIVERY_PHONE";
/** @constant
    @type {string}
    @default
*/
const PROFILE_SAVE ="PROFILE_SAVE";
/** @constant
    @type {string}
    @default
*/
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
/** @constant
    @type {string}
    @default
*/
const STATE_CODE ="STATE_CODE";
/** @constant
    @type {string}
    @default
*/
const GST ="GST";
/** @constant
    @type {string}
    @default
*/
const PAN="PAN";
const DELIVERY_SPECIFIC_INSTRUCTION="DELIVERY_SPECIFIC_INSTRUCTION";
const USER_BREADCRUMB="USER_BREADCRUMB";
const RESET_PASSWORD="RESET_PASSWORD";
const VALIDATE_PIN="VALIDATE_PIN";

const ORDER_HISTORY = 'ORDER_HISTORY';
/** @constant
    @type {string}
    @default
*/
const HTML_VIEW_ORDER_INVOICE = 'HTML_VIEW_ORDER_INVOICE';
/**
 * Actions defination
 * @constructor
 * @param {*} param 
 */
export const set_fname = (param) => ({ type: USER_FIRST_NAME, payload: param })
export const set_lname = (param) => ({ type: USER_LAST_NAME, payload: param })
export const set_fullName = (param) => ({ type: USER_FULL_NAME, payload: param })
export const set_phone = (param) => ({ type: USER_PHONE, payload: param })
export const set_phone1 = (param) => ({ type: USER_PHONE1, payload: param })
export const set_email = (param) => ({ type: USER_EMAIL, payload: param })
export const set_email1 = (param) => ({ type: USER_EMAIL1, payload: param })
export const set_address1 = (param) => ({ type: USER_ADDRESS_1, payload: param })
export const set_address2 = (param) => ({ type: USER_ADDRESS_2, payload: param })
export const set_pin = (param) => ({ type: USER_PINCODE, payload: param })
export const set_city = (param) => ({ type: USER_CITY, payload: param })
export const set_dist = (param) => ({ type: USER_DISTRICT, payload: param })
export const set_state = (param) => ({ type: USER_STATE, payload: param })
export const set_country = (param) => ({ type: USER_COUNTRY, payload: param })
export const set_addressType = (param) => ({ type: ADDRESS_TYPE, payload: param })
export const set_addressId = (param) => ({ type: ADDRESS_ID, payload: param})
export const set_reference = (param) => ({ type: USER_REFERENCE, payload: param})
export const clear_userDetails = () => ({ type: CLEAR_DETAILS })
export const set_purpose = (param) => ({ type: USER_PURPOSE, payload: param })
export const set_delivery_address1 = (param) => ({ type: USER_DELIVERY_ADDRESS1, payload: param })
export const set_delivery_address2 = (param) => ({ type: USER_DELIVERY_ADDRESS2, payload: param })
export const set_delivery_pin = (param) => ({ type: USER_DELIVERY_PIN, payload: param })
export const set_delivery_city = (param) => ({ type: USER_DELIVERY_CITY, payload: param })
export const set_delivery_district = (param) => ({ type: USER_DELIVERY_DISTRICT, payload: param })
export const set_delivery_state = (param) => ({ type: USER_DELIVERY_STATE, payload: param })
export const set_delivery_phone = (param) => ({ type: USER_DELIVERY_PHONE, payload: param })
export const set_org = (param) => ({ type: USER_ORG, payload: param })
export const set_org_name = (param) => ({ type: USER_ORG_NAME, payload: param })
export const set_org_type = (param) => ({ type: USER_ORG_TYPE, payload: param })
export const set_org_phone = (param) => ({ type: USER_ORG_PHONE, payload: param })
export const set_org_email = (param) => ({ type: USER_ORG_EMAIL, payload: param })
export const set_org_address1 = (param) => ({ type: USER_ORG_ADDRESS1, payload: param })
export const set_org_address2 = (param) => ({ type: USER_ORG_ADDRESS2, payload: param })
export const set_org_city = (param) => ({ type: USER_ORG_CITY, payload: param })
export const set_org_district = (param) => ({ type: USER_ORG_DISTRICT, payload: param })
export const set_org_state = (param) => ({ type: USER_ORG_STATE, payload: param })
export const set_org_pin = (param) => ({ type: USER_ORG_PIN, payload: param })
export const set_state_code = (param) => ({ type: STATE_CODE, payload: param })
export const set_gst = (param) => ({ type: GST, payload: param })
export const set_pan = (param) => ({ type: PAN, payload: param })
export const userProfile = (param) => ({ type: USER_PROFILE, payload: param.data.user })
export const set_delivery_spec_ins = (param) => ({ type: DELIVERY_SPECIFIC_INSTRUCTION, payload: param })
export const set_breadcrumb = (param) => ({ type: USER_BREADCRUMB, payload: param })

/**
 * Action getCustomerDetails defined.
 * @constructor
 * @param {object} obj - Pass object as payload to get customer details.
 */ 
export const getCustomerDetails = (obj) => {
    return apiAction({
        url: API.GET_CUSTOMER_DETAILS+`CompId=${obj.companyid}&Phone=${obj.phone}`, 
        onSuccess: setCustomerDetails,
        onFailure: () => console.log("Error occured in getCustomerDetails"),
        callback: 'getCustomerDetails',
        label: CUSTOMER_DETAILS
      });
}
/**
 * Action setCustomerDetails defined.
 * @constructor
 * @param {object} param - Pass object as payload to set customer details.
 */ 
export const setCustomerDetails = param => {
    //console.log("setCustomerDetails ", param);
    return {
        type: CUSTOMER_DETAILS,
        payload: param
    }
  };

/**
 * Action validateOtp defined.
 * @constructor
 * @param {object} obj - Pass object as payload to validate the otp.
 */
export const validateOtp = (obj) => {
    return apiAction({
        url: API.VALIDATE_OTP,
        method: "post",
        data: obj,
        onSuccess: setValidateOtp,
        onFailure: () => console.log("Error occured in validateOtp"),
        callback: 'validateOtp',
        label: OTP_VALIDATION
    });
}
/**
 * Action profileSave defined.
 * @constructor
 * @param {object} obj - Pass object as payload to save profile.
 */
export const profileSave = (obj) => {
    return apiAction({
        url: API.PROFILE_SAVE,
        method: "post",
        data: obj,
        onSuccess: profileSaved,
        onFailure: () => alert("Error occured in profileSave"),
		callback: 'profile',
        label: PROFILE_SAVE
    });
}
/**
 * Action changePassword defined.
 * @constructor
 * @param {object} param - Pass object as payload to change password.
 */
export const profileSaved = param => {
	DispatchAction(set_message(param.message));
	DispatchAction(set_status(param.status));
	return{ type: PROFILE_SAVE, payload: param }
};

/**
 * Action changePassword defined.
 * @constructor
 * @param {object} param - Pass object as payload to change password.
 */
export const changePassword = (obj) => {
    return apiAction({
        url: API.CHANGE_PASSWORD,
        method: "post",
        data: obj,
        onSuccess: passwordChanged,
        onFailure: () => alert("Error occured while changing password"),
		callback: 'password',
        label: CHANGE_PASSWORD
    });
}
/**
 * Action passwordChanged defined.
 * @constructor
 * @param {object} state - Pass object as payload to change password.
 */
export const passwordChanged = param=>{
	DispatchAction(set_message(param.message));
    DispatchAction(set_status(param.status));
    return{ type: CHANGE_PASSWORD, payload: param }
};

export const forgotPassword = (obj) => {
    return apiAction({
        url: API.RESET_PASSWORD,
        method: "post",
        data: obj,
        onSuccess: reset_password,
        onFailure: () => alert("Error occured while changing password"),
		callback: 'resetpassword',
        label: RESET_PASSWORD
    });
}
/**
 * Action passwordChanged defined.
 * @constructor
 * @param {object} state - Pass object as payload to change password.
 */
export const reset_password = param=>{
	DispatchAction(set_message(param.message));
	DispatchAction(set_status(param.status));
};

export const deliveryvalidatepin = (obj) => {
    return apiAction({
        url: API.VALIDATE_PIN,
        method: "post",
        data: obj,
        onSuccess: pin_validated,
        onFailure: () => alert("Error occured while validating pin"),
		callback: 'deliveryvalidatepin',
        label: VALIDATE_PIN
    });
}

/**
 * Action passwordChanged defined.
 * @constructor
 * @param {object} state - Pass object as payload to change password.
 */
export const pin_validated = param=>{
	return{ type: VALIDATE_PIN, payload: param }
};

/**
 * Action selectProfileSaveJSON defined.
 * @constructor
 * @param {object} state - Pass object as payload to profile data in JSON format.
 */
export function selectProfileSaveJSON(state) {

    return {
        "compId": state.HomeData.companyid,
		
        
        "user": {
          "id": state.UserData.user.id,
          "name": state.UserData.user.name,
		  "fname": state.UserData.fname,
		  "lname": state.UserData.lname,
		  "phone": state.UserData.phone,
		  "phone2": state.UserData.phone2,
		  "email": state.UserData.email,
		  "address1": state.UserData.address1,
		  "address2": state.UserData.address2,
		  "pin": state.UserData.pin,
		  "city": state.UserData.city,
		  "dist": state.UserData.dist,
		  "state": state.UserData.cstate,
		  "reference": state.UserData.reference,
		  "purpose": state.UserData.purpose,
		  "gst": {
			  "stateCode": state.UserData.state_code,
			  "number": state.UserData.gst,
		  },
		  "pan": state.UserData.pan
        }
    }
}
/**
 * Action setValidateOtp defined.
 * @constructor
 * @param {object} param - Pass object as payload to set validate OTP.
 */
export const setValidateOtp = param => ({
    type: OTP_VALIDATION,
    payload: param.valid
});

/**
 * Action sendOtp defined.
 * @constructor
 * @param {object} obj - Pass object as payload to send otp.
 */ 
export const sendOtp = (obj) => {
    return apiAction({
        url: API.SEND_OTP, 
        method: "post",
        data: obj,
        onSuccess: setOtpSent,
        onFailure: () => console.log("Error occured in sendOtp"),
        callback: 'sendOtp',
        label: OTP_SEND_STATUS
      });
}
/**
 * Action sendOtp defined.
 * @constructor
 * @param {object} obj - Pass object as payload to fetch order history.
 */ 
export const fetchOrderHistory = (obj) => {
    return apiAction({
        url: API.GET_ORDER_HISTORY_LIST, 
        method: "post",
        data: obj,
        onSuccess: setOrderHistory,
        onFailure: () => console.log("Error occured in fetchOrderHistory"),
        label: ORDER_HISTORY
      });
}
/**
 * Action GetHtmlViewOrderInvoice defined.
 * @constructor
 * @param {object} obj - Pass object as payload to get specific Order or Invoice as a html view
 */ 
  
export const getHtmlViewOrderInvoice = (obj) => {
    return apiAction({
        url: API.HTML_VIEW_ORDER_INVOICE, 
        method: "post", 
        data: obj,
        onSuccess: getHtmlOrderInvoice,
        onFailure: () => console.log("Error occured in HtmlViewOrderInvoice"),
        label: HTML_VIEW_ORDER_INVOICE
      });
}
/**
 * Action getHtmlOrderInvoice defined.
 * @constructor
 * @param {object} param - Pass object as payload to get specific Order or Invoice as a html view
 */ 
export const getHtmlOrderInvoice = param => ({
    type: HTML_VIEW_ORDER_INVOICE,
    payload: param.data
  });
/**
 * Action setOrderHistory defined.
 * @constructor
 * @param {object} param - Pass object as payload to fetch order history.
 */ 
export const setOrderHistory = param => ({
    type: ORDER_HISTORY,
    payload: param.data
  });
/**
 * Action setOtpSent defined.
 * @constructor
 * @param {object} param - Pass object as payload to send otp.
 */ 
export const setOtpSent = param => ({
    type: OTP_SEND_STATUS,
    payload: param
  });

/**
 * Action clearOtpStatus defined.
 * @constructor
 * @param 
 */ 

export const clearOtpStatus = param => ({
    type: CLEAR_OTP_STATUS,
    payload: param
});




/**
 * set up with initial state.
 */
const initialState = {
    addrid: '',
    fname: '',
    lname: '',
    fullName: '',
    phone: '',
	phone1: '',
    email: '',
	email1: '',
    address1: '',
    address2: '',
    pin: '',
    city: '',
    dist: '',
    cstate: '',
    country: '',
    type: -1,
	reference: '',
    addressTypes: [],
    customerDetails: [],
	purpose : '',
	deliveryAddress1 : '',
	deliveryAddress2 : '',
	deliveryPin : '',
	deliveryCity : '',
	deliveryDistrict : '',
	deliveryState : '',
	deliveryPhone : '',
	org : '',
	org_name : '',
	org_type : '',
	org_phone : '',
	org_email : '',
	org_address1 : '',
	org_address2 : '',
	org_state : '',
	org_pin : '',
	org_district : '',
	org_city : '',
    valid_otp: false,
    valiDated_Otp: false,
	profile_response: {},
	state_code: '',
	gst: '',
	pan: '',
	delivery_spec_ins: '',
	breadcrumb: [],
	validatepin: {},
    otpSendStatus: {},
	user: {
		id: '',
		name: '',
    },
    userOrder:[],
    htmlData:null
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_FIRST_NAME: return {...state, fname: action.payload }
        case USER_LAST_NAME: return {...state, lname: action.payload }
        case USER_FULL_NAME: return {...state,fullName:action.payload}
        case USER_PHONE: return {...state, phone: action.payload, valiDated_Otp: false }
		case USER_PHONE1: return {...state, phone1: action.payload }
        case USER_EMAIL: return {...state, email: action.payload }
		case USER_EMAIL1: return {...state, email1: action.payload }
        case USER_ADDRESS_1: return {...state, address1: action.payload }
        case USER_ADDRESS_2: return {...state, address2: action.payload }
        case USER_PINCODE: return {...state, pin: action.payload }
        case USER_CITY: return {...state, city: action.payload }
        case USER_DISTRICT: return {...state, dist: action.payload }
        case USER_STATE: return {...state, cstate: action.payload }
		case USER_PURPOSE: return {...state, purpose: action.payload }
        case ADDRESS_ID: return {...state, addrid: action.payload }
        case USER_COUNTRY: return {...state, country: action.payload }
        case ADDRESS_TYPE : return {...state, type: action.payload }
        case CUSTOMER_DETAILS: return {...state, customerDetails: action.payload.data, addressTypes: action.payload.type}
        case OTP_VALIDATION: return {...state, valid_otp: action.payload, valiDated_Otp: action.payload}
        case OTP_SEND_STATUS: return {...state, otpSendStatus: action.payload}
        case CLEAR_OTP_STATUS: return {...state, otpSendStatus: {}}
        case CLEAR_DETAILS: return initialState
        case USER_PROFILE: return {...state, user: action.payload}
		case USER_REFERENCE: return {...state, reference: action.payload}
		case USER_DELIVERY_ADDRESS1: return {...state, deliveryAddress1: action.payload}
		case USER_DELIVERY_ADDRESS2: return {...state, deliveryAddress2: action.payload}
		case USER_DELIVERY_PIN: return {...state, deliveryPin: action.payload}
		case USER_DELIVERY_CITY: return {...state, deliveryCity: action.payload}
		case USER_DELIVERY_DISTRICT: return {...state, deliveryDistrict: action.payload}
		case USER_DELIVERY_STATE: return {...state, deliveryState: action.payload}
		case USER_DELIVERY_PHONE: return {...state, deliveryPhone: action.payload}
		case USER_ORG: return {...state, org: action.payload}
		case USER_ORG_NAME: return {...state, org_name: action.payload}
		case USER_ORG_TYPE: return {...state, org_type: action.payload}
		case USER_ORG_PHONE: return {...state, org_phone: action.payload}
		case USER_ORG_EMAIL: return {...state, org_email: action.payload}
		case USER_ORG_ADDRESS1: return {...state, org_address1: action.payload}
		case USER_ORG_ADDRESS2: return {...state, org_address2: action.payload}
		case USER_ORG_CITY: return {...state, org_city: action.payload}
		case USER_ORG_DISTRICT: return {...state, org_district: action.payload}
		case USER_ORG_PIN: return {...state, org_pin: action.payload}
		case USER_ORG_STATE: return {...state, org_state: action.payload}
		case PROFILE_SAVE: return { ...state, profile_response: action.payload}
		case STATE_CODE: return {...state, state_code: action.payload}
		case GST: return {...state, gst: action.payload}
		case PAN: return {...state, pan: action.payload}
		case DELIVERY_SPECIFIC_INSTRUCTION: return {...state, delivery_spec_ins: action.payload}
		case USER_BREADCRUMB: {
            return {...state, breadcrumb: action.payload }
        }
		case VALIDATE_PIN: return {...state, validatepin: action.payload}
        case ORDER_HISTORY: return {...state,userOrder: action.payload}
        case HTML_VIEW_ORDER_INVOICE: return {...state,htmlData: action.payload}

        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const UserAction = {
    reducer: reducer,
    actions: {
        sendOtp,
        getCustomerDetails,
        clear_userDetails,
        validateOtp,
        fetchOrderHistory,
        getHtmlViewOrderInvoice
    } 
}

export function selectSignUpUserJSON(state) {
    return {
        "name": state.UserData.name,
        "phone": state.UserData.phone,
        "email": state.UserData.email,
        "address": state.UserData.address,
        "address2": state.UserData.address2,
        "city": state.UserData.city,
        "dist": state.UserData.dist,
        "state": state.UserData.cstate,
        "pin": state.UserData.pin,
        "purpose": state.UserData.purpose,
        "referenceName": state.UserData.referenceName
    }
}

export default UserAction