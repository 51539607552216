import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import {setfilterData,setpriceMin,setpriceMax} from  '../actions/ProductsAction';



const Filtertags = (props) => {
	
	const [brand,setBrand]=useState([]);
	const [color,setColor]=useState([]);
	const [size,setSize]=useState([]);
	const [discount,setDiscount]=useState([]);
	const [price,setPrice]=useState([]);
	
	useEffect(() => {
		if(typeof props.filter_data != "undefined" && typeof props.filter_data.brands != "undefined" && typeof props.filter_data.colors!="undefined" && typeof props.filter_data.sizes != "undefined" && typeof props.filter_data.discounts != "undefined")
		{
			setBrand(props.filter_data.brands.filter((item)=> item.checked===true));
			setColor(props.filter_data.colors.filter((item)=> item.checked===true));
			setSize(props.filter_data.sizes.filter((item)=> item.checked===true));
			setDiscount(props.filter_data.discounts.filter((item)=> item.checked===true));
			setPrice(props.filter_data.prices.filter((item)=> item.checked===true));
		}
    }, [props.filter_data]);
	
	useEffect(() => {
		if(typeof props.filter_data.prices == "undefined" && typeof props.filters !="undefined" && typeof props.filters.prices !="undefined" && typeof props.filters.prices.price != "array" && props.filters.prices.price.type==="slider")
        {
			props.setpriceMin(props.filters.prices.price.min);
			props.setpriceMax(props.filters.prices.price.max);
		}
		else if(typeof props.filter_data.prices == "undefined")
		{
			props.setpriceMin(0);
			props.setpriceMax(0);
		}
		let brand=[];
		let color=[];
		let size=[];
		let discount=[];
		let price=[];
		var i;
		if(typeof props.filter_data.brands == "undefined" && typeof props.filters !="undefined" && typeof props.filters.brands != "undefined")
		for(i=0;i<props.filters.brands.brand.length;i++)
		{
			let temp={"name":props.filters.brands.brand[i].name,"checked":false}
			brand.push(temp);
		}
		if(typeof props.filter_data.colors == "undefined" && typeof props.filters !="undefined" && typeof props.filters.colors != "undefined")
		for( i=0;i<props.filters.colors.color.length;i++)
		{
			let temp={"name":props.filters.colors.color[i].name,"checked":false}
			color.push(temp);
		}
		if(typeof props.filter_data.sizes == "undefined" && typeof props.filters !="undefined" && typeof props.filters.sizes != "undefined")
		for( i=0;i<props.filters.sizes.size.length;i++)
		{
			let temp={"name":props.filters.sizes.size[i].name,"checked":false}
			size.push(temp);
		}
		if(typeof props.filter_data.discounts == "undefined" && typeof props.filters !="undefined" && typeof props.filters.discounts != "undefined")
		for( i=0;i<props.filters.discounts.discount.length;i++)
		{
			let temp={"name":props.filters.discounts.discount[i].name,"checked":false}
			discount.push(temp);
		}
		if(typeof props.filter_data.prices == "undefined" && typeof props.filters !="undefined" && typeof props.filters.prices != "undefined" && typeof props.filters.prices.price == "array" && props.filters.prices.price[0].type==="radio")
		for( i=0;i<props.filters.prices.price.length;i++)
		{
			let temp={"name":props.filters.prices.price[i].name,"checked":false}
			price.push(temp);
		}
		let final_temp={
			"brands":brand,
			"colors":color,
			"sizes":size,
			"discounts":discount,
			"prices":price
		}
		if(brand.length>0 || color.length>0 || size.length>0 || discount.length>0 || price.length>0)
		props.setfilterData(final_temp);
		
    }, [props.filters]);
	
	const displaytags = () =>
	{
		
		const clicked =(key,name) =>
		{
			let temp=JSON.parse(JSON.stringify(props.filter_data));
			for(var i=0;i<temp[key].length;i++)
				if(temp[key][i].name===name)
				{
					temp[key][i].checked=false;
					if(key==="prices")
					{
						props.setpriceMax(0);
						props.setpriceMin(0);
					}
					break;
				}
			props.setfilterData(temp);

		}			
		let content=[];
		content=[content,(<React.Fragment key="initial"><b>Tags</b><br/></React.Fragment>)];
		var i;
		for( i=0;i<brand.length;i++)
		{
			let name=brand[i].name
			content=[content, (<React.Fragment key="test5"><label className="filter_tag" onClick={()=>clicked("brands",name)}> {"Brand: ".concat(brand[i].name)}</label> <span>&nbsp;</span><span>&nbsp;</span></React.Fragment>)];
		}
		for( i=0;i<color.length;i++)
		{
			let name=color[i].name
			content=[content, (<React.Fragment key="test1"><label className="filter_tag" onClick={()=>clicked("colors",name)}> {"Color: ".concat(color[i].name)}</label> <span>&nbsp;</span><span>&nbsp;</span></React.Fragment>)];
		}
		for( i=0;i<size.length;i++)
		{
			let name=size[i].name
			content=[content, (<React.Fragment key="test2"><label className="filter_tag" onClick={()=>clicked("sizes",name)}> {"Size: ".concat(size[i].name)}</label> <span>&nbsp;</span><span>&nbsp;</span></React.Fragment>)];
		}
		for( i=0;i<discount.length;i++)
		{
			let name=discount[i].name
			content=[content, (<React.Fragment key="test3"><label className="filter_tag" onClick={()=>clicked("discounts",name)}> {"Discount: ".concat(discount[i].name)}</label> <span>&nbsp;</span><span>&nbsp;</span></React.Fragment>)];
		}
		for( i=0;i<price.length;i++)
		{
			let name=price[i].name
			content=[content, (<React.Fragment key="test4"><label className="filter_tag" onClick={()=>clicked("prices",name)}> {"Price: ".concat(price[i].name)}</label> <span>&nbsp;</span><span>&nbsp;</span></React.Fragment>)];
		}
		return (
        <React.Fragment key="final" >
		{(brand.length>0 || color.length>0 || size.length>0 || discount.length>0 || price.length>0)?content:null}
      </React.Fragment>
	  );
			
	}
	
	return (
        <React.Fragment key="final1">
		{displaytags()}
		</React.Fragment>
    );
}

const mapStateToProps = state => ({
	filter_data:state.ProductsData.filter_data,
	filters:state.ProductsData.filters
});


export default connect(mapStateToProps, { setfilterData,setpriceMin,setpriceMax })(Filtertags);
