import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {getCompanyInfo} from '../actions/HomeAction';
import { Global } from '../utils/Env';
import { config } from '../config';

const ConfigLoader = props => {
	
	useEffect(() => {
		props.getCompanyInfo();
    }, [])
	
	useEffect(() => {
		seturldetails();
    }, [props])
	
	const seturldetails = () => 
	{
		config.app.IMG_URL = props.imagebasepath;
		config.app.COMPANY_URL= props.url;
	}
	
	Global.callback.config_onComplete = () => {
        console.log("config_onComplete ");
		seturldetails();
    }
	
	return (
	<React.Fragment>
	
	</React.Fragment>
	);
}

const mapStateToProps = state => ({
	imagebasepath: state.HomeData.imagebasepath,
	url: state.HomeData.url
    
})

export default connect(mapStateToProps, {getCompanyInfo})(ConfigLoader);
