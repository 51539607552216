import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ImageUtil from '../../utils/ImageUtil';
import { Navbar, Nav, ListGroup } from 'react-bootstrap';
/**
 * Creates a new Logo.
 * @class
 */
const Logo = props => {
    const navigate = useNavigate();
    /** @function
 * @name onClickLogo */
    const onClickLogo = () => {
        navigate("/");
    }

    return (
    
        <div className="brand cur-pointer" onClick={onClickLogo}>
            {props.logo ? <img
                className="logo"
                src={ImageUtil.getImage(props.logo.src)}
                alt= {props.logo.alt}
            /> : null}
           
        </div>
       
    )
}

const mapStateToProps = state => ({
    logo:state.HomeData.logo
})

export default connect(mapStateToProps, {})(Logo);