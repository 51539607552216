import { config } from '../config';

export default class ImageUtil {

    static getErrorImage() {
        return config.app.IMG_URL + "not_available.jpg"
    }
    
    static getImage(img){
        //return config.app.IMG_URL + img
        let _img = (img) ? (config.app.IMG_URL + img) : ImageUtil.getErrorImage()
        return _img
    }
}


