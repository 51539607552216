import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Form, Navbar } from 'react-bootstrap';
import { login, signup, set_phoneNumber, set_password,  set_authenticated, setMsgModal } from '../actions/AuthAction';
import { getfeaturedItems } from '../actions/ProductsAction';
import { Global } from '../utils/Env';
import { config } from '../config';
import MsgModal from './MsgModal';
import Footer from '../components/Footer';
import { set_phone1, set_state, set_gst, set_state_code,set_delivery_pin,set_delivery_district,set_delivery_state,set_delivery_city,set_fname,set_lname,set_phone,set_email,set_address1,set_address2,set_pin,set_city,set_dist,set_pan,set_reference,set_purpose } from '../actions/UserAction'; 
/**
 * Creates a new Login.
 * @class
 */
const Login = props => {
    
    const [validNumber, setValidNumber] = useState(false);
    const navigate = useNavigate();
    var phone1='';
    /** @function
 * @name login_onComplete */
    Global.callback.login_onComplete = () => {
        console.log("login_onComplete ", props.valid, props.authenticated);
		setData();
        if(props.valid === "valid") {
            config.app.IMG_URL = props.imagebasepath;
            props.set_authenticated(true);
            localStorage.setItem('tmp_auth', true);
        } else {
            props.setMsgModal({
                "title": "Error in login",
                "message": props.message,
                "show": true
            });
        }
    }
     /** @function
 * @name login_onError */
    Global.callback.login_onError = () => {
        console.log("login_onError ", props.valid, props.authenticated);
        props.setMsgModal({
            "title": "Error in login",
            "message": props.message,
            "show": true
        });
    }
	
	
	const setData = () => {
		console.log("user",props.user);
	let deliveryPin=(props.user.pin)?props.user.pin:null;
	let deliveryState=(props.user.state)?props.user.state:null;
	let deliveryCity=(props.user.city)?props.user.city:null;
	let deliveryDistrict=(props.user.dist)?props.user.dist:null;
	let phone1=(props.user.phone2)?props.user.phone2:null;
	let cstate=(props.user.state)?props.user.state:null;
	let gst=(props.user.gst && props.user.gst.number)?props.user.gst.number:null;
	let state_code=(props.user.gst && props.user.gst.stateCode)?props.user.gst.stateCode:null;
	let fname=(props.user.fname)?props.user.fname:null;
	let lname=(props.user.lname)?props.user.lname:null;
	let phone=(props.user.phone)?props.user.phone:null;
	let email=(props.user.email)?props.user.email:null;
	let address1=(props.user.address1)?props.user.address1:null;
	let address2=(props.user.address2)?props.user.address2:null;
	let pin=(props.user.pin)?props.user.pin:null;
	let city=(props.user.city)?props.user.city:null;
	let dist=(props.user.dist)?props.user.dist:null;
	let pan=(props.user.pan)?props.user.pan:null;
	let reference=(props.user.reference)?props.user.reference:null;
	let purpose=(props.user.purpose)?props.user.purpose:null;
	props.set_phone1(phone1);
	props.set_state(cstate);
	props.set_gst(gst);
	props.set_delivery_city(deliveryCity);
	props.set_delivery_state(deliveryState);
	props.set_delivery_pin(deliveryPin);
	props.set_delivery_district(deliveryDistrict);
	props.set_state_code(state_code);
	props.set_fname(fname);
	props.set_lname(lname);
	props.set_phone(phone);
	props.set_email(email);
	props.set_address1(address1);
	props.set_address2(address2);
	props.set_pin(pin);
	props.set_city(city);
	props.set_dist(dist);
	props.set_pan(pan);
	props.set_reference(reference);
	props.set_purpose(purpose);
	}
	
	 
    /** @function
 * @name onErrorLoginClose */
    const onErrorLoginClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }
    /** @function
 * @name linkToSignUp */
    const linkToSignUp = () => {
        navigate("/register");
    }
    /** @function
 * @name handlePhonenumberChange */
    const handlePhonenumberChange = (e) => {
        if (e.target.value.length <= 10) {
			phone1=e.target.value;
            props.set_phoneNumber(e.target.value);  
        }
        setValidNumber(isValidNumber(e.target.value.substring(0,10).length));
    }
    /** @function
 * @name isValidNumber */
    const isValidNumber = (val) => {
        return (val === 10) ? true : false;
    }
    /** @function
 * @name handlePasswordChange */
    const handlePasswordChange = (e) => {
        props.set_password(e.target.value);
    }
    /** @function
 * @name handleClick */
    const handleClick = () => {
        props.login({"phone": props.phoneNumber, "password": props.password});
    }
    /** @function
 * @name onKeyDownHandler */
	const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };

    useEffect(() => {
        if(props.authenticated){
            navigate('/', {replace: true});
        }
		else{
            navigate('/login', {replace: true});
        }
    }, [navigate,props.authenticated])

    useEffect(() => {
        if(props.phoneNumber.length===10){
			if(phone1.length===0)
			setValidNumber(true);
		}
    }, [phone1,props.phoneNumber])

    
    
    return (
        <div className="page-bodesection">
            
            <Navbar bg="white" sticky="top" expand="lg border-bottom" className="navstylecontrol ">
                <Navbar.Brand>
                    <img src={require("../images/logo.png")} alt="logo" />
                </Navbar.Brand>
            </Navbar>
            <Container>
                <Row>
                    <Col sm={8} xs={12} className="hidden-xs">
                        <div className="position-relative">
                            <img src={require("../images/login-banner.png")} alt="Login Banner" className="w-75control w-100-mob" />
                        </div>
                        <div className="position-absolute log-sign-leftdiv text-center">
                            <div>
                                <h1 className="text-center log-welcome-text mb-0"><b>WELCOME</b></h1>
                                <h3><i>To</i></h3>
                                <label className="log-banner-logo mb-1"><img src={require("../images/logo.png")} alt="Login Banner" /></label>
                                <p className="mb-2"><b>Online Fashion Store</b></p>
                                <h4 className="b2btagline mb-2">B2B ORDERING PLATFORM</h4>
                                <div className="log-reg-contact mb-2">
                                <p className="mb-1 fsize13"><i className="fa fa-mobile fsize20 text-dark" aria-hidden="true"></i>&nbsp;&nbsp;+91 6289095905</p>
                                <p className="mb-0 fsize13 lh-17"><i className="fa fa-map-marker fsize20 text-dark" aria-hidden="true"></i>&nbsp;&nbsp;Naumi Fashions Private Ltd 20 , Park Lane , Police station Park Street, Kolkata - 700016</p>
                                </div>
                                <Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2" onClick={linkToSignUp} >NEW REGISTRATION</Button>{' '}
                            </div>
                        </div>
                    </Col>
                    <Col sm={4} xs={12} className="mob-martop-25px">
                        <div className="d-flex h-100 justify-content-center align-self-center flex-column">
                            <fieldset className="scheduler-border rounded">
                                <legend className="scheduler-border signin-label">Sign In</legend>
                                <Form onKeyDown={onKeyDownHandler}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="fsize14 mb-1"><b>Mobile No.</b></Form.Label>
                                        <Form.Control type="number" placeholder="Register Mobile No." value={props.phoneNumber} onChange={handlePhonenumberChange} />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label className="fsize14 mb-1"><b>Password</b></Form.Label>
                                        <Form.Control type="password" placeholder="Type Password" value={props.password} onChange={handlePasswordChange} />
                                    </Form.Group>
									
									<Form.Group controlId="formBasicCheckbox">
                                        <div>
                                            <Form.Check type="checkbox" label="Remember Me" className="d-inline font-weight-bold fsize13 text-muted">
                                            </Form.Check>
                                            <a href="/forgot" className="fsize13 float-right font-weight-bold text-muted text-decoration-underline">Forget Password ?</a>
                                        </div>
                                    </Form.Group>
                                    
                                    <Button variant="dark" id="submit_test" className="rounded-0 primarybutton pt-2 pb-2 mb-4" onClick={handleClick} disabled={!validNumber} >SIGN IN</Button>{' '}
                                    <div ><p className="fsize13 font-weight-bold text-muted">New User ? <a href="/register" className="fsize13 font-weight-bold text-danger text-decoration-underline"><span onClick={linkToSignUp}>Register Here</span></a></p></div>
                                </Form>
								
                            </fieldset>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
            {!props.authenticated && props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorLoginClose} />}
        </div> 
    );
}

const mapStateToProps = state => ({
    phoneNumber: state.AuthData.phoneNumber,
    password: state.AuthData.password,
    authenticated: state.AuthData.authenticated,
    valid: state.AuthData.valid,
    message: state.AuthData.message,
    imagebasepath: state.HomeData.imagebasepath,
	user: state.UserData.user,

    modal: state.AuthData.modal
})

export default connect(mapStateToProps, {setMsgModal, getfeaturedItems, login, signup, set_phoneNumber, set_password, set_authenticated,set_phone1, set_state, set_gst, set_state_code,set_delivery_pin,set_delivery_district,set_delivery_state,set_delivery_city,set_fname,set_lname,set_phone,set_email,set_address1,set_address2,set_pin,set_city,set_dist,set_pan,set_reference,set_purpose })(Login);
 
