import React from "react";
import { connect } from 'react-redux';
import ImageUtil from '../utils/ImageUtil';
import { Carousel } from 'react-bootstrap';
/**
 * Creates a new SimpleSlider.
 * @class
 */
const SimpleSlider = props => {
    /** @function
 * @name renderBanners */
  const renderBanners = () => {
    let bItems = props.banners.map((item, index) => {
      return (
        <Carousel.Item key={index}>
          <div className="branding-div branding-div-first pr-2">
            <img className="d-block w-100" alt={item.text} src={ImageUtil.getImage(item.image)} />
          </div>
        </Carousel.Item>
      )
    })
    return <Carousel>{bItems}</Carousel>
  }
  return <React.Fragment>{props.banners ? renderBanners() : null}</React.Fragment>
}

const mapStateToProps = state => ({
  banners: state.HomeData.banners
})

export default connect(mapStateToProps,{})(SimpleSlider);


/*
<Carousel>
    <Carousel.Item> 
      <img
        className="d-block w-100"
        src={require("../images/banner1.png")}
        alt="First slide"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={require("../images/banner2.png")}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={require("../images/banner1.png")}
        alt="Third slide"
      />
    </Carousel.Item>
  </Carousel>
*/