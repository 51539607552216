import React from 'react';
import {Modal} from 'react-bootstrap';

const OrderInvoiceModal = props => {
  
    return (
      <>
        <Modal
        size="lg"
          show={props.show}
          onHide={props.onHide}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {props.heading}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
         
            <div dangerouslySetInnerHTML={{__html: props.html}} />
          
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default OrderInvoiceModal;