
import React, {  useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { Row, Col, Container } from 'react-bootstrap';
import Logo from './Logo';
/* import Location from './Location';*/
import Cart from './Cart';
import Search from './Search';
import { Global } from '../../utils/Env';
import CategoryMenu from '../category/CategoryMenu';
import { toggleMenuState, setMobileSearch,setMenuState } from '../../actions/CategoryAction';
import { set_authenticated } from '../../actions/AuthAction';
//import { addStyle } from '../../utils/CommonUtil';
import { set_breadcrumb } from '../../actions/UserAction';

import { Navbar, Nav, ListGroup, InputGroup, FormControl } from 'react-bootstrap';
/**
 * Creates a new Header.
 * @class
 */
const Header = props => {
    
    const deepClone = require('clone-deep');

    const navigate = useNavigate();

    /** @function
 * @name logOut */
    const logOut = () => {
        localStorage.removeItem('tmp_auth');
        props.set_authenticated(false);
    }
    /** @function
* @name goProfile */
    const goProfile = () => {
        navigate("/profile");
    }

    const onSearchMobile = () => {
        props.setMobileSearch(true);
    }

    useEffect(() => {
        let breadcrumb = deepClone(props.breadcrumb);
        let a = "search";
        let linkObj = { "name": a, "link": a };
        props.set_breadcrumb([{ ...breadcrumb, linkObj, linkObj }]);
    }, []);
	

    return (
        <React.Fragment>
            <Navbar bg="white" sticky="top" expand="lg border-bottom" className="navstylecontrol" onToggle={() => props.setMenuState(!props.menuState)}  expanded={props.menuState}>
                {/* <Navbar.Brand><img src={require("../../images/logo.png")} alt="logo" onClick={goHome} className="cur-pointer" /></Navbar.Brand> */}
                <Navbar.Brand><Logo /></Navbar.Brand>
                <div className="hidden-desktop">

                    <i className="fa fa-search search-icon-mob" aria-hidden="true" onClick={onSearchMobile}></i>
                    <span className="position-relative hover-submenu border-right border-secondary pr-3"><img src={require("../../images/user.png")} alt="user" className="cur-pointer width20-marrightcont-mob" />

                        <label className="position-absolute mb-0 hovdropdowndiv">
                            <ListGroup>
                                <ListGroup.Item className="bg-000 text-white p-2 text-center"><a href="#" className="text-white text-decoration-none" onClick={goProfile}>Profile</a></ListGroup.Item>
                                <ListGroup.Item className="bg-000 text-white p-2 text-center"><a href="#" className="text-white text-decoration-none" onClick={goProfile}>Order</a></ListGroup.Item>
                                <ListGroup.Item className="bg-000 text-white p-2 text-center"><a href="#" className="text-white text-decoration-none" onClick={logOut}>Log Out</a></ListGroup.Item>
                            </ListGroup>
                        </label>
                    </span>
                    <span className="position-relative">
                        <Cart />
                    </span>
                    <span></span>
                </div>

                {props.mobileSearch && <div className="mob-search-pannel">
                    <Search />
                </div>}

                <Navbar.Toggle aria-controls="basic-navbar-nav" className="for-mob-control-hamberger"  />
                <Navbar.Collapse id="basic-navbar-nav">
    
                    <Nav className="mr-auto mob-control-search">
                        <CategoryMenu />
                        <Search />
                        <div className="d-flex position-absolute r-4opx top30px">

                            {props.user &&
                                <label className="fsize13 pr-2 mt-2 mb-0 hidden-xs">{props.user.name}</label>
                            }
                            <span className="position-relative hover-submenu border-right border-secondary pr-3 hidden-xs"><img src={require("../../images/user.png")} alt="user" className="cur-pointer" />

                                <label className="position-absolute mb-0 hovdropdowndiv">
                                    <ListGroup>
                                        {/* <ListGroup.Item className="bg-000 text-white p-2 text-center"><a href="#" className="text-white text-decoration-none">Account</a></ListGroup.Item> */}
                                        <ListGroup.Item className="bg-000 text-white p-2 text-center"><a href="#" className="text-white text-decoration-none" onClick={goProfile}>Profile</a></ListGroup.Item>
                                        <ListGroup.Item className="bg-000 text-white p-2 text-center"><a href="#" className="text-white text-decoration-none" onClick={goProfile}>Order</a></ListGroup.Item>
                                        <ListGroup.Item className="bg-000 text-white p-2 text-center"><a href="#" className="text-white text-decoration-none" onClick={logOut}>Log Out</a></ListGroup.Item>
                                    </ListGroup>
                                </label>
                            </span>
                            <span className="position-relative hidden-xs">
                                <Cart />
                            </span>
                        </div>

                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="page-bodesection">
                <Col className="page-breadcrumb pl-5 pr-5 pt-2 pb-1 pl-pr-2em-mob border-bottom">
                    <Breadcrumbs
                        separator={<i> / </i>}
                        item={NavLink}
                        finalItem={'i'}
                        finalProps={{
                            style: { color: 'black' }
                        }}
                    />
                </Col>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.UserData.user,
    breadcrumb: state.UserData.breadcrumb,
	menuState: state.CategoryData.menuState,
    mobileSearch: state.CategoryData.mobileSearch
})

export default connect(mapStateToProps, { setMobileSearch, toggleMenuState, set_authenticated, set_breadcrumb,setMenuState})(Header);