import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';

import {host} from '../utils/Env';
 /** @constant
    @type {string}
    @default
*/
const COMPANY_INFO = "COMPANY_INFO";
const COMPANY_ID = "COMPANY_ID";

/**
 * Action  defined.
 * @constructor
 */ 
export const getCompanyInfo = () => {
    return apiAction({
        url: window.location.origin.toString().concat(API.CONFIG), 
        onSuccess: setCompanyInfo,
        onFailure: () => console.log("Error occured in getCompanyInfo"),
		callback: 'config',
        label: COMPANY_INFO
      });
}
/**
 * setCompanyInfo Action defined.
 * @constructor
 * @param {object} param - Pass object as payload set company data
 */
export const setCompanyInfo = param => ({
    type: COMPANY_INFO,
    payload: param.data.company 
});

export const setCompanyId = param => ({
    type: COMPANY_ID,
    payload: param.data.company.companyid 
});


/**
 * set up with initial state.
 */
const initialState = {
    companyid: ''
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_INFO: return {...state, ...action.payload }
		case COMPANY_ID: return {...state, companyid:action.payload }
        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const HomeAction = {
    reducer: reducer,
    actions: {
        getCompanyInfo
    }
}

export default HomeAction