import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Form, Navbar } from 'react-bootstrap';

import { login, set_phoneNumber, setMsgModal } from '../actions/AuthAction';
import {forgotPassword} from '../actions/UserAction';
import { Global } from '../utils/Env';
import { config } from '../config';
import MsgModal from './MsgModal';
import Footer from '../components/Footer';
/**
 * Creates a new Login.
 * @class
 */
const Forgot = props => {
    
    const [validNumber, setValidNumber] = useState(false);
	const [password,set_password]=useState(null);
	const [re_password,set_re_password]=useState(null);
	const [email,set_email]=useState(null);
	const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    var phone1='';
    /** @function
 * @name onErrorLoginClose */
 Global.callback.resetpassword_onComplete = () => {
        console.log("Password_onComplete ");
        props.setMsgModal({
            "title": "Password Reset Done Successfully",
            "message": props.message,
            "show": true
        });
    }
    /** @function
 * @name password_onError */
    Global.callback.resetpassword_onError = () => {
		console.log("Password_onError ");
        props.setMsgModal({
            "title": "Error in Password Reset",
            "message": "Error in Reseting your password",
            "show": true
        });
    }
    const onErrorLoginClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }
    /** @function
 * @name linkToSignUp */
    const linkToSignUp = () => {
        navigate("/register");
    }
	const linkToLogIn = () => {
        navigate("/login");
    }
    /** @function
 * @name handlePhonenumberChange */
    const handlePhonenumberChange = (e) => {
        if (e.target.value.length <= 10) {
			phone1=e.target.value;
            props.set_phoneNumber(e.target.value);  
        }
        setValidNumber(isValidNumber(e.target.value.substring(0,10).length));
    }
    /** @function
 * @name isValidNumber */
    const isValidNumber = (val) => {
        return (val === 10) ? true : false;
    }
    /** @function
 * @name handlePasswordChange */
    const handlePasswordChange = (e) => {
        set_password(e.target.value);
    }
	 const handlerePasswordChange = (e) => {
        set_re_password(e.target.value);
    }
	const handleEmailChange = (e) => {
        set_email(e.target.value);
    }
	
	
    /** @function
 * @name handleClick */
    const handleClick = (event) => {
        const form = event.currentTarget;
		setValidated(true);
		if (form.checkValidity() === false) {
            event.stopPropagation();
			if(password!=re_password)
			{
				props.setMsgModal({
            "title": "Error while validation",
            "message": "Data Error Password and Confirm Password value dont match",
            "show": true
			});
			}
			else
			{
				props.setMsgModal({
            "title": "Error while validation",
            "message": "Data Incorrect Please check and try again",
            "show": true
			});
				
			}
		}
			event.preventDefault();
		
		 if (form.checkValidity() === true) {
			 if(password!=re_password)
			{
				props.setMsgModal({
            "title": "Error while validation",
            "message": "Data Error Password and Confirm Password value dont match",
            "show": true
			});
			}
			else
			{
            //.. Save DETAILS OF THE FORM
			let obj={
			  "phoneNumber": props.phoneNumber,
			  "email": email,
			"newPassword": password
			};
			props.forgotPassword(obj);
			// to be replaced with updateAddress call to server
			}
        }
    }
    /** @function
 * @name onKeyDownHandler */

    

    useEffect(() => {
        if(props.phoneNumber.length==10){
			if(phone1.length==0)
			setValidNumber(true);
		}
    }, [props.phoneNumber])

    
    
    return (
        <div className="page-bodesection">
            <Navbar bg="white" sticky="top" expand="lg border-bottom" className="navstylecontrol ">
                <Navbar.Brand>
                    <img src={require("../images/logo.png")} alt="logo" />
                </Navbar.Brand>
            </Navbar>
            <Container>
                <Row>
                    <Col sm={8}>
                        <div className="position-relative">
                            <img src={require("../images/login-banner.png")} alt="Login Banner" className="w-75control" />
                        </div>
                        <div className="position-absolute log-sign-leftdiv text-center">
                            <div>
                                <h1 className="text-center log-welcome-text mb-0"><b>WELCOME</b></h1>
                                <h3><i>To</i></h3>
                                <label className="log-banner-logo mb-1"><img src={require("../images/logo.png")} alt="Login Banner" /></label>
                                <p className="mb-2"><b>Online Fashion Store</b></p>
                                <h4 className="b2btagline mb-4">B2B ORDERING PLATFORM</h4>
                                <Button variant="dark" className="rounded-0 primarybutton pt-2 pb-2" onClick={linkToSignUp} >NEW REGISTRATION</Button>{' '}
                            </div>
                        </div>
                    </Col>
                    <Col sm={4} className="pr-0">
                        <div className="d-flex h-100 justify-content-center align-self-center flex-column">
                            <fieldset className="scheduler-border rounded">
                                <legend className="scheduler-border signin-label">Forgot Password</legend>
                                <Form noValidate validated={validated} onSubmit={handleClick}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="fsize14 mb-1"><b>Mobile No.</b></Form.Label>
                                        <Form.Control type="number" placeholder="Registered Mobile No." required  value={props.phoneNumber} onChange={handlePhonenumberChange} />
                                    </Form.Group>
									<Form.Group controlId="formBasicEmail">
                                        <Form.Label className="fsize14 mb-1"><b>Email ID.</b></Form.Label>
                                        <Form.Control  placeholder="Registered Email ID" required pattern='[a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*@[a-zA-Z]{1}[a-zA-Z]*[.]{1}[a-z]{1}[a-z]*' onChange={handleEmailChange} />
                                    </Form.Group>
									<Form.Group controlId="formBasicPassword">
                                        <Form.Label className="fsize14 mb-1"><b>Password</b></Form.Label>
                                        <Form.Control type="password" placeholder="Type New Password" required onChange={handlePasswordChange} />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label className="fsize14 mb-1"><b>Password</b></Form.Label>
                                        <Form.Control type="password" placeholder="Confirm Password" required onChange={handlerePasswordChange} />
                                    </Form.Group>
									
									
									
                                    
                                    <Button variant="dark" id="submit_test" className="rounded-0 primarybutton pt-2 pb-2 mb-4" type="submit" value="submit" disabled={!validNumber} >SUBMIT</Button>{' '}
                                    <div ><p className="fsize13 font-weight-bold text-muted">Already Registered ? <a href="" className="fsize13 font-weight-bold text-danger text-decoration-underline"><span onClick={linkToLogIn}>Log In</span></a></p></div>
                                </Form>
								
                            </fieldset>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
            {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorLoginClose} />}
        </div> 
    );
}

const mapStateToProps = state => ({
    phoneNumber: state.AuthData.phoneNumber,
    message: state.AuthData.message,

    modal: state.AuthData.modal
})

export default connect(mapStateToProps, {setMsgModal, set_phoneNumber,forgotPassword })(Forgot);
 
