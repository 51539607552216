import {apiAction} from '../middleware/api/apiActions';
import API from './APIConst';
import {DispatchAction} from './index';

import { setCompanyId } from './HomeAction'; 
import { userProfile } from './UserAction'; 
/** @constant
    @type {string}
    @default
*/
const GENERIC_MODAL = 'GENERIC_MODAL';
/** @constant
    @type {string}
    @default
*/
const USER_LOGIN = "USER_LOGIN";
/** @constant
    @type {string}
    @default
*/
//const USER_LOGOUT = "USER_LOGOUT";
/** @constant
    @type {string}
    @default
*/
const USER_REGISTER = "USER_REGISTER";
/** @constant
    @type {string}
    @default
*/
const USER_AUTHENTICATED = "USER_AUTHENTICATED";
/** @constant
    @type {string}
    @default
*/
const USER_PHONE_NUMBER = "USER_PHONE_NUMBER";
/** @constant
    @type {string}
    @default
*/
const USER_PASSWORD = "USER_PASSWORD";

//This is for SignUp//
/** @constant
    @type {string}
    @default
*/
const USER_NAME = "USER_NAME";
/** @constant
    @type {string}
    @default
*/
const USER_PHONE="USER_PHONE";
/** @constant
    @type {string}
    @default
*/
const USER_EMAIL="USER_EMAIL";
/** @constant
    @type {string}
    @default
*/
const USER_ADDRESS="USER_ADDRESS";
/** @constant
    @type {string}
    @default
*/
const USER_PURPOSE="USER_PURPOSE";
/** @constant
    @type {string}
    @default
*/
const USER_REFERENCENAME="USER_REFERENCENAME";
/** @constant
    @type {string}
    @default
*/
const USER_ATTACHGST="USER_ATTACHGST";
/** @constant
    @type {string}
    @default
*/
const USER_ATTACHPAN="USER_ATTACHPAN";
/** @constant
    @type {string}
    @default
*/
const USER_GSTNUMBER="USER_GSTNUMBER";
/** @constant
    @type {string}
    @default
*/
const USER_MESSAGE="USER_MESSAGE";
/** @constant
    @type {string}
    @default
*/
const USER_STATUS="USER_STATUS";
const USER_CONFIRM="USER_CONFIRM";

/**
 * setMsgModal Action defined
 * @constructor
 * @param {object} param - Pass object as payload to set modal message.
 */

export const setMsgModal = (param) => ({ 
    type: GENERIC_MODAL,
    payload: param
});

/**
 * Login Action defined.
 * @constructor
 * @param {object} obj - Pass object as payload to check login authentication.
 */
export const login = (obj) => {
    return apiAction({
        url: API.LOG_IN,
        method: "post",
        data: obj,
        onSuccess: setLogin,
        onFailure: () => console.log("Error occured in setLogin"),
        callback: 'login',
        label: USER_LOGIN
    });
}
/**
 * setLogin Action define.
 * @constructor
 * @param {object} param - Pass object as payload to check login authentication.
 */
export const setLogin = param => {
    DispatchAction(setCompanyId(param));
    DispatchAction(userProfile(param));
    DispatchAction(set_message(param.message));
	DispatchAction(set_status(param.status));
    return {
        type: USER_LOGIN,
        payload: param
    }
};


/**
 * signup Action define.
 * @constructor
 * @param {object} obj - Pass object as payload to save registration data.
 */
export const signup = (obj) => {
    return apiAction({
        url: API.SIGN_UP,
        method: "post",
        data: obj,
        onSuccess: setSignUp,
        onFailure: () => console.log("Error occured in setLogin"),
        callback: 'signup',
        label: USER_REGISTER
    });
}
/**
 * setSignUp Action as payload.
 * @constructor
 * @param {object} param - Pass object as payload to set registration data.
 */
export const setSignUp = param => ({
    type: USER_REGISTER,
    payload: {...{}, signupData: param.data, message: param.message, status:param.status}
});

/**
 * Actions defination
 * @constructor
 * @param {*} param 
 */ 

export const set_phoneNumber = (param) => ({ type: USER_PHONE_NUMBER, payload: param })
export const set_password = (param) => ({ type: USER_PASSWORD, payload: param })

export const set_authenticated = (param) => ({ type: USER_AUTHENTICATED, payload: param })


//This is for SignUp//
export const set_name= (param) => ({ type: USER_NAME, payload: param })
export const set_phone= (param) => ({ type: USER_PHONE, payload: param })
export const set_email= (param) => ({ type: USER_EMAIL, payload: param })
export const set_address= (param) => ({ type: USER_ADDRESS, payload: param })
export const set_purpose= (param) => ({ type: USER_PURPOSE, payload: param })
export const set_referenceName= (param) => ({ type: USER_REFERENCENAME, payload: param })
export const set_attachGST= (param) => ({ type: USER_ATTACHGST, payload: param })
export const set_attachPAN= (param) => ({ type: USER_ATTACHPAN, payload: param })
export const set_gstNumber= (param) => ({ type: USER_GSTNUMBER, payload: param })
export const set_message= (param) => ({ type: USER_MESSAGE, payload: param })
export const set_status= (param) => ({ type: USER_STATUS, payload: param })
export const set_confirm= (param) => ({ type: USER_CONFIRM, payload: param })


/**
 * set up with initial state.
 */
const initialState = {

    modal: {},

    loginData:{},
    signupData:{},
    phoneNumber:'',
    password:'',
    authenticated: false,
    valid:false,


    name:'',
    phone:'',
    email:'',
    address:'',
    purpose:'',
    referenceName:'',
    attachGST:'',
    attachPAN:'',
    gstNumber:'',
    message:'',
    status:'',
	confirm:false
}




/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_PHONE_NUMBER: return {...state, phoneNumber: action.payload } 
        case USER_PASSWORD: return {...state, password: action.payload } 
        case USER_AUTHENTICATED: return {...state, authenticated: action.payload } 

        case USER_LOGIN: return {...state, ...action.payload.data.token }        
        case USER_REGISTER: return {...state, signupData: action.payload.signupData,message:action.payload.message,status:action.payload.status }      
        
        case USER_NAME: return {...state, name: action.payload } 
        case USER_PHONE:  return {...state, phone: action.payload } 
        case USER_EMAIL:  return {...state, email: action.payload } 
        case USER_ADDRESS:  return {...state, address: action.payload } 
        case USER_PURPOSE:  return {...state, purpose: action.payload } 
        case USER_REFERENCENAME:  return {...state, referenceName: action.payload } 
        case USER_ATTACHGST:  return {...state, attachGST: action.payload } 
        case USER_ATTACHPAN:  return {...state, attachPAN: action.payload } 
        case USER_GSTNUMBER:  return {...state, gstNumber: action.payload } 
        case USER_MESSAGE:  return {...state, message: action.payload }
        case USER_STATUS:  return {...state, status: action.payload }
		case USER_CONFIRM:  return {...state, confirm: action.payload }
        
        case GENERIC_MODAL: return { ...state, modal: action.payload }
        
        default: return state;
    }
}

/**
 * Export Object (Action + Reducer)
 */
const AuthAction = {
    reducer: reducer,
    actions: {
        login
    }
}

export default AuthAction