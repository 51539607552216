//import '../utils/wdyr';

import React, { useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import {ToastContainer} from 'react-toastify';
import { connect } from 'react-redux';


import Loader from './Loader';

import {useWindowResizeListener} from '../utils/useHooks';
import { setMenuState, setMobileState } from '../actions/CategoryAction';
import { getCompanyInfo } from '../actions/HomeAction';
import { isProd } from '../utils/Env';

import HomeContainer from './HomeContainer';
import PinModal from '../components/PinModal';
import ProductDetails from '../components/ProductDetails';
import CategoryContainer from './CategoryContainer';
import SearchPanel from './SearchPanel';
import Profile from './Profile';
import FeaturePanel from './FeaturePanel';
import Login from './Login';
import Forgot from './Forgot_Password';
import SignUp from './SignUp';
import VariantModal from "../components/VariantModal";
import CheckOut from './CheckOut';
import CartPreviewModal from './CartPreviewModal';
import NotFound from './NotFound'; 
import ConfigLoader from './ConfigLoader';
import ScrollToTop from '../components/Scroll';
import "../assets/css/style.css";
/* const HomeContainer = lazy(() => import('./HomeContainer'));
const Products = lazy(() => import('../components/Products'));
const PinModal = lazy(() => import('../components/PinModal'));
const ProductDetails = lazy(() => import('../components/ProductDetails'));
const CategoryContainer = lazy(() => import('./CategoryContainer'));
const SearchPanel = lazy(() => import('./SearchPanel'));
const VariantModal = lazy(() => import('../components/VariantModal'));
const CheckOut = lazy(() => import('./CheckOut'));
const CartPreview = lazy(() => import('./CartPreview'));
const NotFound = lazy(() => import('./NotFound')); */
/**
 * Creates a new App.
 * @class
 */
const App = props => {

    
    /* <nav>
        <ul className="home-menu">
        <li className="home-menuli"><Link to="/"> Home </Link></li> 
        <li className="home-menuli"><Link to="/products"> Products </Link></li> 
        <li className="home-menuli"><Link to="/productdetails"> ProductDetails </Link></li> 
        <li className="home-menuli"><Link to="/checkout"> Checkout  </Link></li> 
        </ul>
    </nav> */
    
    const { width } = useWindowResizeListener();
    const {setMenuState, setMobileState} = props;

    useEffect(() => {
        if (width < 768) {
            setMenuState(false);
            setMobileState(true);
        } else {
            setMenuState(true);
            setMobileState(false);
        }
    }, [width, setMenuState, setMobileState]);

    useEffect(() => {
        if(isProd()){
            document.addEventListener("contextmenu", function(event){
                event.preventDefault();
            })
            console.log = () => {}
        }
      }, []);
    
    return (
        <Container fluid="true">
            <ScrollToTop>
              <ConfigLoader />  
                <Routes>
                    <Route path="/" element={<Outlet />} >
                        
                        <Route path="/" element={<HomeContainer />}>


                            <Route path="/" element={<FeaturePanel />} />
							
							<Route path="profile" element={<Outlet />} >
								<Route path="/" element={<Profile />} />
								<Route path="*" element={<NotFound />} />
							</Route>
                            
                            <Route path="/search" element={<SearchPanel />} />

                            <Route path="products" element={<Outlet />}>
                                <Route path="/" element={<FeaturePanel />} />
                                <Route path="/:slug" element={<ProductDetails />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>

                            
                            <Route path="category" element={<Outlet />} >
                                <Route path="/" element={<Outlet />} />
                                <Route path="/:catid" element={<CategoryContainer />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
							
							<Route path="checkout" element={<Outlet />} >
							<Route path="/" element={<CheckOut />} />
								<Route path="*" element={<NotFound />} />
							</Route>
                        </Route>    
                        <Route path="register" element={<SignUp />} />
                        <Route path="login" element={<Login />} />
						<Route path="forgot" element={<Forgot />} />
                        
                        <Route path="*" element={<NotFound />} />

                    </Route>
                </Routes>
                <PinModal />
                <VariantModal />
                <CartPreviewModal />
                <ToastContainer autoClose={false} />
                <Loader />
            </ScrollToTop>
        </Container>
    )
}


export default connect(null, { getCompanyInfo, setMenuState, setMobileState })(App);
