
import React, { useState, useEffect } from 'react';
import { Form, Col, Row,  Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Global } from '../../utils/Env';
import {
    set_delivery_address1,
    set_delivery_address2,
    set_delivery_city,
    set_delivery_district,
    set_delivery_state,
	set_delivery_phone, set_delivery_pin,setCustomerDetails,set_delivery_spec_ins,deliveryvalidatepin
} from '../../actions/UserAction';
import MsgModal from '../../container/MsgModal';
import {setMsgModal} from '../../actions/AuthAction';


/**
 * Creates a new Address.
 * @class
 */

const Address = (props) => {
	
	useEffect(() => {
        props.set_delivery_city(props.city1);
		props.set_delivery_state(props.state1);
		props.set_delivery_district(props.dist1);
		props.set_delivery_address1(props.address1);
		props.set_delivery_address2(props.address21);
		props.set_delivery_phone(props.phone);
		props.set_delivery_pin(props.pin1);
    }, []);
    const emptyForm = {
        addrid: -1,
        email: "",
        fname: "",
        lname: "",
        address1: props.address1,
        address2: props.address21,
        phone: props.phone,
        type: ""
    }

	const onErrorLoginClose = () => {
        props.setMsgModal({
            "title": "",
            "message": "",
            "show": false
        });
    }
    const [formData, setFormData] = useState(emptyForm);
    const [isFormOpen, setFormOpen] = useState(false);
    const [validated, setValidated] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false);
	const [dist, setDist]=useState(props.dist1);
	const [cstate, setCstate]=useState(props.state1);
	const [city, setCity]=useState(props.city1);
	const [pin,setPin]=useState(props.pin1);
	var formData1=emptyForm;
    const { email, fname, lname, address1, address2, type,phone } = formData;
    /** @function
 * @name setFinalAddress */

    const setFinalAddress = (item) => {
        //+++++++++++++++++++++++++
		let deliveryAddress1=item.address1;
		let deliveryAddress2=item.address2;
		let deliveryPhone=item.phone;
		let delivery_spec_ins=item.specialinstructions;
        props.set_delivery_address1(deliveryAddress1);
        props.set_delivery_address2(deliveryAddress2);
		props.set_delivery_phone(deliveryPhone);
		props.set_delivery_spec_ins(delivery_spec_ins);
        //+++++++++++++++++++++++++
    }

    /** @function
 * @name selectAddress */
    const selectAddress = (data) => {
        setFinalAddress(data);
    }
    /** @function
* @name handleOnChange */
    const handleOnChange = (e) => {
		if(e.target.name=='phone' && (e.target.value.length>10 || !(e.target.value.charAt(e.target.value.length-1)>='0' && e.target.value.charAt(e.target.value.length-1)<='9')))
			e.target.value=e.target.value.substring(0,e.target.value.length-1);
		if((e.target.name=='state' || e.target.name=='city') && e.target.value.charAt(e.target.value.length-1)>='0' && e.target.value.charAt(e.target.value.length-1)<='9')
			e.target.value=e.target.value.substring(0,e.target.value.length-1);
		if(e.target.name=='state')
	  props.set_delivery_state(e.target.value);
  else if(e.target.name=='dist')
	  props.set_delivery_district(e.target.value);
  else if(e.target.name=='city')
	  props.set_delivery_city(e.target.value);
  else
  {
		setFormData({ ...formData, [e.target.name]: e.target.value });
		formData1={ ...formData, [e.target.name]: e.target.value };
  }
		
		}
		
		
	const handlePinChange = (e) => {
		if(e.target.name=='pin' && (e.target.value.length>6 || !(e.target.value.charAt(e.target.value.length-1)>='0' && e.target.value.charAt(e.target.value.length-1)<='9')))
			e.target.value=e.target.value.substring(0,e.target.value.length-1);
		if(e.target.value.length<6)
		{
			props.set_delivery_city('');
			props.set_delivery_state('');
			props.set_delivery_district('');
		}
		else if(e.target.value.length==6)
		{
			props.set_delivery_pin(e.target.value);
		}
			setPin(e.target.value);
		
}
const pinsubmit = () => {
	if(pin.length!=6)
		props.setMsgModal({
            "title": "Error in Pin Validation",
            "message": "Error in the value of pin Check the Pin",
            "show": true
        });
	else
	{
	let obj={"compId": props.compid, "pinCode":pin}
	props.deliveryvalidatepin(obj);
	}
}
		
		Global.callback.deliveryvalidatepin_onComplete = () => {
			if(props.validatepin.status)
			{
			let deliveryPin=props.validatepin.data.pincode;
			let deliveryCity=props.validatepin.data.region;
			let deliveryState=props.validatepin.data.state;
			let deliveryDistrict=props.validatepin.data.dist;
			props.set_delivery_city(deliveryCity);
			props.set_delivery_pin(deliveryPin);
			props.set_delivery_state(deliveryState);
			props.set_delivery_district(deliveryDistrict);
			selectAddress(formData1);
			}
			else
			{
				props.set_delivery_city('');
				props.set_delivery_state('');
				props.set_delivery_district('');
				setPin('');
				 props.setMsgModal({
				"title": "Pin does not exist",
				"message": "Please check pin. Pin code does not exist",
				"show": true
				});
			}
    }
    /** @function
 * @name password_onError */
    Global.callback.deliveryvalidatepin_onError = () => {
        props.setMsgModal({
            "title": "Error in Pin Validation",
            "message": "Error in validating the pin",
            "show": true
        });
    }

    //.. Call for NEW ADDRESS  
    /** @function
* @name saveAddress */
    const saveAddress = (event) => {
				setValidated(true);
				const form = event.currentTarget;
				
		if(pin=='' || props.deliveryState==null || props.deliveryDistrict==null || props.deliveryCity==null || props.deliverState=='' || props.deliveryDistrict=='' || props.deliveryCity=='')
	{
		 event.stopPropagation();
		props.setMsgModal({
            "title": "Profile Page Validation Error",
            "message": "Data Incorrect. Please check and try again",
            "show": true
        });
	}
        
       else if (form.checkValidity() === false) {
            event.stopPropagation();
            props.setMsgModal({
                "title": "Profile Page Validation Error",
                "message": "Data Incorrect. Please check and try again",
                "show": true
            });
        }
		
		else if (form.checkValidity() === true) {
			console.log(formData1);
            //.. Save DETAILS OF THE FORM
            selectAddress(formData1);
            props.parentCallback(true);
            // to be replaced with updateAddress call to server

        }

		event.preventDefault();
        //.. callback for evaluation

    }
    /** @function
* @name saveAddress1 */
    const saveAddress1 = (event) => {

        const form = event.currentTarget;
        event.preventDefault();
			selectAddress(formData1);
			// to be replaced with updateAddress call to server
        //.. callback for evaluation

    }
    /** @function
 * @name doLocalUpdate */
    const doLocalUpdate = (data) => {
        //console.log(data.addrid, "->", data, props.customerDetails);
        if (data.addrid === -1) {
            props.setCustomerDetails({ data: props.customerDetails.add(data) });
        } else {
            let updatedData = props.customerDetails.map(function (item) { return item.addrid === data.addrid ? data : item; });
            props.setCustomerDetails({ data: updatedData });
        }
    }

    return (
        <React.Fragment>
<Form noValidate validated={validated} onChange={saveAddress1} onSubmit={saveAddress}  className="mb-0"  >


                            <h4 className="border-bottom bge4e4e4 m-0 prod-dlts-label pt-2 pb-2 pl-3 pr-3">Delivery Address</h4>
							
							<Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group className="mt-3">
                                            <Form.Label className="fsize14 mb-1"><b>PIN Code</b></Form.Label>
                                            <Form.Control            
                                                value={pin}
                                                name="pin" 
												pattern='[0-9]{6}'
                                                required 
                                                placeholder="PIN Code"
												autoComplete="none"
												onChange={handlePinChange} />
                                        </Form.Group>
										<div className="text-right"><Button variant="outline-dark" className="rounded-0 pt-1 pb-1 mb-3 btn-sm w-100" onClick={pinsubmit}>CHECK PINCODE</Button>{' '}</div>
                                    </Col>
                                </Row>
								<Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group>
                                        <Form.Label className="fsize14 mb-1"><b>District</b></Form.Label>
                                    <Form.Control value={props.deliveryDistrict} placeholder="District"
                                        name="dist" onChange={handleOnChange} autoComplete="none" required  />
                                        </Form.Group>
                                    </Col>
                                </Row>
								<Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className="fsize14 mb-1"><b>State</b></Form.Label>
                                            <Form.Control value={props.deliveryState} placeholder="State"
                                                name="state"  onChange={handleOnChange} autoComplete="none" required  />
                                        </Form.Group>
                                    </Col>
                                </Row>
								<Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group>
                                        <Form.Label className="fsize14 mb-1"><b>City</b></Form.Label>
                                            <Form.Control value={props.deliveryCity}
                                                name="city" onChange={handleOnChange}  autoComplete="none" required placeholder="City" required />
                                           
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className="fsize14 mb-1"><b>Address Line 1</b></Form.Label>
                                            <Form.Control placeholder="Number & Street" value={address1}
                                                name="address1" autoComplete="none" onChange={handleOnChange} required placeholder="Address Line 1" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className="fsize14 mb-1"><b>Address Line 2</b></Form.Label>
                                            <Form.Control placeholder="Number & Street" value={address2}
                                                name="address2" onChange={handleOnChange} autoComplete="none" placeholder="Address Line 2" />
                                        </Form.Group>
                                    </Col>
                                </Row>
								<Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className="fsize14 mb-1"><b>Contact Phone No</b></Form.Label>
                                            <Form.Control value={phone} name="phone" autoComplete="none" pattern='[0-9]{10}' required placeholder="Contact Phone No" onChange={handleOnChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
								
                                <Row className="bgefefef m-0">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className="fsize14 mb-1"><b>Special Instructions</b></Form.Label>                                            
                                            <Form.Control as="textarea" rows="2" name="specialinstructions" autoComplete="none" placeholder="Special Instructions" onChange={handleOnChange}/>
                                        </Form.Group>
                                    </Col>
                                </Row>

								<Row className="bgefefef m-0 mb-3 pb-3">
                                    <Col>
                                        <Form.Group>
                                            
                                        <div className="pt-0 bgefefef">
                                            <div className="text-center mt-3 pb-2 mob-fix-plbutton">
                                            <a className="product-links-anchor float-left pt-2 pb-2 pl-3 pr-3 hidden-desktop" href=""><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back </a>
                                            <Button variant="dark" className="rounded-0 primarybutton bg-bordb02409 fl-none-desktop float-right-mob " type="submit" value="Submit"  >
												PLACE ORDER
											</Button>
                                            </div>
                                        </div>
                                            
                                            
                                        </Form.Group>
										 {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={props.modal.message} closeAction={onErrorLoginClose} />}
                                    </Col>
                                </Row>
                        </Form>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    pincode: state.LocationData.pin,
    /* address1: state.LocationData.area, */
    address2: state.LocationData.area,
    city: state.LocationData.city,
    dist: state.LocationData.dist,
    state: state.LocationData.state,
    pin: state.LocationData.pin,
	phone: state.UserData.phone,
	pin1: state.UserData.pin,
	address1: state.UserData.address1,
	address21: state.UserData.address2,
	city1: state.UserData.city,
	state1: state.UserData.cstate,
	dist1:state.UserData.dist,
	user: state.UserData,
	compid: state.HomeData.companyid,
    modal: state.AuthData.modal,
    customerDetails: state.UserData.customerDetails,
   addressTypes: state.UserData.addressTypes,
   validatepin: state.UserData.validatepin,
   deliveryState:state.UserData.deliveryState,
   deliveryCity:state.UserData.deliveryCity,
   deliveryDistrict:state.UserData.deliveryDistrict
})

export default connect(mapStateToProps, {
    set_delivery_address1,
    set_delivery_address2,
    set_delivery_city,
    set_delivery_district,
    set_delivery_state,
	set_delivery_phone, set_delivery_pin,setCustomerDetails,
	set_delivery_spec_ins,deliveryvalidatepin,setMsgModal

})(Address);