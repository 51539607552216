import React, { useState } from 'react';
//import ImageUtil from '../../utils/ImageUtil';
import { useNavigate } from 'react-router-dom';
import { clearCategory, setMobileSearch } from '../../actions/CategoryAction';
import { connect } from 'react-redux';
import { Container, Button, Row, Col, Navbar, Nav, Form, FormControl, InputGroup, ListGroup } from 'react-bootstrap';
/**
 * Creates a new Search.
 * @class
 */
const Search = (props) => {

    const [input, setInput] = useState('');
    const navigate = useNavigate();
    /** @function
 * @name handleClick */
    function handleClick(e){
 
        e.preventDefault();
        props.clearCategory();
        navigate('/search', {state:{term:input}});

        setInput(''); //.. clear input field
    } 

    const onSearchMobile = () => {
        props.setMobileSearch(false);
    }

    return (
        <Form inline className="position-absolute r-280px top30px pos-static-mob mobile-hide-search" onSubmit={handleClick}>
            <InputGroup> 
            <InputGroup.Prepend className="hidden-desktop">
                 <InputGroup.Text className="back-iconcss mt-1"><i className="fa fa-arrow-left" aria-hidden="true" onClick={onSearchMobile}></i></InputGroup.Text>
            </InputGroup.Prepend>               
                <FormControl
                    value={input} 
                    onChange={e => setInput(e.target.value)}
                    placeholder="Search for products, brands and more"
                    aria-label="Search"
                    aria-describedby="basic-addon1" className="bgeee border-left-0 pl-0 custom-srchbox custom-widthsearch"
                />
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1" className="bgeee custom-srchbox" type="search" >
                        <div className="cur-pointer" onClick={handleClick}><img src={require("../../images/search.png")} alt="Search" /></div>
                    </InputGroup.Text>
                </InputGroup.Prepend>
            </InputGroup>
        </Form> 
    )
}

const mapStateToProps = state => ({
    mobileSearch: state.CategoryData.mobileSearch
})

export default connect(mapStateToProps, {setMobileSearch, clearCategory}) (Search);