import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';

// Description on const CATEGORYLIST
/** @constant
    @type {string}
    @default
*/
const PRODUCT_LIST = "PRODUCT_LIST";
/** @constant
    @type {string}
    @default
*/
const SELECTED_PRODUCT = "SELECTED_PRODUCT";
/** @constant
    @type {string}
    @default
*/
const FILTERED_PRODUCTS_BY_CATEGORY = "FILTERED_PRODUCTS_BY_CATEGORY";
/** @constant
    @type {string}
    @default
*/
const VARIANT_MODAL = "VARIANT_MODAL";
/** @constant
    @type {string}
    @default
*/
const VARIANT_LIST = "VARIANT_LIST";
/** @constant
    @type {string}
    @default
*/
const CLEAR_VARIANTS = "CLEAR_VARIANTS";
/** @constant
    @type {string}
    @default
*/
const FEATURED_ITEMS = "FEATURED_ITEMS";

const PRICE_MIN="PRICE_MIN";
const PRICE_MAX="PRICE_MAX";
const FILTER_DATA="FILTER_DATA";
const FILTER_LIST="FILTER_LIST";
//.....................................
/**
 * Action getProductList defined.
 * @constructor
 * @param {object} obj - Pass object as payload to get product list.
 */
export const getProductsList = (obj) => {
    return apiAction({
        /* url: `${API.GET_ALL_ITEMS}${cid}`, */
        url: API.GET_ALL_ITEMS,
        method: "post",
        data: obj,
        onSuccess: setProductsList,
        onFailure: () => console.log("Error occured in getCategoryList"),
        label: PRODUCT_LIST
    });
}

export const getFilterList = (obj) => {
    return apiAction({
        /* url: `${API.GET_ALL_ITEMS}${cid}`, */
        url: API.GET_FILTER,
        method: "post",
        data: obj,
        onSuccess: setFilterList,
        onFailure: () => console.log("Error occured in getFilterList"),
        label: FILTER_LIST
    });
}



/**
 * Action setProductsList defined.
 * @constructor
 * @param {object} obj - Pass object as payload to set product list.
 */
//TODO:: incorrect response data param.Message
export const setProductsList = param => ({
    type: PRODUCT_LIST,
    payload: param.data
});

export const setpriceMin = param => ({
    type: PRICE_MIN,
    payload: param
});

export const setFilterList = param => {
return{
	
    type: FILTER_LIST,
    payload: param.data
}};


export const setpriceMax = param => ({
    type: PRICE_MAX,
    payload: param
});

export const setfilterData = param => ({
    type: FILTER_DATA,
    payload: param
});



/**
 * Action getfeaturedItems defined.
 * @constructor
 */  
export const getfeaturedItems = (cid) => {
    return apiAction({
        url: `${API.GET_FEATURE_ITEMS}${cid}`, 
        onSuccess: setfeaturedItems,
        onFailure: () => console.log("Error occured in getCompanyInfo"),
        label: FEATURED_ITEMS
      });
}
/**
 * Action setfeaturedItems defined.
 * @constructor
 */  
export const setfeaturedItems = param => ({
    type: FEATURED_ITEMS,
    payload: param.data
});  

//.....................................
/**
 * Action getItemsByVariant defined.
 * @constructor
 */
export const getItemsByVariant = (obj) => {

    return ({
        type: "none",
        payload: null
    })

    /* return apiAction({
        url: `${API.GET_ITEMS_BY_VARIANT}`,
        method: "post",
        data: obj,
        onSuccess: setItemByVariant,
        onFailure: () => console.log("Error occured in getItemsByVariant"),
        label: VARIANT_LIST
    }); */
}

export const setItemByVariant = param => {
    return ({
        type: VARIANT_LIST,
        payload: param.data
    })
};
//...................................................................
/**
 * Action filterByCategory defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const filterByCategory = param => ({
    type: FILTERED_PRODUCTS_BY_CATEGORY,
    payload: param
});

//...................................................................
/**
 * Action selectProduct defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const selectProduct = param => ({
    type: SELECTED_PRODUCT,
    payload: param
});


//...................................................................
/**
 * Action setVariantModal defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setVariantModal = param => ({
    type: VARIANT_MODAL,
    payload: param
});

//...................................................................
/**
 * Action clearVariants defined.
 * @constructor
 * 
 */
export const clearVariants = () => ({
    type: CLEAR_VARIANTS
});
//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    productsList: [],
    featuredItems:[],
    selectedProduct: {},
    variantModal: false,
    variantList:[],
    filteredProductsByCategory: "",
	pricemin: 0,
	pricemax: 0,
	filter_data: {},
	filters: {}
}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_LIST: {
                return {
                    ...state,
                    productsList: action.payload
                }
            }
        case VARIANT_LIST: {
            return {
                ...state,
                variantList: action.payload
            }
        }
        case SELECTED_PRODUCT: {
            return {
                ...state,
                selectedProduct: action.payload
            }
        }
         case CLEAR_VARIANTS: {
            return {
                ...state,
                variantList: []
            }
        }
        case VARIANT_MODAL: {
            return {
                ...state,
                variantModal: action.payload
            }
        }

        case FEATURED_ITEMS: return {...state, featuredItems: action.payload }
		
		 case PRICE_MIN: return {...state, pricemin: action.payload }
		 
		 case PRICE_MAX: return {...state, pricemax: action.payload }
		 
		 case FILTER_DATA: return {...state, filter_data: action.payload }
		 case FILTER_LIST: return {...state, filters: action.payload }

        default:
            return state;
    }
}

//...................................................................
//.. selectors :: TODO


//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const ProductsAction = {
    reducer: reducer,
    actions: {
        getProductsList,
        filterByCategory,
        selectProduct,
        getItemsByVariant,
        setVariantModal,
        clearVariants,
		setpriceMin,
		setpriceMax,
		setfilterData,
		getFilterList
    }
}

export default ProductsAction